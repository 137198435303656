import React, { useState } from 'react'
import Header from '../../CreatorComponents/CreatorJoin/header'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link, useNavigate } from 'react-router-dom'
import { insertUserData } from '../../../Counter/userSignUpSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { userDetailValidation } from '../../../Validations/formValidation'
import { adminValidationSchema } from '../../../Validations/formValidation'
import logo from '../../../assets/images/logo.svg'
import eye from '../../../assets/images/Eye.svg'
import cross from '../../../assets/images/cross-button.svg'
import eyeClosed from '../../../assets/images/eye-icon-change.svg'
import { postApi } from '../../../Services/apiService'

function DetailForm({ nextStep, prevStep, data, admin }) {
  const [showPassword, setShowPassword] = useState(false);
  const [emailStatus,setEmailStatus] = useState(false)
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    name: data?.name || '',
    lname: data?.lname || '',
    email: data?.email || '',
    password: data?.password || ''
  }
  const dispatch = useDispatch();
  const onSubmit = async (values) => {
    if(emailStatus === false){
    console.log('this is filled details',values);
    dispatch(insertUserData(values));
    nextStep()
    }
  }

  const handleVerifyEmail = async(e) =>{
    setEmailStatus(false)
    const values = {
      email: e?.target?.value
    }
    // setTimeout(() => {
      if(e?.target?.value !== ''){
      makeApiCall(values);
      }
    // }, 3000);
    
    // if(response?.statusCode === 200){

    // }

  }
  const makeApiCall = async(value)=>{
    const values = {
      email:value?.email,
      user_role:'user',
    }
    const response = await postApi('/check-email',values)
    
    if(response?.statusCode === 400){
      setEmailStatus(true)
    }
  }

  return (
    <div className='detail-page signup-cs'>
      <Header/>
      
      <div className="detail-main">
        <h1>Enter {admin === "admin" ? 'member' : 'your'} details</h1>
        <p>Add name and password to complete {admin === "admin" ? 'member' : 'your'} registration</p>
        {admin === 'admin' && (
            <button className='close-button admin-btncs' onClick={() => navigate('/admin/members')}><img src={leftArrow} alt='' /></button>

      )}
        <Formik
          initialValues={initialValues}
          validationSchema={admin === "admin" ? adminValidationSchema : userDetailValidation}
          onSubmit={onSubmit}
        >
          {({ handleChange, setFieldValue, values }) => (
          <Form>
            {/* <div className="modal-body"> */}
            <div className="form-group">
              <label htmlFor="name" >
                {admin === "admin" ? 'First Name' : 'Your Name'}
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder={admin === 'admin' ? 'Please enter first name' : "What should we call you"}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" >
                {/* Email */}
                {admin === "admin" ? 'Last Name' : 'Email'}
              </label>
              {admin === 'admin' ?
              <Field
              type="text"
              id="lname"
              name="lname"
              className="form-control"
              placeholder="Please enter last name"
            />
              :
              
              <Field
                type="text"
                id="email"
                name="email"
                className="form-control"
                placeholder="Write your Email"
                onChange={(e) => {
                  handleChange(e);
                  handleVerifyEmail(e); 
                }}
              />
  }
              <ErrorMessage
                name={admin === 'admin' ? 'lname' :"email"}
                component="div"
                className="text-danger"
              />
              <div className="text-danger">

              {emailStatus && 'Email already exist'}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="password" >
                {admin === "admin" ? 'Email' : 'Password'}
              </label>
              {admin === 'admin' ?

              <Field
              type="text"
              id="email"
              name="email"
              className="form-control"
              placeholder="Write your Email"
            />
              :
              <Field
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="form-control"
                placeholder="Create Password"
              />
            }
              {admin === 'admin' ? 
              <></>
               :
              <span className="eye-icon" onClick={togglePasswordVisibility}>
                {showPassword ? <img src={eye} alt='' /> : <img src={eyeClosed} alt='' />}
                {/* {showPassword ? '🙈' : '👁️'} */}
              </span>
              }
              <ErrorMessage
                name={admin === 'admin' ? 'email' :"password"}
                component="div"
                className="text-danger"
              />
            </div>
            <button type="submit">
              {/* {/* <button type="submit" className="btn btn-primary">Login</button> */}
              Continue
              {/* <SubmitButton submitting={isLoading} onClick={insertData()}/> */}
            </button>

            {/* </div> */}
          </Form>
           )}
        </Formik>

      </div>
      {admin !== 'admin' &&
        <div className="back-button">
          <button className="button" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>
        </div>
      }

    </div>
  )
}

export default DetailForm