import { messaging, getToken, onMessage } from '../../Component/FirebaseConfig/firebase';
import { firebase_valid_key } from '../../Component/FirebaseConfig/firebase';

// Request FCM token for notifications
// Request FCM token for notifications
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted' && messaging) {
      const fcm_token = await getToken(messaging, { vapidKey: firebase_valid_key.key });
      return fcm_token; // Return the token
    } else if (!messaging) {
      console.error("Firebase messaging is not initialized.");
      return null;
    } else {
      console.warn("Notification permission not granted");
      return null; // Return the token
    }
  } catch (error) {
    console.error("Permission denied or error occurred:", error);
    return null;
  }
};
  

// Listen for foreground messages
export const handleForegroundMessages = () => {
  if (!messaging) {
    console.error('Firebase Messaging is not initialized.');
    return;  // Return early if messaging is not initialized
  }

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Customize notification UI or handle the message payload here
  });
};