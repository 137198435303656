import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ModalOfferingPlace from './modalOfferingplace'
import leftArrow from '../../../assets/images/backarrow.svg'
import { useDispatch } from 'react-redux'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import burger from '../../../assets/images/burger-menu.svg'
import UserSidebar from '../../../Layout/userSidebar'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import { getApi } from '../../../Services/apiService'

function SessionServiceWhere({ nextStep, prevStep, step, data }) {
  const dispatch = useDispatch();
  const [classMode, setClassMode] = useState(data?.classMode || '')
  const [modalOpen, setModalOpen] = useState(false)
  const [offeringType, setOfferingType] = useState(data?.personPlace || '')
  const [chargeFees, setChargeFees] = useState(data?.chargeFees || '')
  const [classState, setClassState] = useState(false)
  const [errors, setErrors] = useState({});
  const [getAddress, setGetAddress] = useState([]);
  const [addressForm, setAddressForm] = useState({})
  const [travelArea, setTravelArea] = useState(false)
  const [chargeTravel, setChargeTravel] = useState(false)
  const [inputTravelArea,setInputTravelArea] = useState('')
  const [inputChargeTravel,setInputChargeTravel] = useState('')
  // const [personPlace,setPersonPlace] = useState('')

  useEffect(() => {
    handleGetAddress();
  }, [])

  const handleGetAddress = async () => {
    const response = await getApi('/address')
    console.log('getAddress', response)
    setGetAddress(response?.data)
  }

  console.log("offeringTypeofferingTypeofferingTypeofferingType", offeringType)
  // const offeringValue = ['At my place', 'I can travel', 'Both']
  const offeringValue = [{ name: 'At my place', label: 'place' }, { name: 'I can travel', label: 'travel' }, { name: 'Both', label: 'both' }]

  const validateForm = () => {
    const newErrors = {};

    // if (!classMode) {
    //   newErrors.classMode = 'Class mode is required';
    // }

    // if (classMode === 'in_person' && !offeringType) {
    //   newErrors.offeringType = 'Offering type is required';
    // }

    // if ((offeringType === 'travel' || offeringType === 'both') && !chargeFees) {
    //   newErrors.chargeFees = 'Travel price details are required';
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleType = (value) => {
    setOfferingType(value)
    // setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  };
  const handleSave = () => {
    const values = {
      classMode: classMode,
      personPlace: offeringType,
      travelArea:inputTravelArea,
      chargeTravel:inputChargeTravel,
      locationInput:addressForm,
      // in_person_option:3
    }
    if (chargeFees) {
      values.chargeFees = chargeFees
    }
    if (validateForm()) {
      dispatch(insertOfferingData(values))
      nextStep();
    }
  }

  const handleSetMode = (type) => {
    const values = {
      classMode: type,
    }
    // if (validateForm()) {
    dispatch(insertOfferingData(values))

    // }
    nextStep('types', type);
  }
  useEffect(() => {
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])
  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  return (
    <div>
      <UserSidebar classState={classState} />
      <div className='right-main'>
        <div class="session step-2 where">
          <div class="header">
            <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Session or Service</h1>
            <div class="top-buttons">
              {/* <Link to='/creator/offering/session'> */}
              <button class="save-draft" onClick={closeModal}>Save to Draft</button>
              {/* </Link> */}
              {/* <Link to='/creator/offering/sessioncharge'> */}
              <button class="next" onClick={handleSave}>Next</button>
              {/* </Link> */}
            </div>
          </div>
          {/* <!-- active links --> */}
          <div class="active-links">

            <button className="back" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>

            <ActiveLinks step={step} />
          </div>





          {/* <!-- Main --> */}
          <div class="main">
            <div class="row">
              <div class="col-sm-7">
                <div class="left">
                  <h1>Where</h1>


                  <div class="size-range bottom">
                    <h6>How class will happen?</h6><img src="images/green-tick.svg" class="green-tick" alt='' />
                    <div class="bottom-buttons">
                      <label class="radio-check3">
                        <input type="radio" checked={classMode === 'online'} name="radio" onClick={() => { setClassMode('online'); handleSetMode('online') }} />
                        <span class="checkmark">Online</span>
                      </label>
                      <label class="radio-check3">
                        <input type="radio" checked={classMode === 'in_person'} name="radio" onClick={() => { setClassMode('in_person'); handleSetMode('in_person') }} />
                        <span class="checkmark">In Person</span>
                      </label>
                      <label class="radio-check3">
                        <input type="radio" checked={classMode === 'hybrid'} name="radio" onClick={() => { setClassMode('hybrid'); handleSetMode('hybrid') }} />
                        <span class="checkmark">Hybrid</span>
                      </label>
                    </div>
                    {errors.classMode && <div className="error">{errors.classMode}</div>}
                    {/* <!--  --> */}
                    {/* <h6>Where in person?</h6> */}

                    <div className='in-person'>
                      <div className='form-group'>
                        <label>Location</label>
                        <input type="text" className='form-control' placeholder="Street Name and Number, apartment number" value={addressForm?.country} onClick={() => setModalOpen(true)}></input>
                        <p>*Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                      </div>

                      <div className="switch-content can-travel">
                        <div className="switch-button">
                          <label className="switch">
                            <input type="checkbox" onClick={() => setTravelArea(!travelArea)} checked={travelArea} />
                            <span className="slider"></span>
                          </label>
                        </div>
                        <h3>I can Travel</h3>
                        {travelArea ?
                          <div className='form-group'>
                            <label>Travel Area</label>
                            <input type="text" className='form-control' placeholder="Type your area (Downtown Austin, North Austin,Sou..." onChange={(e)=>setInputTravelArea(e?.target?.value)}></input>
                          </div>
                          :
                          <>
                            <p>Allow users to suggest an alternative location for the session, in addition to the fixed address. This gives clients/students the option to have the session at their home or office if they prefer.</p>
                          </>
                        }
                      </div>
                      {travelArea &&
                      <div className="switch-content can-travel">
                        <div className="switch-button">
                          <label className="switch">
                            <input type="checkbox" onClick={() => setChargeTravel(!chargeTravel)} checked={chargeTravel} />
                            <span className="slider"></span>
                          </label>
                        </div>
                        <h3>Charge for Travel fee</h3>
                        {chargeTravel &&
                          <div className='form-group'>
                            <label>How you charge</label>
                            <input type="text" className='form-control' placeholder="Enter travel price details" onChange={(e)=>setInputChargeTravel(e?.target?.value)} ></input>
                          </div>
                        }
                      </div>
}

                    </div>

                    {errors.offeringType && <div className="error">{errors.offeringType}</div>}
                    {/* <div class="add-address">
                      <h1 onClick={()=>setModalOpen(true)}>Add address</h1>
                      <p>Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                    </div> */}
                  </div>
                  {/* {offeringType === 'both' &&
                    <div>
                      <div>Your Area: Downtown Austin, North Austin </div>
                      <div>Address: 123 Main Street, Anytown </div>
                    </div>

                  }
                  <div className='switch-content-outer'>
                    {(offeringType === 'travel' || offeringType === 'both') &&
                      <>
                        <div class="switch-content">
                          <div class="switch-button">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="slider"></span>
                            </label>
                          </div>
                          <h3>Same start time</h3>
                        </div>
                        <div class="how-you">
                          <p>How you charge</p>
                          <input type="text" placeholder="Enter travel price details" value={chargeFees} onChange={(e) => setChargeFees(e?.target?.value)} />
                          {errors.chargeFees && <div className="error">{errors.chargeFees}</div>}
                        </div>
                      </>
                    }
                  </div> */}

                </div>
                <ModalOfferingPlace offeringType={offeringType} closeModal={closeModal} modalOpen={modalOpen} type="where" getAddress={getAddress} setAddressForm={setAddressForm} />
              </div>
              <OfferingDetails data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionServiceWhere