import React, { useEffect, useState } from 'react'
import Search from '../../../assets/images/search.svg'
import arrowUpRight from '../../../assets/images/arrow-up-right.svg'
import UniqueOffering from '../../HomeComponent/uniqueOffering'
import { Link } from 'react-router-dom'
import jane from '../../../assets/images/jane.png'
import { getApi } from '../../../Services/apiService'
import { toast } from 'react-toastify'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'

function AllCreatorList() {

    const [creatorData,setCreatorData]=useState([])
    const [dataLimit,setDataLimit] = useState(6)
    const [searchName,setSearchName] =useState('')
    const [classState, setClassState] = useState(false)
  const [userData, setUserData] = useState([])
    useEffect(()=>{
        fetchCreatorData()
    },[searchName])
    useEffect(() => {
        const handleClick = () => setClassState(false);
    
        document.addEventListener('click', handleClick);
    
        // Cleanup event listener on component unmount
        return () => {
          document.removeEventListener('click', handleClick);
        };
      }, [])

    const fetchCreatorData = async() =>{
        const response = await getApi(`/creator/allcreator/auth?userName=${searchName}`);
        if (response?.statusCode === 200) {
            setDataLimit(6)
            setCreatorData(response?.data);
        } else {
          toast.error(response?.message)
        }
    }
    
    const handleLimitSet = () =>{
        setDataLimit(dataLimit + 6)
    }
    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
      };

    return (
        <>
        <div class="home">
        <UserSidebar classState={classState} name={userData} />
        <div class="right-main home-right">
            <section class="creators-top">
                <div class="container">
                    <div class="section">
                        <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Creators</h1>
                        <div class="creators-input">
                            <img src={Search} alt='' />
                            <input type="text" class="search" placeholder="Find offers or creators" onChange={(e)=>setSearchName(e?.target?.value)}/>
                        </div>
                    </div>
                    <div class="links">
                        <h3>Popular tags</h3>
                        <ul>                    
                            <li><a href="">Art</a> </li>
                            <li><a href="">Photo</a> </li>
                            <li><a href="">Education</a> </li>
                            <li><a href="">language Learning</a> </li>
                            <li><a href="">Yoga</a> </li>
                            <li><a href="">Meditation</a> </li>
                            <li><a href="">Cooking</a> </li>
                            <li><a href="">Health and Fitness</a> </li>
                            <li><a href="">Business</a> </li>
                            <li><a href="">Photo</a> </li>
                            <li><a href="">Education</a> </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="discover">
                <div class="container">
                    <UniqueOffering/>
                </div>
            </section>

            <section class="creators-mid">
			<div class="container">
				<div class="row">
            {creatorData && creatorData?.length>0 && creatorData.map((x,index)=>{
                return(
                index < dataLimit &&
                <>
                 <div class="col-sm-6">
                 <div class="section2-left">
                     <label>Design</label>
                     <label>Graphic design</label>
                     <label>UX&UI Design</label>
                     <div class="sec2-main">
                     <div class="profile-left">
                         <span> <img src={x?.user_profile_image} alt=''/></span>
                         {/* <span> <img src={jane} alt=''/></span> */}
                     </div>
                         <div class="profile-right">
                             <h2>{x?.name}</h2>
                             <h3>{x?.specializations[0]?.experience !=='' && `Experience ${x?.specializations[0]?.experience}` }</h3>
                             {/* <p>{x?.about}</p> */}
                             <p> {x?.about?.split(' ').slice(0, 20).join(' ') + (x?.about?.split(' ').length > 200 ? '...' : '')}</p>
                         </div>
                         <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
               </div>
               <Link to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
             </div>
             </div>
             </>
                )
            })}
                {/* <div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} alt=''/></span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                  <Link to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div>

				<div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} alt=''/></span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                  <Link to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div>
				<div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} /> </span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                    <Link  to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div>
				<div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} /> </span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                    <Link  to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div>
				<div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} /> </span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                    <Link to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div>
				<div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} /> </span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                    <Link  to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div>
				<div class="col-sm-6">
					<div class="section2-left">
                        <label>Design</label>
                        <label>Graphic design</label>
                        <label>UX&UI Design</label>
                        <div class="sec2-main">
                        <div class="profile-left">
                            <span> <img src={jane} /> </span>
                        </div>
                            <div class="profile-right">
                                <h2>JANE COOPER</h2>
                                <h3>Experience 2 years </h3>
                                <p>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                            </div>
                            <div className='mobile-view'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</div>
                  </div>
                    <Link  to="/creator/4"><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt=''/></button></Link>
                </div>
				</div> */}
				
			</div>
            {creatorData.length > dataLimit &&
			<button class="explore" onClick={handleLimitSet}>Load more Creators</button>
}
			</div>
		</section>
        </div>
</div>
        </>
    )
}   

export default AllCreatorList