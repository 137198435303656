import React, { useEffect, useState } from 'react';
import { getApi, putApi } from '../../../Services/apiService';
import dummyImage from '../../../assets/images/profile3.svg';
import { useNavigate } from 'react-router-dom';

function AdminBasics() {
    const [imageFile, setImageFile] = useState(null); // State to store the image file
    const [imagePreview, setImagePreview] = useState(null); // State to store the image preview URL
    const [profileDetails, setProfileDetails] = useState(''); // State to store the image preview URL
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        about: '',
        image: ''
    });
    const [isEditing, setIsEditing] = useState(false); // State to toggle between edit and view mode
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    // Fetch existing profile details and populate the form
    const fetchProfileDetails = async () => {
        const response = await getApi(`/admin/profile`);
        if (response?.statusCode === 200) {
            setFormData({
                name: response?.data?.name || '',
                email: response?.data?.email || '',
                about: response?.data?.about || '',
                image: response?.data?.user_profile_image || ''
            });

            // Set image preview to either the profile image or the dummy image
            setImagePreview(response?.data?.user_profile_image || dummyImage);
        } else {
            console.error('Error fetching profile details:', response?.message || 'Unknown error');
        }
    };

    // Handle image upload (store file and show preview)
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file); // Save the file for later use
            setImagePreview(URL.createObjectURL(file)); // Set image preview
        }
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


  const validateForm = () => {
    let validationErrors = {};
    if (!formData.name) {
        validationErrors.name = 'Name is required';
      } else if (formData.name.trim().length !== formData.name.length) {
        validationErrors.name = 'Name cannot contain spaces';
      }
    if (formData.about.trim().length !== formData.about.length) {
        validationErrors.about = 'About cannot contain spaces';
      }
      setErrors(validationErrors);
      return Object.keys(validationErrors).length === 0;
  

  }

    // Handle form submission (Save button)
    const handleSave = async () => {
        if(!isEditing){
            toggleEditMode()
        }
        else {
            try {
                if(validateForm()){
                setLoader(true);
                // Create a FormData object
                const fullFormData = new FormData();
                fullFormData.append('name', formData.name);
                fullFormData.append('about', formData.about);
                if (imageFile) {
                    fullFormData.append('profile_image', imageFile); // 'image' is the key name for the file
                }
                const response = await putApi('/admin/profile', fullFormData);
                if (response.statusCode === 200) {
                    setLoader(false);
                    // Update local storage with new details
                    const userDetails = JSON.parse(localStorage.getItem('userDetail'));
                    if (userDetails) {
                        userDetails.name = formData.name;
                        userDetails.user_profile_image = response?.data?.user_profile_image || userDetails.user_profile_image;
                        userDetails.about = formData.about;
                        localStorage.setItem('userDetail', JSON.stringify(userDetails));
                    }
                    navigate('/admin/members')
                    setIsEditing(false); // Exit edit mode after saving
                } else {
                    // Handle error
                }
            } }
            catch (error) {
                setLoader(false);
                console.error('Error submitting booking:', error);
            }
        }
    };

    // Toggle edit mode
    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    return (
        <div className="setting-main">
            <h2>{isEditing ? 'Edit Profile' : ' Profile'} </h2>
            <div className="setting-img basic-setting">
                <div className="setting-profile">
                    {/* Display the uploaded image preview or dummy image */}
                    <img src={imagePreview} alt="Profile Preview" className="profile-preview" />
                </div>
                {isEditing && (
                    <div className="upload-btn-wrapper">
                        <button className="btn">Upload new photo</button>
                        <input type="file" name="myfile" onChange={handleImageChange} />
                    </div>
                )}
            </div>

            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input 
                    name="name" 
                    type="text" 
                    id="name" 
                    placeholder="Marley Westervelt" 
                    className="form-control" 
                    value={formData.name} 
                    onChange={handleInputChange} 
                    readOnly={!isEditing} // Make input read-only if not editing
                />
                {errors.name && <div className='error'>{errors.name}</div>}

            </div>

            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input 
                    name="email" 
                    type="email" 
                    id="email" 
                    placeholder="marley@gmail.com" 
                    className="form-control" 
                    value={formData.email} 
                    readOnly // Email field is always read-only
                />
            </div>

            <div className="form-group">
                <label htmlFor="about">About me</label>
                <textarea 
                    name="about" 
                    className="form-control textarea" 
                    value={formData.about} 
                    onChange={handleInputChange} 
                    readOnly={!isEditing} // Make textarea read-only if not editing
                ></textarea>
                {errors.about && <div className='error'>{errors.about}</div>}

            </div>

            <div className="setting-btns">
            {isEditing ? 
                <button className="setting-btn" onClick={toggleEditMode}>
                    Cancel
            </button> : null }
                <button className="setting-btn save"  onClick={() => handleSave()} disabled={loader}>
                {loader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                    {isEditing ? 'Save' : 'Edit'}
                </button>
            </div>
        </div>
    );
}

export default AdminBasics;
