import React from 'react'
import DefineOffering from './defineOffering'
import AvailabilityOffering from './availabilityOffering'
import SessionSessionHybrid from './sessionSessionHybrid'
import SessionDiscovery from './sessionDiscovery'
import OfferingDetails from './OfferingDetails'
import { useSelector } from 'react-redux'

function EditOffering() {
  const formState = useSelector((state) => state.offerCounter);
  return (
    // <div>EditOffering</div>
    <>
     <div className='edit-offering'>
     <div className='edit-offer-one'>
    <DefineOffering  nextStep='' prevStep='' data={formState} step='' submitForm='' setDraftData='' typess={'edit'}/>
    </div>
    <div className='edit-offer-two'>
    <AvailabilityOffering nextStep='' prevStep='' step='' data={formState} submitFormtype='' type={'edit'}/>
    </div>
    <div className='edit-offer-three'>
    <SessionSessionHybrid nextStep='' prevStep='' step='' data={formState} submitFormtype='' type={'edit'}/>
    </div>
    <div className='edit-offer-four'>
    <SessionDiscovery nextStep='' prevStep='' step='' data={formState} submitFormtype='' adminType='' type={'edit'}/>
    </div>
    </div>
    {/* <OfferingDetails data={'data'} /> */}
   
    </>
  )
}

export default EditOffering