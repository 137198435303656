import React, { useState,useEffect }  from 'react'
import checkCircle from '../../../assets/images/check-circle-2.svg'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { insertData } from '../../../Counter/signupSlice'
import { getApi, postApi } from '../../../Services/apiService';
import { clearUserData } from '../../../Counter/userSignUpSlice';
import { useNavigate } from 'react-router-dom';
import Header from './header'


function EmailVerify({ nextStep, prevStep, data, showGoTo,comp,emailData,emailType }) {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [TextChange,setTextChange] = useState(true)
  const [loader,setloader] = useState(false)
  console.log('this is details', data);
  console.log('this is show to data', showGoTo);
  console.log('this is comp', comp);
  console.log('this is text change', TextChange);




useEffect(() => {
  if(comp === undefined){
  setTextChange(false)
}
}, [])
  const onSubmit = async (value) => {
    setloader(true)
    // dispatch(insertData('end'));
    try {
      let values ={};
      values = {
        email: data?.email,
      };
      if(emailType === 'resetPAssword'){
        values = {
          email: emailData !== '' ? emailData :'',
        };
      } 

      console.log('Data to send:', values);
      let response;
if(value === " Send again"){
  // response = await postApi('/forgot-password', { email: values.email });
  response = await postApi('/verify-email', { email: values.email });
}
if(value === ' Send Link on Email'){
  response = await postApi('/forgot-password', { email: values.email });
}

      if (response.statusCode === 200) {
        setloader(false)
        if(comp === 'login'){
          setTextChange(false)
        }
        // Proceed to the next step
        //  nextStep()

      }else{
        setloader(false)
      }
    } catch (error) {
      console.error('Error submitting booking:', error);
    }
  }

  const dashboard = async (values) => {
    // setloader(true)
    // dispatch(insertData('end'));
    try {
      const values = {
        email: data?.email,
      };

      console.log('Data to send:', values);
      const response = await postApi('/verify-email-status', { email: values.email });
      if (response.statusCode === 200) {
        // setloader(false)
        // Proceed to the next step
        //  nextStep()
       

        if (data?.role === "creator") {
          dispatch(clearUserData())
          navigate('/creator/home')

        }

        if (data?.role === "user") {
          dispatch(clearUserData())
          navigate('/user/home')

        }

      } else{
        // setloader(false)
      }
    } catch (error) {

      console.error('Please verify your email first', error);
    }
  }

  return (
    <div className="detail-page congratulations signup-cs check-email">
       <Header/>
      <div className="detail-main">
        <div className="congratulations">
          {/* <span> <img src={checkCircle}/> </span> */}
          {/* <h1>Congratulations!</h1>
                <p>You have successfully registered! We will be in touch with you shortly.</p> */}
               {comp === 'login' ? (
          <h2>Please, Verify Your Email</h2>
      ) : (
        <h2>Please, Check your email</h2>
      )}
          <div className='mail-link'>
            <a>{data?.email}</a>
            {comp === 'login' ? (
                   <p>It looks like you haven't verified your email yet. To complete your registration, please click on the below button to receive verification link.</p>
      ) : (
        <p>We've sent you a confirmation link. Click on the link to verify your email address. Thank you!</p>
      )}
          </div>
          {/* {/ <Link to='/creator'> /} */}
          <div className='bottom-link'>
            {!TextChange ? (
            <a > Didn't receive an email?</a>
            ):(
              <></>
            )}

            <button className="button" onClick={() => onSubmit(comp ==='login' && TextChange ?' Send Link on Email' : ' Send again' )} disabled={loader}>
              {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}{comp ==='login' && TextChange ?' Send Link on Email' : ' Send again' }</button>

            {showGoTo !== '0' && emailType !== 'resetPAssword' && (
              <button className="button" onClick={() => dashboard()} disabled={loader}>Go to {data?.role === "creator" ? 'Creator' : 'User'} Dashboard</button>
              // <button className="button" onClick={() => dashboard()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Go to {data?.role === "creator" ? 'Creator' : 'User'} Dashboard</button>

            )}

          </div>
          {/* {/ </Link> /} */}
          {/* <p className="bottom-p">*Please note: you won't be able to publish an offer until you complete the verification process.</p> */}
        </div>

      </div>
      <div className="back-button">
        {/* <button className="button" onClick={() => prevStep()}><img src={leftArrow} /> </button> */}
      </div>
    </div>
  )
}

export default EmailVerify