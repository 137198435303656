import React from "react";
import sucess from '../../../assets/images/successfull.svg'
import dummyProfile from '../../../assets/images/profile3.svg'

function CalendarLook() {
    return (
        <>
            <div className=" booking signup-cs">
                <div className="detail-page  booking signup-cs">
                <div className="container">

                   
                    <div className="share-main"> 
                  
                    <img src={sucess } alt='' className="mb-2"/>   
                    <h2 className=''>Discovery Call successfully booked</h2>
                    <p>Your call has been added to the calendar and we have sent you link to your email</p>
                    <div className="booking-circle-img">
                        <span className="img">
                            <img src={dummyProfile} alt=''/>
                        </span>
                    </div>
                    <div className="">
                        <button className="btn btn-save">Add to calendar</button>
                    </div>
                    </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CalendarLook;