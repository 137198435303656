import React from 'react'
import Sidebar from './sidebar'
import addTag from '../../../assets/images/add-tag.svg'
import search from '../../../assets/images/search.svg'
import necklace from '../../../assets/images/necklace.png'
import paint from '../../../assets/images/paint.svg'
import heart from '../../../assets/images/heart.svg'
import settingLogo from '../../../assets/images/setting-logo.svg'
import buttonXAxis from '../../../assets/images/button-x-axis.svg'
import annete from '../../../assets/images/annete.png'

function OfferingActive() {
  return (
    <div class="creators-detail offering-active">

      <Sidebar />
      {/* <!-- creator offering right --> */}
      <div class="offering-active-right">
        <div class="top">
          <h1 class="my-offering">My Offerings</h1>
          <button class="create"><img src={addTag} alt="" /> Create a new offering</button>
        </div>
        <div class="search-buttons">
          <div class="offering-input">
            <img src={search} alt='' />
            <input type="text" class="search" placeholder="Find offers or creators" />
          </div>
          <div class="top-button">
            <label class="radio-check2">
              <input type="radio" checked="checked" name="radio" />
              <span class="checkmark">Active</span>
            </label>
            <label class="radio-check2">
              <input type="radio" checked="checked" name="radio" />
              <span class="checkmark">Archive</span>
            </label>
          </div>
        </div>
        <div class="looking-for">
          <h2>What are you looking for</h2>
          <label class="check3"> Online Session
            <input type="checkbox" checked="checked" />
            <span class="checkmark"></span>
          </label>
          <label class="check3">Offline Session
            <input type="checkbox" checked="checked" />
            <span class="checkmark"></span>
          </label>
          <label class="check3">Physical product
            <input type="checkbox" checked="checked" />
            <span class="checkmark"></span>
          </label>
          <label class="check3">Digital Product
            <input type="checkbox" checked="checked" />
            <span class="checkmark"></span>
          </label>
        </div>
        {/* <!-- main section start --> */}
        <section class="main">
          <div class="row">
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="product-box">
                <div class="product-img">
                  <img src={necklace} />
                  <div class="product-image-data">
                    <span><img src={paint} /></span>
                    <h5>Product</h5>
                    <button><img src={heart} /></button>
                  </div>
                </div>
                <div class="product-data">
                  <h3>Necklace </h3>
                  <ul>
                    <li class="active">Accessorize</li>
                    <li>Jewellery</li>
                    <li>Handmade</li>
                  </ul>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.</p>
                  <div class="date-time">
                    <ul>
                      {/* <!-- <li><img src="images/date.svg">01.04.2024 </li>
                                                <li><img src="images/clock.svg">8:00 AM </li> --> */}
                      <li> <img src={settingLogo} alt='' />Processing</li>
                    </ul>
                    <button class="button"><img src={buttonXAxis} alt='' /> </button>
                  </div>
                  <div class="product-data-bottom">
                    <span> <img src={annete} alt='' /> </span>
                    <h6><strong>Marley Westervelt</strong>Handcrafted jewellerr</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
    </div>
  )
}

export default OfferingActive