import React from 'react'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people-outline.svg'
import map from '../../../assets/images/map-pin.svg'

function VIewOfferingDetails({data}) {
  return (
    <div class="col-sm-6 col-xl-4 col-lg-6 mb-2">
    <div class="right second">
      <div className='offering-detail offering-detail-fw'>
      <h1>Offering Details</h1>
      <div className='right-social'>
        <button className='share'>
          <img src={share}></img>
        </button>
        <button className='like'>
          <img src={heart}></img>
        </button>

      </div>
      </div>
      <ul>
        {/* <div class="detail-top">
          <li><span>Category</span> <strong>{data?.name}</strong> </li>
          <li><span>Type</span> <strong>{data?.type}</strong> </li>
          <li><span>Tags</span> <strong>{data?.tagName}</strong> </li>
          <li><span>Group</span> <strong>{data?.sessionType}</strong> </li>
        </div>
        <div class="detail-middle">
          <li><span>Repeat</span> <strong>{data?.offeringType}</strong> </li>
          <li><span>Time</span> <strong></strong> </li>
          <li><span>Session duration</span> <strong></strong> </li>
        </div>
        <div class="detail-bottom">
          <li><span>How</span> <strong>{data?.personPlace}</strong> </li>
          <li><span>Place</span> <strong>{data?.address_id}</strong> </li>
        </div> */}

        {/* <li><img src={clock}></img> <h6>60 min</h6></li>
        <li><img src={calendar}></img> <h6>2x per week, 12 weeks</h6></li>
        <li><img src={people}></img> <h6>Private session</h6></li>
        <li><img src={map}></img> <h6>At creators place: Downtown Austin</h6></li> */}
         <li><span>Category</span> <strong>{data?.categoryName}</strong> </li>
          <li><span>Type</span> <strong>{data?.type}</strong> </li>
          <li><span>Tags</span> <strong>{data?.tagName && data?.tagName?.length > 0 && 
      data?.tagName?.map((x, indx) => 
      x !== undefined &&
        `${x}${indx < data?.tagName?.length - 1 ? ', ' : ''}`
      )
    }</strong> </li>
          {/* <li><span>Tags</span> <strong>{data?.tagName}</strong> </li> */}
          {/* <li><span>Group</span> <strong>{data?.sessionType}</strong> </li> */}
          <li><span>Group</span> <strong>{data?.sessionTypes == 'private_session' && 'Individual Session' || data?.sessionTypes == 'group_session' && 'Group Session'}</strong> </li>
        {/* <li> */}
          <div className='offering-time mt-2 mb-1'>
  {/* <h3>April 6 (GMT+)</h3>
  <label className='time'>
    <input name='selectedtime_april6' type='radio' value='12:00pm'>
    </input>
    <span className='checkmark'>
      <h5>12:00pm</h5>
    </span>
  </label>
  <label className='time'>
    <input name='selectedtime_april6' type='radio' value='02:00pm'>
    </input>
    <span className='checkmark'>
      <h5>03:00pm</h5>
    </span>
  </label> */}
  {/* <li><span>Repeat</span> <strong>{data?.offeringType}</strong> </li>
          <li><span>Time</span> <strong></strong> </li> */}
           <li><span>Session duration</span> <strong>{data?.sessionTypes == 'private_session' && data?.sessionDuration + 'min'}</strong> </li>
</div>

<div className='offering-time mb-2 mt-0'>
  {/* <h3>April 12 (GMT+)</h3>
  <label className='time'>
    <input name='selectedtime_april12' type='radio' value='12:00pm'>
    </input>
    <span className='checkmark'>
      <h5>12:00pm</h5>
    </span>
  </label>
  <label className='time'>
    <input name='selectedtime_april12' type='radio' value='04:00pm'>
    </input>
    <span className='checkmark'>
      <h5>04:00pm</h5>
    </span>
  </label>
  <label className='time'>
    <input name='selectedtime_april12' type='radio' value='06:00pm'>
    </input>
    <span className='checkmark'>
      <h5>06:00pm</h5>
    </span>
  </label> */}
  <li><span>How</span> <strong>{data?.classMode}</strong> </li>
  <li><span>Place</span> <strong>{data?.classMode === 'online' ? data?.invitationLink : data?.locationInput && data?.locationInput?.address + ' ' + data?.locationInput?.country + ' ' + data?.locationInput?.state + ' ' + data?.locationInput?.city}</strong> </li>
</div>
{/* </li>    */}
{/* <li>
  <div className='offering-bottom'>
    <span className='price-info'>
      <h5>Price</h5> 
      <h4>$25</h4>
    </span>
    <button className='continue'>Continue with booking</button>
  </div>
</li> */}


      </ul>
    </div>
  </div>
  )
}

export default VIewOfferingDetails