import React, { useState } from 'react'
import UpdownArrow from '../../../assets/images/up-down-arrow.svg'
// import { RRule, rrulestr } from 'rrule';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { RRule } from 'rrule-alt';
import { useDispatch, useSelector } from 'react-redux';
import { insertOfferingData } from '../../../Counter/offeringSLice'
import RadioButton from './radioButton';

function DailyOffering({closeModal}) {

  // const daysOfWeek = [
  //   { label: 'Sunday', value: RRule.SU },
  //   { label: 'Monday', value: RRule.MO },
  //   { label: 'Tuesday', value: RRule.TU },
  //   { label: 'Wednesday', value: RRule.WE },
  //   { label: 'Thursday', value: RRule.TH },
  //   { label: 'Friday', value: RRule.FR },
  //   { label: 'Saturday', value: RRule.SA },
  // ];
  // const [events, setEvents] = useState([]);
  // const [title, setTitle] = useState('');
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [frequency, setFrequency] = useState(RRule.DAILY);
  // const [interval, setInterval] = useState(1);
  // const [count, setCount] = useState(10);
  // const [selectedDays, setSelectedDays] = useState([]);

  // // const handleDayChange = (day) => {
  // //   setSelectedDays(prevDays =>
  // //     prevDays.includes(day)
  // //       ? prevDays.filter(d => d !== day)
  // //       : [...prevDays, day]
  // //   );
  // // };

  // const handleGenerate = () => {
  //   const ruleOptions = {
  //     freq: frequency,
  //     interval: interval,
  //     dtstart: startDate,
  //     count: '10',
  //   };

  //   if (frequency === RRule.WEEKLY) {
  //     ruleOptions.byweekday = selectedDays;
  //   }

  //   const rule = new RRule(ruleOptions);
  //   const recurrenceRule = rule.toString();
  //   const event = {
  //     title: title,
  //     start: startDate.toISOString(),
  //     end: endDate.toISOString(),
  //     recurrenceRule: recurrenceRule,
  //   };
  //   onGenerate(event);
  // };

  // const onGenerate = (event) => {
  //   console.log("event", event);
  //   const rule = rrulestr(event.recurrenceRule);
  //   const dates = rule.all();
  //   const newEvents = dates.map((date) => ({
  //     start: new Date(date),
  //     end: new Date(date).setHours(
  //       new Date(date).getHours() + (new Date(event.end).getHours() - new Date(event.start).getHours())
  //     ),
  //     title: event.title,
  //   }));

  //   setEvents(newEvents);
  // };


  const [dailyType,setDailyType] = useState('')
  const [typeValue,setTypeValue] = useState({})
  const [radioType,setRadioType] = useState('');
  // const [typeValue,setTypeValue] = useState({
  //   date: new Date(),
  //   after: ''
  // })
  const formState = useSelector((state) => state.offerCounter);

  const dispatch = useDispatch();
  const handleSubmitData = () => {

    
  }

  const handleChangeData = (e,type) => {
    setDailyType(type)
    setRadioType(type)
    setTypeValue({[type]:e?.target?.value})
    // if(type === 'after'){
    //   setTypeValue({['date']:e?.target?.value})
    // //   setTypeValue((prevValue) => ({
    // //     ...prevValue,
    // //     ['after']: e?.target?.value,
    // // }));
    // }
    // if(type === 'date'){
    //   setTypeValue({['date']:e?.target?.value})
    // //   setTypeValue((prevValue) => ({
    // //     ...prevValue,
    // //     ['date']: e?.target?.value,
    // // }));
    // }
  }

  console.log("dailyTypedailyType",dailyType)
  console.log("typeValuetypeValue",typeValue)

  const handleGenerate = () => {
    // daily
    const ruleOptions = {
      freq: 3,//1 for month 2 for week 3 for daily
      interval: 1,
      dtstart: new Date(),
      count: typeValue?.after || 12,
    };

    const rule = new RRule(ruleOptions);
    const recurrenceRule = rule.toString();
    console.log('recurrenceRulerecurrenceRulerecurrenceRulerecurrenceRule',recurrenceRule)
    // const event = {
    //   title: 'title',
    //   start: fixedData.toISOString(),
    //   // end: endDate.toISOString(),
    //   recurrenceRule: recurrenceRule,
    // };
    // onGenerate(event);
  };

  const handleSave = ()=>{
    const values ={
      // type:'',
      // after:'',
      // count:'',
      type:dailyType,
      // date:typeValue?.date || typeValue?.after,
    }
    if(dailyType === 'date'){
      values.date = typeValue?.date
      values.count= ''
    }
    if(dailyType === 'after'){
      values.count= typeValue?.after
      values.date = ''
    }

    dispatch(insertOfferingData(values))
    closeModal()
  }

  return (
    <>
     
<div id="class-daily" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
        <div class="class-daily">
            <div class="top">
                <h1>Repeat Class - Daily</h1>
            {/* <div class="end-class">
            <h2>End class</h2>
            <ul>
                <li onClick={()=>setRadioType('never')}><label class="container">Never
                    <input type="radio" checked={radioType === 'never'} name="radio" />
                    <span class="checkmark"></span>
                  </label> </li>
                  <li onClick={()=>setRadioType('date')}><label class="container">Date
                    <input type="radio" checked={radioType === 'date'} name="radio"/>
                    <span class="checkmark"></span>
                  </label> </li>
                  <li onClick={()=>setRadioType('after')}><label class="container">After
                    <input type="radio" checked={radioType === 'after'} name="radio" />
                    <span class="checkmark"></span>
                  </label> </li>
            </ul>
            <div class="date-section">
              <input type="date" class="date" onChange={(e)=>handleChangeData(e,'date')}/>
              <div class="time-img">
              <input type="text"class="time" placeholder="12 times"  onChange={(e)=>handleChangeData(e,'after')}/>
              <img src={UpdownArrow} alt=''/>
              </div>
          </div>
            </div> */}
            <RadioButton radioType={radioType} setRadioType={setRadioType} handleChangeData={handleChangeData}/>
           
           
            </div>
            <div class="bottom">
                <button class="cancel" onClick={()=>closeModal}>Cancel</button>
                <button class="save" onClick={handleSave}>Save</button>
                {/* <button class="save" onClick={handleGenerate}>Save</button> */}
            </div>
        </div>
        
        </div>
        
      </div>
    </div>
  </div>
    </>
  )
}

export default DailyOffering