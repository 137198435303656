import React, { useEffect, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar'
import arrowWhite from '../../../assets/images/arrow-up-right3.svg'
import noUserBox from '../../../assets/images/medical_discover.svg'
import { Link, useNavigate } from 'react-router-dom'
// import jennny from '../../../assets/images/jenny.svg'

// import facebookgrey from '../../../assets/images/user-social1.svg'
import linkdngrey from '../../../assets/images/linkedin-grey.svg'
import instagramgrey from '../../../assets/images/Insta-grey.svg'
import twittergrey from '../../../assets/images/twiter-grey.svg'
import { getApi, postApi } from '../../../Services/apiService'
import burger from '../../../assets/images/burger-menu.svg'
// import annete from '../../../assets/images/annete.png'
// import dummy from '../../../assets/images/dummy-profile.jpg'
import linkdn from '../../../assets/images/linkedin.svg'
import twitter from '../../../assets/images/creator-twitter.svg'
import instagram from '../../../assets/images/Instagram-Logo.wine 1.svg'
import dummyProfile from '../../../assets/images/profile3.svg'
import BookInterview from '../../CreatorComponents/CreatorJoin/bookInterview'
import SubmitForm from '../../CreatorComponents/CreatorJoin/submitForm'
import Modal from 'react-modal';
import AboutDetail from '../../CreatorComponents/CreatorJoin/aboutDetail'

function UserHome() {

	const navigate = useNavigate()
	const [classState,setClassState] = useState(false)
	const [userData,setUserData] = useState([])
	const [bookingInterview,setBookingInterviw] = useState(false)
    const [dashboardModal,setDashboardModal] = useState(false)
    const [step, setStep] = useState(1);

	const nextStep = () =>{
		setStep(step+1)
	}
	
	const prevStep = () =>{
		setStep(step-1)
	}
	

  const localDataUser = JSON.parse(localStorage.getItem('userDetail'));
  const id = localDataUser?.id;
  useEffect(()=>{
		fetchUserDeatils()
		const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  },[])

  const userSocialLink = JSON.parse(localStorage.getItem('user_social_links'))

  const fetchUserDeatils = async() =>{
	const response = await getApi('/user/dashboard');
	if(response?.statusCode === 200){
		localStorage.setItem('user_role',response?.data?.roles[0]?.user_role)
		localStorage.setItem('user_social_links',JSON.stringify(response?.data?.social_links))
		localStorage.setItem('full_user_detail',JSON.stringify(response?.data))
		setUserData(response?.data);
	}
	else{
		localStorage.clear();
		navigate('/')
	  }
  }
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleText = () => {
	setIsExpanded(!isExpanded);
};
  // Ensure description is defined and is a string
  const description = userData?.about || "Don't forget to complete the 'About Me' section";
  const isLongDescription = description.length > 300;
  const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };
//   /user Dashboard
// https://microcosm.enactstage.com/microcosmapi/api/v1/user/dashboard

// //creator Dashboard
// https://microcosm.enactstage.com/microcosmapi/api/v1/creator/dashboard


// switch (step) {
// 	case 1:
//         return <AboutDetail nextStep={nextStep} prevStep={prevStep} data={''} typeUser={'userSide'} />;
// 	case 2:
//         // return <AboutDetail nextStep={nextStep} prevStep={step} data={formState} typeUser={'userSide'} />;
		
// 		default:
// 			return <div>Unknown step</div>;
// 		}


  return (
    <div class="creators-detail user-home">
		<UserSidebar classState={classState}/>
<div class="right-main creators-detail-right user-home">
		<section class="creators-top detail res-creator-cs">
				<div class="my-page"> <button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt=''/></button> My Page </div>
				{/* <div class="my-page"> <button class="burger-menu" onClick={()=>setClassState(!classState)}><img src={burger} alt=''/></button> My Page </div> */}
				<button class="become-creator" onClick={()=> navigate('/user/become-creator')}>Become a creator</button>
				<div class="row">
					<div class="col-sm-4 col-xl-3  col-lg-4">
						<div class="creator-detail-left">
							<div class="creator-detail-img bg-none">
							<span><img src={userData?.user_profile_image  || dummyProfile} alt=''/></span></div>
							<div class="creator-social home-user">
								<ul>
								<li><Link to={userSocialLink && userSocialLink[0]?.facebook_link ? userSocialLink[0]?.facebook_link : '' }><img src={userSocialLink && userSocialLink[0]?.facebook_link ? linkdn : linkdngrey}/></Link></li>
									<li><Link to={userSocialLink && userSocialLink[0]?.instagram_link ? userSocialLink[0]?.instagram_link : ''}><img src={userSocialLink && userSocialLink[0]?.instagram_link ? instagram : instagramgrey}/></Link></li>
									<li><Link to={userSocialLink && userSocialLink[0]?.twitter_link ? userSocialLink[0]?.twitter_link : ''}><img src={userSocialLink && userSocialLink[0]?.twitter_link ? twitter : twittergrey}/></Link></li>
								</ul>
							</div>
							<div className='creator-social home-user mange-profile'>
							<h6>Manage profile info <img src={arrowWhite} alt="" /></h6>
						</div>
						
						</div>
						
						
					</div>
					<div class="col-sm-8">
						<div class="creator-detail-right user-home">
							<h3>{userData?.name}</h3>
							<h4>USER</h4> 
							<h5>About Me </h5>
							<p>
							{isExpanded || !isLongDescription ? description : truncatedDescription}
                                        <br />
                                        {isLongDescription && (
                                            <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </strong>
                                        )}
							</p>
							{/* {userData?.about != null && <p>
								Don't forget to complete the 'About Me' section
							</p>} */}
							{/* <div class="links mention-tags user-home">
							<h4>Interests</h4>
							<ul>
								{userData?.length !=0 && userData?.categories.length>0 && userData?.categories.map((x,index)=>{
									return(
									<li key={index}><a href="#">{x}</a> </li>
									)
								})}
								 <li><a href="#">Fitness</a> </li>
								<li><a href="#">Yoga</a> </li>
								<li><a href="#">Languaga Learning</a> </li>
								<li><a href="#">Art</a> </li>
								<li><a href="#">Handmade	</a> </li>
								<li><a href="#">Health and Fitness	</a> </li> 
		
		
							</ul>
						</div> */}
						</div>
						
						
					</div>
				</div>
		</section>


		<section class="offerings">
			<h3 class="journey">Journey</h3>
				<div class="no-user-main">
                    <span><img src={noUserBox} alt=''/> </span>
                    <p class="no-user-content">You haven't purchased any products yet.<br/> 
                        Visit our <strong>Discovery</strong> section to explore what's available!</p>
                        <Link class="nav-link" to="/user/offering"><button class="become-creator discoverthing">Discover Offerings <img src={arrowWhite} alt=''/> </button></Link>
                </div>
		</section>

		
</div>


<Modal
        isOpen={bookingInterview}
        onRequestClose={() => setBookingInterviw(false)}
        contentLabel="Login Modal"
        portalClassName="Login-modal-tag"
        shouldCloseOnOverlayClick={true}
      >
  <BookInterview nextStep={''} prevStep={''} data={''} submitForm={''} loader={''} loginStatus={'googleLogin'} setDashboardModal={setDashboardModal} setBookingInterviw={setBookingInterviw}/> {/* Pass selectedDays as data */}
      </Modal>
            <Modal
        isOpen={dashboardModal}
        onRequestClose={() => setDashboardModal(false)}
        contentLabel="Login Modal"
        portalClassName="google-login-modal"
        shouldCloseOnOverlayClick={false}
      >
  <SubmitForm prevStep={''} submitForm={''} loader={''} loginStatus={'googleLogin'}/> {/* Pass selectedDays as data */}
      </Modal>
	</div>
  )
}

export default UserHome