import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBHu8Qc1vSfi3uvJLmL_7BsBszbJfuusNE",
  authDomain: "microcosm-9def0.firebaseapp.com",
  projectId: "microcosm-9def0",
  storageBucket: "microcosm-9def0.appspot.com",
  messagingSenderId: "812332518999",
  appId: "1:812332518999:web:61ed4c88f867ab2f9f1d12",
  measurementId: "G-3RFFFXDNXG"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Lazy load messaging to avoid initialization errors
// Lazy load messaging
let messaging;
try {
  messaging = getMessaging(firebaseApp);
  console.log('messagingmessaging',messaging);
  console.log("Firebase messaging initialized successfully.");
} catch (error) {
  console.error("Error initializing Firebase messaging:", error);
}


// Firestore and Auth initialization
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

// Firebase valid key
export const firebase_valid_key = {
  // key: 'BB1Yb0FeHZp9SKRMM5ZH0QfJPnGjmI88PdU1VJBp9zNjDhRQXvXb9Lw7oJ8tEyUmR6-Ev3kwDtOPJVjVLN-RGrY',
  key: 'BC-d2c0R56smRKNSfwCIPmMLSCXektDvkpBX862zY18LWUYT2gK7gP_MX2iObON3Dd1gvRU8mZ1k4k1K1YVzdVk'
};

// Export messaging-related functions
export { auth, messaging, getToken, onMessage };  // Added 'onMessage'
export default db;
