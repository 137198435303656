import React, { useEffect, useState, useCallback, useRef } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import { getApi, putApi,deleteApi } from '../../../Services/apiService'
import addtag from '../../../assets/images/add-tag.svg'
import { useNavigate } from 'react-router-dom';
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import hearts from '../../../assets/images/heart.svg'
import NoDataFound from '../../AuthComponent/noDataFound';
import search from '../../../assets/images/search.svg'
import { toast } from 'react-toastify';
import { clearOfferingData, insertOfferingData } from '../../../Counter/offeringSLice';
import { useDispatch } from 'react-redux';
import { clearProductData } from '../../../Counter/productSlice';
import { insertProductData } from '../../../Counter/productSlice';
import doublebox from '../../../assets/images/double-box.svg'
import threedots from '../../../assets/images/three-dots-horizontal.svg'
import resotre from '../../../assets/images/restore-icon2.svg'
import edit from '../../../assets/images/edit-icon-2.svg'
import deleteoffer from '../../../assets/images/delete-2.svg'
import publish from '../../../assets/images/publish-ison2.svg'
import DeleteOffering from './deleteOffering';
import Modal from 'react-modal'
import cross from '../../../assets/images/gray-close5.svg'


function CreatorOfferingHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [classState, setClassState] = useState(false);
    const [apiResponse, setApiResponse] = useState('')
    const [offeringData, setOfferingData] = useState('')
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [status, setStatus] = useState(1); // Default to "Active"
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [loader, setloader] = useState(false); // State for search query
    const [deleteloader, setdeleteloader] = useState(false); // State for search query
    const [activeDropdown, setActiveDropdown] = useState(null); // State to track which dropdown is open
    const [btnloader, setBtnLoader] = useState(false)
    const [delteOffering,setDeleteOffering] = useState(false)
    const [delteOfferingId,setDeleteOfferingId] = useState('')
    const [delteOfferingData,setDeleteOfferingData] = useState({})


    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    const toggleDropdown = (id) => {
        setActiveDropdown((prevDropdown) => (prevDropdown === id ? null : id)); // Toggle the dropdown
    };

        const dropdownRef = useRef(null);
      
        // Close the dropdown if a click is detected outside of it
        useEffect(() => {
          function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setActiveDropdown(null); // Close the dropdown
            }
          }
      
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [setActiveDropdown]);

    useEffect(() => {
        fetchUserOfferings()
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [page, status, searchQuery]);

    const fetchUserOfferings = async () => {
        const response = await getApi(`/creator/creator-services?page=${page}&pageSize=9&status=${status}&search=${searchQuery}`);
        if (response?.statusCode === 200) {
            setApiResponse(response)
            setTotalCount(response.totalCount); // Set the total count from API response
            if(page === 1){
                setOfferingData(response.data)
            }
            else{
                setBtnLoader(false)
                setOfferingData((prevOfferings) => [...prevOfferings, ...response.data]); // Append new data to existing offerings
            }
        } else {
            // localStorage.clear();
            // navigate('/');
            toast.error(response?.message)
        }
    };

    const handleTabClick = (selectedStatus) => {
        setPage(1); // Reset page to 1 when switching tabs
        setOfferingData([]); // Clear current offerings when switching tabs
        setStatus(selectedStatus); // Update status based on the selected tab
        setActiveDropdown(null)
    };

    //   / Function to handle loading more offerings
    const loadMoreOfferings = () => {
        setBtnLoader(true)
        setPage((prevPage) => prevPage + 1); // Increment the page number
    };

    const offerStatusChange = async (id, datas) => { 
        setloader(true)
        const data ={
            serviceId:id,
            status: status === 1 ? '2' : '1'
        }

        const response= await putApi('/creator/service/status', data)
        fetchUserOfferings()
        setloader(false)


    }
    const deleteOffer = async (id,datas) =>{
        setdeleteloader(true)
        try {
            const response = await deleteApi(`/creator/service?offerId=${id}`);
            fetchUserOfferings()
            setdeleteloader(false)
            setDeleteOffering(false)
            console.log('Delete successful', response);
        } catch (error) {
            setdeleteloader(false)
            console.error('Error deleting offer', error);
        }

    }
    const delteOfferModal= (id,datas)=>{
        setDeleteOffering(true)
        setDeleteOfferingId(id)
        setDeleteOfferingData(datas)
        setActiveDropdown(null)
    }
    const singleService = (id, datas) => {
        if(status !==2){
        console.log('this is datas in single service click',datas);
        if (status === 3) {
            if (datas?.service_type === '2') {
                dispatch(clearProductData());

                // Map relevant fields for product_kind scenario
                let updatedData = { ...datas };
                // Additional fields specific to product_kind
                updatedData.additionalProcessingTime = datas?.additional_processing_time;
                updatedData.showImagePreview = datas?.cover_image;
                updatedData.deliveryCharge = datas?.delivery_charge === '0' ? false : datas?.delivery_charge === '1' ? true : null;
                updatedData.delivery_charge_per_mile = datas?.delivery_price;
                updatedData.selectedOption = datas?.delivery_option === '1' ? 'Pick up' : datas?.delivery_option === '2' ? 'Delivery' : datas?.delivery_option === '3' ? 'Both' : null;
                updatedData.description = datas?.description;
                updatedData.digitalFile = datas?.digital_file;
                updatedData.digitalLink = datas?.digital_link;
                updatedData.discoverManPrice = datas?.maximum_price;
                updatedData.maximumQuantityOrder = datas?.maximum_quantity_order;
                updatedData.messageUser = datas?.message_to_user;
                updatedData.discoverMinPrice = datas?.minimum_price;
                updatedData.productStatus = datas?.product_status;
                updatedData.productType = datas?.product_kind === '0' ? 'physical' : 'digital';
                updatedData.address = datas?.product_address;
                updatedData.availability = datas?.product_availbility === '0' ? 'ready' : 'order';
                updatedData.productImage = datas?.product_image;
                updatedData.quantityOrders = datas?.quantity_items;
                updatedData.tagName = datas?.tags;
                updatedData.discoveryPrice = datas?.pricing_type === '1' ? 'fixed_price' : datas?.pricing_type === '2' ? 'variable_price' : 'no_price';
                updatedData.step = datas?.step;

                // Process tags
                updatedData.creatorTag = datas?.tags;
                // updatedData.tagName = datas?.tags?.length > 0 ? datas?.tags.map(tag => tag.tag_name) : [];
                updatedData.tags = datas?.tags?.length > 0 ? datas?.tags.map(tag => tag.id) : [];

                // Log to console
                console.log("updatedData with product_kind:", updatedData);

                // Dispatch updated data
                dispatch(insertProductData(updatedData));

                // Navigate to offering page
                navigate(`/creator/offering/product`);
            }

            // this is for 
            else {

            
            dispatch(clearOfferingData())
            // nextStep(datas?.count)
            datas.categoryId = datas?.category?.id
            datas.sessionTypes = datas?.session_type === '1' ?'private_session' : datas?.session_type === '2' && 'group_session'
            datas.tagData = datas?.tags;
            datas.tagName = datas?.tags?.length>0 && datas?.tags?.map(tag => tag?.tag_name);
            const tagss = datas?.tags?.length>0 && datas?.tags?.map(tag => tag?.id);
            delete datas?.tags
            datas.tags = tagss
            datas.step = datas?.step +1;
            datas.classMode = datas?.location_type === '1' && 'online' ||datas?.location_type === '2' && 'in_person' ||datas?.location_type === '3' && 'hybrid'
            datas.invitationLink = datas?.invitation_link !==  "undefined" &&datas?.invitation_link
            datas.sessionType = datas?.session_offer_type === '1' && 'single_session' || datas?.session_offer_type === '2' && 'multiple_session'
            datas.sessionMultipleType = datas?.package_type === '1' && 'weekly' || datas?.package_type === '2' && 'monthly' || datas?.package_type === '3' && 'custom' || datas?.package_type === '4' && 'daily'
            // datas.recurrencess = datas?.session_occurrences && datas?.session_occurrences !== "undefined" && JSON.parse(datas?.session_occurrences.replace(/\\n/g, '\n'))
            datas.recurrencess = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && JSON.parse(datas?.draft_session_occurrences.replace(/\\(?!n)/g, ''))
            // datas.recurrencess = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && JSON.parse(datas?.draft_session_occurrences.replace(/\\/g, ' '))

            console.log("datas.recurrencessdatas.recurrencess",datas.recurrencess);
            
            // datas.discoveryPrice = datas?.pricing_type === '1' && 'fixed_price' || datas?.pricing_type === '2' && 'variable_price'
            // datas.discoveryMinPrice = datas?.price
            // datas.coverImage = datas?.cover_image
            // datas.maxGroupSize = datas?.max_group_size
            // datas.coverImage = datas?.cover_image
            // datas.discoveryMaxPrice = datas?.maximum_price
            // datas.timingZone = datas?.time_zone
           
            // datas.additionalImages = addImageArray
            datas.name = datas?.offer_name
            datas.sameTime = datas?.draft_same_time
            delete datas?.draft_same_time
            datas.selectedDayLabel = datas?.draft_times && datas?.draft_times !='undefined' && JSON.parse(datas?.draft_times)
            datas.addDateCalendar = datas?.draft_slot && datas?.draft_slot !='undefined' && JSON.parse(datas?.draft_slot)
            datas.sameTimeDuratioon = datas?.draft_same_duration && datas?.draft_same_duration !='undefined' && JSON.parse(datas?.draft_same_duration)
            delete datas?.draft_same_duration
            const weekday=[]
            datas?.draft_times && datas?.draft_times !=='null' && datas?.draft_times !='undefined' && datas?.selectedDayLabel.map((x,index)=>[
                weekday.push(x?.label)
            ])
            datas.dayLabel = weekday
            datas.sessionDuration = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && datas.recurrencess[0].sessionDuration
            datas?.step === 3 && delete datas?.classMode
            // datas.classMode = datas?.location_type === '1' && 'online' || datas?.location_type === '2' && 'in_person' || datas?.location_type === '1' && 'hybrid'
            // datas.sessionMultipleType =  datas?.package_type == '1' && 'weekly' || datas?.package_type == '2' && 'monthly' || datas?.package_type == '3' && 'custom' || datas?.package_type == '4' && 'daily'  
            // datas.recurrences = JSON.parse(datas?.session_occurrences)
            // datas.type = datas?.offer_type
            console.log("datas.sessionMultipleTypedatas.sessionMultipleType",datas.sessionMultipleType);

                console.log("datas.recurrencessdatas.recurrencess", datas.recurrencess);

                datas.discoveryPrice = datas?.pricing_type === '1' && 'fixed_price' || datas?.pricing_type === '2' && 'variable_price'
                datas.discoveryMinPrice = datas?.price || datas?.minimum_price
                // datas.coverImage = datas?.cover_image
                datas.maxGroupSize = datas?.max_group_size
                // datas.coverImage = datas?.cover_image
                datas.discoveryMaxPrice = datas?.maximum_price
                datas.timingZone = datas?.time_zone
                const addImageArray = []
                datas?.offerMaterials && datas?.offerMaterials?.length > 0 && datas?.offerMaterials.map((x, index) => {
                    addImageArray.push(x?.file_url)
                })
                // datas.additionalImages = addImageArray
                datas.name = datas?.offer_name
                datas.setDraftGroup = true  
                if(datas?.step >= 3){
                datas.svaeSlotWeek = true
                datas.manageCalendar = false
                datas.showManage = true
                }
                // else{
                // datas.svaeSlotWeek = false
                // datas.manageCalendar = false
                // datas.showManage = false
                // }
                datas.addOnDay = datas?.draft_add_day!=undefined && datas?.draft_add_day!=null && JSON.parse(datas?.draft_add_day)
                datas.sessionPackage = datas?.no_of_sessions
                datas.discoveryCall = datas?.discovery_call != null && datas?.discovery_call != undefined && (datas?.discovery_call =='1' ? true :false) 
                datas.timeFrame = { number: datas?.package_timeframe || '', months: datas?.package_period || '' }
                datas.locationInput = {
                    country: datas?.address?.country,
      state: datas?.address?.state,
      zip_code: datas?.address?.zipCode,
      city: datas?.address?.city,
      travel_area: datas?.address?.travelArea || '',
      address: datas?.address?.address
                }
                // datas.classMode = datas?.location_type === '1' && 'online' || datas?.location_type === '2' && 'in_person' || datas?.location_type === '1' && 'hybrid'
                // datas.sessionMultipleType =  datas?.package_type == '1' && 'weekly' || datas?.package_type == '2' && 'monthly' || datas?.package_type == '3' && 'custom' || datas?.package_type == '4' && 'daily'  
                // datas.recurrences = JSON.parse(datas?.session_occurrences)
                // datas.type = datas?.offer_type
                console.log("datas.sessionMultipleTypedatas.sessionMultipleType", datas.sessionMultipleType);

                dispatch(insertOfferingData(datas))
                navigate(`/creator/offering`)
            }
        }
        else {
            if (datas?.service_type === '2') {
                let updatedData = { ...datas };
                updatedData.showImagePreview = datas?.cover_image;
                updatedData.tagName = datas?.tags;
                updatedData.productType = datas?.product_kind === '0' ? 'physical' : 'digital';
                updatedData.productImage = datas?.offerMaterials;
                updatedData.showImagePreview = datas?.cover_image;
                dispatch(insertProductData(updatedData));

                navigate(`/creator/offering/product/${id}`)
            }
            else {
                //     dispatch(clearOfferingData())
                // // nextStep(datas?.count)
                // datas.categoryId = datas?.category?.id
                // datas.sessionTypes = datas?.session_type === '1' ?'private_session' : datas?.session_type === '2' && 'group_session'
                // datas.tagData = datas?.tags;
                // datas.tagName = datas?.tags?.length>0 && datas?.tags?.map(tag => tag?.tag_name);
                // const tagss = datas?.tags?.length>0 && datas?.tags?.map(tag => tag?.id);
                // delete datas?.tags
                // datas.tags = tagss
                // datas.step = datas?.step +1;
                // datas.classMode = datas?.location_type === '1' && 'online' ||datas?.location_type === '2' && 'in_person' ||datas?.location_type === '3' && 'hybrid'
                // datas.invitationLink = datas?.invitation_link
                // datas.sessionType = datas?.session_offer_type === '1' && 'single_session' || datas?.session_offer_type === '2' && 'multiple_session'
                // datas.sessionMultipleType = datas?.package_type === '1' && 'weekly' || datas?.package_type === '2' && 'monthly' || datas?.package_type === '3' && 'custom' || datas?.package_type === '4' && 'daily'
                // // datas.recurrencess = datas?.session_occurrences && datas?.session_occurrences !== "undefined" && JSON.parse(datas?.session_occurrences.replace(/\\n/g, '\n'))
                // datas.recurrencess = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && JSON.parse(datas?.draft_session_occurrences.replace(/\\/g, ' '))

                // console.log("datas.recurrencessdatas.recurrencess",datas.recurrencess);

                // datas.discoveryPrice = datas?.pricing_type === '1' && 'fixed_price' || datas?.pricing_type === '2' && 'variable_price'
                // datas.discoveryMinPrice = datas?.price
                // datas.coverImage = datas?.cover_image
                // datas.maxGroupSize = datas?.max_group_size
                // datas.coverImage = datas?.cover_image
                // datas.discoveryMaxPrice = datas?.maximum_price
                // datas.timingZone = datas?.time_zone
                // const addImageArray = []
                // datas?.offerMaterials && datas?.offerMaterials?.length>0 && datas?.offerMaterials.map((x,index)=>{
                //     addImageArray.push(x?.file_url)
                // })
                // datas.additionalImages = addImageArray
                // // datas.classMode = datas?.location_type === '1' && 'online' || datas?.location_type === '2' && 'in_person' || datas?.location_type === '1' && 'hybrid'
                // // datas.sessionMultipleType =  datas?.package_type == '1' && 'weekly' || datas?.package_type == '2' && 'monthly' || datas?.package_type == '3' && 'custom' || datas?.package_type == '4' && 'daily'  
                // // datas.recurrences = JSON.parse(datas?.session_occurrences)
                // // datas.type = datas?.offer_type
                // console.log("datas.sessionMultipleTypedatas.sessionMultipleType",datas.sessionMultipleType);

                // dispatch(insertOfferingData(datas))
                navigate(`/creator/offering/${id}`)
            }
        }
    }
    }
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(1); // Reset page when search query changes
        setOfferingData([]); // Clear current offerings when search query changes
    };

    const editOffeing = (datas) =>{
        if(status === 3){
            singleService(datas?.id, datas)
        }
        if(status === 1){
        if (datas?.service_type !== '2') {

            
            dispatch(clearOfferingData())
            // nextStep(datas?.count)
            datas.categoryId = datas?.category?.id
            datas.sessionTypes = datas?.session_type === '1' ?'private_session' : datas?.session_type === '2' && 'group_session'
            datas.tagData = datas?.tags;
            datas.tagName = datas?.tags?.length>0 && datas?.tags?.map(tag => tag?.tag_name);
            const tagss = datas?.tags?.length>0 && datas?.tags?.map(tag => tag?.id);
            delete datas?.tags
            datas.tags = tagss
            datas.step = datas?.step +1;
            datas.classMode = datas?.location_type === '1' && 'online' ||datas?.location_type === '2' && 'in_person' ||datas?.location_type === '3' && 'hybrid'
            datas.invitationLink = datas?.invitation_link !==  "undefined" &&datas?.invitation_link
            datas.sessionType = datas?.session_offer_type === '1' && 'single_session' || datas?.session_offer_type === '2' && 'multiple_session'
            datas.sessionMultipleType = datas?.package_type === '1' && 'weekly' || datas?.package_type === '2' && 'monthly' || datas?.package_type === '3' && 'custom' || datas?.package_type === '4' && 'daily'
            // datas.recurrencess = datas?.session_occurrences && datas?.session_occurrences !== "undefined" && JSON.parse(datas?.session_occurrences.replace(/\\n/g, '\n'))
            datas.recurrencess = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && JSON.parse(datas?.draft_session_occurrences.replace(/\\(?!n)/g, ''))
            // datas.recurrencess = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && JSON.parse(datas?.draft_session_occurrences.replace(/\\/g, ' '))

            console.log("datas.recurrencessdatas.recurrencess",datas.recurrencess);
            
            // datas.discoveryPrice = datas?.pricing_type === '1' && 'fixed_price' || datas?.pricing_type === '2' && 'variable_price'
            // datas.discoveryMinPrice = datas?.price
            // datas.coverImage = datas?.cover_image
            // datas.maxGroupSize = datas?.max_group_size
            // datas.coverImage = datas?.cover_image
            // datas.discoveryMaxPrice = datas?.maximum_price
            // datas.timingZone = datas?.time_zone
           
            // datas.additionalImages = addImageArray
            datas.name = datas?.offer_name
            datas.selectedDayLabel = datas?.draft_times && datas?.draft_times !='undefined' && JSON.parse(datas?.draft_times)
            datas.addDateCalendar = datas?.draft_slot && datas?.draft_slot !='undefined' && JSON.parse(datas?.draft_slot)
            const weekday=[]
            datas?.draft_times && datas?.draft_times !='undefined' && datas?.selectedDayLabel.map((x,index)=>[
                weekday.push(x?.label)
            ])
            datas.dayLabel = weekday
            datas.sessionDuration = datas?.draft_session_occurrences && datas?.draft_session_occurrences !== "undefined"  && datas.recurrencess[0].sessionDuration
            datas?.step === 3 && delete datas?.classMode
            // datas.classMode = datas?.location_type === '1' && 'online' || datas?.location_type === '2' && 'in_person' || datas?.location_type === '1' && 'hybrid'
            // datas.sessionMultipleType =  datas?.package_type == '1' && 'weekly' || datas?.package_type == '2' && 'monthly' || datas?.package_type == '3' && 'custom' || datas?.package_type == '4' && 'daily'  
            // datas.recurrences = JSON.parse(datas?.session_occurrences)
            // datas.type = datas?.offer_type
            console.log("datas.sessionMultipleTypedatas.sessionMultipleType",datas.sessionMultipleType);

                console.log("datas.recurrencessdatas.recurrencess", datas.recurrencess);

                datas.discoveryPrice = datas?.pricing_type === '1' && 'fixed_price' || datas?.pricing_type === '2' && 'variable_price'
                datas.discoveryMinPrice = datas?.price || datas?.minimum_price
                // datas.coverImage = datas?.cover_image
                datas.maxGroupSize = datas?.max_group_size
                // datas.coverImage = datas?.cover_image
                datas.discoveryMaxPrice = datas?.maximum_price
                datas.timingZone = datas?.time_zone
                const addImageArray = []
                datas?.offerMaterials && datas?.offerMaterials?.length > 0 && datas?.offerMaterials.map((x, index) => {
                    addImageArray.push(x?.file_url)
                })
                // datas.additionalImages = addImageArray
                datas.name = datas?.offer_name
                datas.setDraftGroup = true
                // datas.classMode = datas?.location_type === '1' && 'online' || datas?.location_type === '2' && 'in_person' || datas?.location_type === '1' && 'hybrid'
                // datas.sessionMultipleType =  datas?.package_type == '1' && 'weekly' || datas?.package_type == '2' && 'monthly' || datas?.package_type == '3' && 'custom' || datas?.package_type == '4' && 'daily'  
                // datas.recurrences = JSON.parse(datas?.session_occurrences)
                // datas.type = datas?.offer_type
                console.log("datas.sessionMultipleTypedatas.sessionMultipleType", datas.sessionMultipleType);

                dispatch(insertOfferingData(datas))
                navigate(`/creator/edit-offering`)
            }
            if (datas?.service_type === '2') {
                dispatch(clearProductData());

                // Map relevant fields for product_kind scenario
                let updatedData = { ...datas };
                // Additional fields specific to product_kind
                updatedData.additionalProcessingTime = datas?.additional_processing_time;
                updatedData.showImagePreview = datas?.cover_image;
                updatedData.deliveryCharge = datas?.delivery_charge;
                updatedData.selectedOption = datas?.delivery_option === '1' ? 'Pick up' : datas?.delivery_option === '2' ? 'Delivery' : datas?.delivery_option === '3' ? 'Both' : null;
                updatedData.description = datas?.description;
                updatedData.digitalFile = datas?.digital_file;
                updatedData.digitalLink = datas?.digital_link;
                updatedData.discoverManPrice = datas?.maximum_price;
                updatedData.maximumQuantityOrder = datas?.maximum_quantity_order;
                updatedData.messageUser = datas?.message_to_user;
                updatedData.discoverMinPrice = datas?.minimum_price;
                updatedData.productStatus = datas?.product_status;
                updatedData.productType = datas?.product_kind === '0' ? 'physical' : 'digital';
                updatedData.address = datas?.product_address;
                updatedData.availability = datas?.product_availbility === '0' ? 'ready' : 'order';
                updatedData.productImage = datas?.product_image;
                updatedData.quantityOrders = datas?.quantity_items;
                updatedData.tagName = datas?.tags;
                updatedData.discoveryPrice = datas?.pricing_type === '1' ? 'fixed_price' : datas?.pricing_type === '2' ? 'variable_price' : 'no_price';
                updatedData.step = datas?.step;

                // Process tags
                updatedData.creatorTag = datas?.tags;
                // updatedData.tagName = datas?.tags?.length > 0 ? datas?.tags.map(tag => tag.tag_name) : [];
                updatedData.tags = datas?.tags?.length > 0 ? datas?.tags.map(tag => tag.id) : [];

                // Log to console
                console.log("updatedData with product_kind:", updatedData);

                // Dispatch updated data
                dispatch(insertProductData(updatedData));

                // Navigate to offering page
                navigate(`/creator/edit-product`);
            }
        }
    }

    return (
        <div>
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="creator-offering home min-height-pera creatoroffer-newcss">
                    <div className="RightSideDiv right-main">
                        <div className='header'>
                            <button className="burger-menu" onClick={handleSidebarClick}>
                                <img src={burger} alt='burger menu' />
                            </button>
                            <h2>My Offerings</h2>
                            <button className='new-offering' onClick={() => { dispatch(clearOfferingData()); navigate('/creator/offering') }}>
                                <img src={addtag} />
                                Create a new offering
                            </button>
                        </div>
                        <div className='top-buttons'>
                            <div className='input-container'>
                                <img src={search} alt="Add tag" />
                                <input
                                    type="text"
                                    placeholder='Find Offerings or Creators'
                                    value={searchQuery}
                                    onChange={handleSearchChange} // Update searchQuery on input change
                                />
                            </div>
                            <div class="creator-offering-tab"
                            >  <ul>
                                    <li><a className={status === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>
                                        Active
                                    </a> </li>
                                    <li><a className={status === 3 ? 'active' : ''} onClick={() => handleTabClick(3)}>
                                        Drafts
                                    </a> </li> <li><a className={status === 2 ? 'active' : ''} onClick={() => handleTabClick(2)}>
                                        Archive
                                    </a> </li>
                                </ul>
                            </div>

                        </div>
                        <div className=   {`${(offeringData.length > 0 && offeringData.length < totalCount) ? 'data-scroll' : 'data-scroll hide '}`}>
                            {offeringData.length === 0 ? (
                                <>
                                    <NoDataFound />
                                </>
                            ) : (
                                <div className="row">
                                    {offeringData.map((x, index) => (
                                        <div className="col-sm-4" key={index}>
                                            <div className="product-box">
                                                <div className="product-img" onClick={() => singleService(x?.id, x)} >
                                                    <img src={x?.cover_image} alt='' />
                                                    <div className="product-image-data">
                                                        {x?.service_type === "1" && (
                                                            <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                        )}
                                                        {/* {(x?.product_kind !== null && x?.product_kind !== '' && x?.product_kind !== undefined) || (x?.location_type !=='' && x?.location_type !==null && x?.location_type !==undefined) && */}
                                                        <h5>
                                                                            {x?.service_type === "2"
                                                                                ? (x?.product_kind === "0" ? 'Physical' : 'Digital')
                                                                                : (x?.service_type === "1"
                                                                                    ? (x?.location_type === "1" ? 'Online'
                                                                                        : (x?.location_type === "2" ? 'Offline'
                                                                                            : (x?.location_type === "3" ? 'Hybrid' : '')))
                                                                                    : 'Offline')}
                                                                        </h5>
{/* } */}
                                                        <button><img src={hearts} alt='' /></button>
                                                    </div>
                                                </div>
                                                <div className="product-data" onClick={() => singleService(x?.id, x)}>
                                                    <h3>{x?.name} </h3>
                                                    <ul className='product-data-tags'>
                                                        {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                                                            <li className={i === 0 ? "active" : ''} key={i}>
                                                                {y?.tag_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <p>{x?.description != 'undefined' && x?.description}</p>
                                                    <div className='row archived-data position-relative'>
                                                      <div className='col-md-8'>
                                                      <p className='py-1'>
    <span className='pe-1'><img src={doublebox} alt='box' /></span>
    {status === 1 ? 'Published' : status === 2 ? 'Archived' : status === 3 ? 'Draft' : ''}
</p>
                                                        </div>
                                                        
                                                        
                                                    </div>

                                                    <div className="product-data-bottom">
                                                        <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                        <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 text-end action-pointer-cs'>
                                                            <span className='action' onClick={() => toggleDropdown(x?.id)}><img src={threedots} alt='action' /></span>
                                                        </div>
                                                {activeDropdown === x?.id && (
                                    <div className='arcived-list' ref={dropdownRef}>
                                        <ul>
                                            {status === 1 && (
                                                <li onClick={() => offerStatusChange(x?.id, x)}>
                                                    <img src={doublebox} disabled={loader} alt='box' />Archive
                                                    {loader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                                                    
                                                </li>
                                            )}
                                            {status === 2 && (
                                                <li onClick={() => offerStatusChange(x?.id, x)}>
                                                    <img src={resotre} alt='box' />
                                                    Restore
                                                </li>
                                            )}
                                            {status === 3 && (
                                                <li onClick={() => singleService(x?.id, x)}>
                                                    <img src={publish} alt='box' />
                                                    Publish
                                                </li>
                                            )}
                                            <li onClick={()=>editOffeing(x)}>
                                                <img src={edit} alt='box' />
                                                Edit
                                            </li>
                                            <li onClick={() => delteOfferModal(x?.id, x)} disabled={deleteloader}>
                                            {/* <li onClick={() => deleteOffer(x?.id, x)} disabled={deleteloader}> */}
                                                <img src={deleteoffer} alt='box' />
                                                Delete
                                                {deleteloader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {offeringData.length > 0 && offeringData.length < totalCount && (
                            <button className="explore" onClick={loadMoreOfferings} disabled={btnloader}>
                             {btnloader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}
                                Load more Offerings
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <Modal
        isOpen={delteOffering}
        onRequestClose={() => setDeleteOffering(false)}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        overlayClassName={`modal-overlay  verification-modal offiering-modals-change  delete-offer-css`} >
        <button className='close-button ' onClick={() => setDeleteOffering(false)}><img src={cross} alt='' /></button>
        <DeleteOffering deleteOffer={deleteOffer} delteOfferingId={delteOfferingId} delteOfferingData={delteOfferingData} setDeleteOffering={setDeleteOffering}/>
      </Modal>
        </div>
    );
}
export default CreatorOfferingHome;
