import React, { useEffect, useState, useCallback } from 'react';
import cross from '../../../assets/images/cross-button.svg';
import creditCard from '../../../assets/images/credit-cards.svg';
import paypal from '../../../assets/images/PayPal.svg';
import stripe from '../../../assets/images/Stripe.svg';
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import checkCircle from '../../../assets/images/check-mark.svg';
import message2 from '../../../assets/images/msg-blank.svg';
import check from '../../../assets/images/success.svg';
import remove from '../../../assets/images/close-circle.svg';
import pin from '../../../assets/images/pin-download.svg';
import { useNavigate } from 'react-router-dom';


function CheckoutSuccess({ setFiltersModal, type ,itemDetails}) {
  const navigate = useNavigate()

    const userDetails = JSON.parse(localStorage.getItem('full_user_detail'));
    const userDetailRole = JSON.parse(localStorage.getItem('userDetail'));
    const getDeliveryDateDay = (offsetDays) => {
        // Get the current date and add offset days
        const currentDate = new Date();
        const deliveryDate = new Date(currentDate);
        deliveryDate.setDate(deliveryDate.getDate() + offsetDays);
    
        // Format the date as "dd/mm/yyyy"
        const day = String(deliveryDate.getDate()).padStart(2, '0');
        const month = String(deliveryDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = deliveryDate.getFullYear();
    
        // Get the day of the week as a short form (e.g., "FR" for Friday)
        const dayShortForm = deliveryDate.toLocaleDateString('en-GB', { weekday: 'short' }).toUpperCase();
    
        // Combine all parts into the final format "dd/mm/yyyy (DAY)"
        return `${day}/${month}/${year} (${dayShortForm})`;
      };
    const getDeliveryDate = (offsetDays) => {
        // Get the current date
        const currentDate = new Date();
        
        // Create a copy of the current date and add offset days
        const deliveryDate = new Date(currentDate);
        deliveryDate.setDate(deliveryDate.getDate() + offsetDays);
    
        // Format the date to "day month year"
        const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
        return deliveryDate.toLocaleDateString('en-GB', dateOptions);
      };
    
      const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${((hours + 11) % 12 + 1)}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        
        return formattedTime;
      };
    console.log('this is in checkout successs',itemDetails);
    console.log('this is userDetails',userDetails);

    const handleClick = () =>{
        localStorage.setItem('creatorDetail',JSON.stringify(itemDetails))
        // localStorage.setItem('userDetail',JSON.stringify(userDetails))
        const isCreator = userDetailRole.roles.some(role => role.user_role === "creator");
        if (isCreator) {
            navigate('/creator/messages');
          } else {
            navigate('/user/messages');
          }
    }
    return (
        <>
        {/* <div className='modal-header'> */}
            {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setSuccessModal(false)}>
                <span><img src={cross} /> </span>
            </button> */}
        {/* </div> */}
        <div>
                <div className="payment-modal">
                    {/* <button className="close-modal" onClick={() => setSuccessModal(false)}>
    <img src={cross} alt="close" />
  </button> */}
                </div>
            </div><div class="modal-dialog">
                <div class="modal-content">
                    {/* <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel"></h5>

                    </div> */}
                    <div class="modal-body">
                        <div class="thanks">
                            <div class="top">
                                <span><img src={checkCircle} /> </span>
                                <div className='text-start'>
                                <h1 className='mb-1'>Thanks for Your Payment</h1>
                                <p>Your reservation was completed successfully</p>
                                </div>
                            </div>
                            {!type && (
                                <div class="mid">
                                    <label>Reserved Time: <span>{getCurrentTime()}</span></label>
                                    <label>Reserved Date: <span>{getDeliveryDateDay(0)}</span></label>
                                    <p>We have sent detailed information about the reservation to</p>
                                    <a>{userDetails?.email}</a>
                                </div>

                            )}

                            {(type === 'digital' || type === 'physical') && (
                                <div class="mid">
                                    <label className='pb-1'>Payment Time: <span>{getCurrentTime()}</span></label>
                                    <label className='pt-1'>Payment Date: <span>{getDeliveryDateDay(0)}</span></label>
                                    <p>We have sent detailed information about the reservation to</p>
                                    <a>{userDetails?.email}</a>
                                </div>
                            )}
{/* {itemDetails?.session_type==='2' &&
<div class="mid">
                                    <label>Session Details <span>{getCurrentTime()}</span></label>
                                    <label>End Date: <span>{getDeliveryDateDay(0)}</span></label>
                                    {itemDetails?.address!== null &&<label>Location: <span>{itemDetails?.address?.city + ', ' + itemDetails?.address?.country}</span></label>}
                                </div>
} */}

                            <div class="bottom mb-0">
                                {!type && (
                                    <>
                               
                                        <h1>Connect with creator</h1>
                                        <p> You can now connect with the creator to discuss details, ask clarifying questions</p>
                                     
                                        <button class="button pe-5" onClick={() => handleClick()}><img className='pe-3' src={message2} />Message creator </button>
                                    </>
                                )}

                                { type === 'physical' && (
                                    <>
                                         <div className='payment-bottom-set'>
                                        <h1>Product details</h1>
                                        {((itemDetails?.delivery_option === '2' || itemDetails?.delivery_option === '3' ) && (itemDetails?.delivery_time !== null)) &&
                                        
                                        <label className='mb-1 w-100'>Approximate Delivery date: <span>{getDeliveryDate(3)}</span></label>
                                        }
                                        <label className='mb-1 w-100'> Delivery address: <span>{itemDetails?.address?.address ===''? itemDetails?.address?.city + ',' + itemDetails?.address?.state : itemDetails?.address?.address}</span></label>
                                        <p> You can now connect with the creator to discuss details, ask clarifying questions</p>
                                        </div>
                                        <button class="button pe-5 msg-creator" onClick={() => handleClick()}><img className='pe-3' src={message2} />Message creator </button>
                                    </>
                                )}

                                {type === 'digital' && (
                                    <>
                                        <button class="button pe-5"><img className='pe-3' src={pin} />Download product </button>
                                        <button class="button pe-5 msg-creator" onClick={() => handleClick()}><img className='pe-3' src={message2} />Message creator </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    );
}

export default CheckoutSuccess;