import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg'
import leftArrow from '../../../assets/images/backarrow.svg'
import { useNavigate } from 'react-router-dom';
import cardJewellery from '../../../assets/images/card jwellery.png'
import location from '../../../assets/images/location1.svg'
import doubleBox from '../../../assets/images/double-box.svg'
import whiteArrow from '../../../assets/images/white-arrow.svg'
import setting from '../../../assets/images/setting-screw.svg'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../../assets/images/cross-button.svg'
import dummyImage from '../../../assets/images/profile3.svg'
import { useParams } from 'react-router-dom'
import { getApi } from '../../../Services/apiService'

import share from '../../../assets/images/share1.svg'
import heart from '../../../assets/images/heart.svg'

function AdminOrderDetails({ }) {

  const [classState, setClassState] = useState(false);
  const [data, setUserData] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const creatorDetail = JSON.parse(localStorage.getItem('userDetail'));
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const params = useParams();
  console.log("params>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", params?.id)
  console.log("formStateformStateformStateformStateformStateformState", data);

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  useEffect(() => {
    fetchData();
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const response = await getApi(`/admin/order?orderId=${params?.id}`);
      if (response?.statusCode === 200) {
        setUserData(response?.data?.orders);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  // Ensure description is defined and is a string
  const description = data?.offerService?.description || '';
  const isLongDescription = description?.length > 300;
  const truncatedDescription = description?.length > 300 ? `${description.slice(0, 300)}...` : description;
  const navBack = () => {
    navigate('/adim/orders')
  }
  return (
    <>
      <UserSidebar classState={classState} />
      <div className='right-main order-details-admin'>
        {/*  */}
        <div className='session  creators-top p-0'>
          <div class="members-top">
            <h2>Order Details</h2>
            <div className='members-top-right'>
              <button class="btn btn-share"><img src={share} />Share</button> <img src={heart} />
            </div>
          </div>

          <div class="member-filter">
            <h3 onClick={() => { navBack() }} className='text-start mb-0'><img src={leftArrow} /> {data?.name}Necklace</h3>
            {/* <h2> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Order Details</h2> */}
            <ul className='processing-list ps-0 text-end mb-0'>
              <li><button className='btn order-pro'><img src={setting} alt="order-img" className='pe-2' />Order processing</button> </li>
            </ul>
          </div>
          <div className='row order-payment-status align-items-center'>
            <div className='col-sm-6'>
              <div class="choose-tags m-0">
                <ul >
                  <li class="custom-checkbox mb-0">
                    <input disabled="" type="checkbox" id="option0" />
                    <label for="option0">Accessorise</label></li>
                  <li class="custom-checkbox mb-0">
                    <input disabled="" type="checkbox" id="option1" />
                    <label for="option1">Jewellery</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-sm-6'>
              <ul className='processing-list ps-0 text-end mb-0'>
                <li>Payment Status: <button className='btn order-pro'>Completed</button> </li>
              </ul>
            </div>
          </div>

          {/* ------------------------- */}
          <div className='order-address'>
          <div className='row '>
            <div className='col-sm-6'>
              <h5 class="card-title">About Order</h5>
              <ul>
                <li><strong>Order id:</strong> #123123</li>
                <li><strong>Date & Time:</strong> xx/xx/xxxx 12:00 PM</li>
                <li><strong>Amount:</strong> $25</li>
                <li><strong>Transaction ID:</strong> #123456</li>
                <li><strong>Payment Method:</strong> Credit car</li>
              </ul>
            </div>
            <div className='col-sm-6'>
            <h5 class="card-title">Shipping Information</h5>
              <ul>
                <li><strong>Recipient Name:</strong> John Doe</li>
                <li><strong>Address:</strong> 123 Main Street</li>
                <li><strong>Phone Number:</strong> +1 (555) 123-4567</li>
                <li><strong>Email:</strong> jennywilson@gmail.com</li>
                <li><strong>Delivery Method:</strong> Standard Shipping</li>
              </ul>
            </div>
          </div>
          </div>
          {/* ------------------------ */}


        </div>
        {/* ----- */}
        <div class="session after-upload-produt">
          {/* <div class="active-links mb-2">
           <h2 onClick={() => { navBack() }} className='text-start'><img src={leftArrow} /> {data?.name}</h2>
            <h2> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Order Details</h2>
          </div> */}
          <div class="main mt-0">
            <div class="row">
              <div class="col-sm-12 col-xl-7 col-lg-12">
                {data && data.tagName && data.tagName?.length > 0 && (
                  <div className="choose-tags mb-2">
                    <ul>
                      {data.tagName.map((tag, index) => (
                        <li key={tag.id} className="custom-checkbox">
                          <input disabled
                            type="checkbox"
                            id={`option${index}`}
                          />
                          <label htmlFor={`option${index}`}>{tag.tag_name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div class="col-sm-9">
                  <div className='creator-detail-right creator-product'>
                    <h2 className='fw-bold'> About product</h2>
                    <p>
                      {isExpanded || !isLongDescription ? description : truncatedDescription}
                      <br />
                      {isLongDescription && (
                        <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                          {isExpanded ? 'Read less' : 'Read more'}
                        </strong>
                      )}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-10 col-sm-10">
                    <div class="main-image">
                      <img src={data?.offerService?.cover_image || cardJewellery} alt='' class="img-fluid " />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-2">
                    <div className={data?.offerService?.offerMaterials?.length < 3 ? 'jewellery-img-height' : 'jewellery-img-height image'} >
                      <div className="row jw-cs">
                        {data?.offerService?.offerMaterials && data.offerService?.offerMaterials?.length > 0 ? (
                          data.offerService?.offerMaterials
                            .filter((item) => item.media_type === "image" || item.media_type === "video") // Filter for images and videos
                            .map((item, index) => (
                              <div className="col-6 col-lg-12 col-sm-12" key={index}>
                                <div className="jewellery-img">
                                  <img src={item.file_url} alt={`additional-image-${index}`} className="img-fluid" />
                                </div>
                              </div>
                            ))
                        ) : (
                          <>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-7 col-xl-7 col-lg-7 creator-order'>
                <h2>Creator</h2>
                <div className='row align-item-center res-creator-cs '>
                  <div className='col-sm-5 col-xl-2 col-lg-5'>
                    <div className='creator-detail-img'>
                      <span>
                        <img src={data?.offerService?.user?.user_profile_image || dummyImage} alt='' />
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-7 col-xl-10 col-lg-7'>
                    <div className='creator-detail-right create-dt-cs'>
                      <h2 className='fw-bold'>{data?.offerService?.user?.creator_name || ''}</h2>
                      <h4>{data?.offerService?.user?.specializations[0]?.area_of_work || ''}</h4>
                      <p className='d-block'>{data?.offerService?.user?.specializations[0]?.experience || ''} of experience</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-7 col-xl-7 col-lg-7 user-order'>
                <h2>User</h2>
                <div className='row align-item-center res-creator-cs '>
                  <div className='col-sm-5 col-xl-2 col-lg-5'>
                    <div className='creator-detail-img'>
                      <span>
                        <img src={data?.user?.user_profile_image || dummyImage} alt='' />
                      </span>
                    </div>
                  </div>
                  <div className='col-sm-7 col-xl-10 col-lg-7'>
                    <div className='creator-detail-right create-dt-cs'>
                      <h2 className='fw-bold'>{data?.user?.user_name || ''}</h2>
                      <h4>User</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminOrderDetails;
