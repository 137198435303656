import React from "react";
import smileyface from '../../../assets/images/simleyface.svg'

const DeclineModals = ({setDeclineMessage,declineMessage,data,UpdateOrderDetail}) => {

    return (
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="share-main">
                            <div className='header'>

                                <span><img src={smileyface} alt=''></img> </span>
                                <h5>The order has been declined.</h5>
                            </div>

                            <div className='availability-top avalibilty-check avalibilty-check-radius'>
                                <ul>
                                    <li className='active'>
                                        <label className="aval-check"><input type="checkbox" checked={declineMessage === 'Unable to meet the timeline.'} onClick={()=>setDeclineMessage('Unable to meet the timeline.')}/><span className="checkmark"></span></label>
                                        <span>Unable to meet the timeline.</span>
                                    </li>
                                    <li className='active'>
                                        <label className="aval-check"><input type="checkbox" checked={declineMessage === 'Lack of necessary resources or tools.'} onClick={()=>setDeclineMessage('Lack of necessary resources or tools.')}/><span className="checkmark"></span></label>
                                        <span>Lack of necessary resources or tools.</span>
                                    </li>
                                    <li className='active'>
                                        <label className="aval-check"><input type="checkbox" checked={declineMessage === 'The project is outside my expertise.'} onClick={()=>setDeclineMessage('The project is outside my expertise.')}/><span className="checkmark"></span></label>
                                        <span>The project is outside my expertise.</span>
                                    </li>
                                    <li className='active'>
                                        <label className="aval-check"><input type="checkbox" checked={declineMessage === 'Personal reasons or unforeseen circumstances.'} onClick={()=>setDeclineMessage('Personal reasons or unforeseen circumstances.')}/><span className="checkmark"></span></label>
                                        <span>Personal reasons or unforeseen circumstances.</span>
                                    </li>


                                </ul>
                            </div>
                            <div className="w-100">
                                <div className="form-group">
                                    <label>Other please specify</label>
                                    <input className="form-control" type="text-area" placeholder="Please provide a reason for declining the order" onChange={(e)=>setDeclineMessage(e.target.value.trim() !== '' ? e.target.value : '')} />
                                    </div>
                            </div>
                            <div className="">
                                <button className="btn btn-sent" onClick={()=>UpdateOrderDetail(data?.id,'4',declineMessage)}>Send</button>
                            </div>
                            </div>
                   </div>
                   </div>
                   </div>
    )  
}  
  
export default DeclineModals