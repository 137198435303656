import React, { useEffect, useState, useCallback } from 'react';
import exportSvg from '../../../assets/images/export.svg';
import add from '../../../assets/images/add.svg';
import search from '../../../assets/images/search.svg';
import filterList from '../../../assets/images/filter-list.svg';
import view from '../../../assets/images/view.svg';
import del from '../../../assets/images/del.svg';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import { deleteApi, getApi } from '../../../Services/apiService';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg';
import { toast } from 'react-toastify';
import AdminMultiStep from '../../AdminComponents/adminMultistep'
import OrderFilter from '../../AdminComponents/Orders/orderFilter';
import PushNotification from '../../AuthComponent/notification';
                         
function AdminBooking() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [classState, setClassState] = useState(false);
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [filtersModal, setFiltersModal] = useState(false);
  const [loader, setLoader] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({
    user: false,
    creator: false,
    coCreator: false
  });

  const fetchData = useCallback(async (query = '', filters = {}) => {
    try {
      const filterQuery = Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key]) => key)
        .join(',');
      const response = await getApi(`/admin/booking?search=${query}&user_role=${filterQuery}`);
      if (response?.statusCode === 200) {
        setUserData(response?.data);
      } else {
        
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchData(searchQuery, selectedFilters); // Initial fetch
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [fetchData, searchQuery, selectedFilters]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async () => {
    setLoader(true)
    try {
      const response = await deleteApi(`/admin/user?userId=${userId}`, { userId });
      if (response?.statusCode === 200) {
        setLoader(false)
        toast.success(response?.message);
        setDeleteModal(false);
        fetchData(searchQuery, selectedFilters); // Refresh data after delete
      } else {
        localStorage.clear();
        navigate('/');
      }
    } catch (error) {
      setLoader(false)
      console.error('Error deleting user:', error);
    }
  };

  const handleModalClose = () => {
    fetchData(searchQuery, selectedFilters); // Refresh data when modal closes
  };

  const openModal = (id) => {
    setUserId(id);
    setDeleteModal(true);
  };

  const closeModal = (type) => {
    setUserId('');
    setDeleteModal(false);
    setShowModal(false)
  };

  const openFiltersModal = () => {
    setFiltersModal(true);
  };
  const closeNotificationModal = () => {
    setNotificationModalOpen(false);
  };
  const openNotificationModal = () => {
    setNotificationModalOpen(true);
  };

  const closeFiltersModal = () => {
    setFiltersModal(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedFilters(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleShowChanges = () => {
    fetchData(searchQuery, selectedFilters); // Fetch data with selected filters
    closeFiltersModal();
  };

  return (
    <div className="users meal-suggestion admin">
      <div className="HomeMainDiv">
        <UserSidebar classState={classState} />
        <div className="right-main request-member booking">
          <div className="members-top">
            <h2>
              <button className="burger-menu" onClick={handleSidebarClick}>
                <img src={burger} alt='burger menu' />
              </button>
              Bookings
            </h2>
            <div className="members-top-right">
              <button onClick={() => openNotificationModal()}><img src={exportSvg} alt="export" />Export</button>
            </div>
          </div>

          <div className="member-filter">
            <div className="filter-search filter-respon">
              <button><img src={search} alt="search" /></button>
              <input
                className="search"
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <button className="filter-btn" onClick={openFiltersModal}>
              <img src={filterList} alt="filters" />Filters
            </button>
            {/* <button className="filter-btn">
              <img src={filterList} alt="filters" />Filters
            </button> */}
          </div>
          <div className="overflow-scrolls">
          <div className="table-header">
            <ul>
              <li className="id">Booking Id</li>
              <li className="name">User Name</li>
              <li className="booking">Booking</li>
              <li className="creator">Creator Name</li>
              <li className="date">Date & Time</li>
              <li className="amount">Amount</li>
              <li className="Action">Action</li>
            </ul>
          </div>

        
            {userData && userData.length > 0 && userData.map((x, index) => (
              <ul className="table-row table-respon" key={index}>
                <li className="id">#{index + 1}</li>
                <li className="name">
                  
                    {x?.user?.name || '-'}
                  
                </li>
                <li className="booking">{x?.offerService?.offer_name || '-'}</li>
                <li className="creator">{x?.offerService?.user?.name || '-'}</li>
                <li className="date">{formatDate(x?.createdAt  || '-') }</li>
                <li className="amount">${x?.price || '-'}</li>
                <li className="Action">
                  <button><img src={view} alt="view" /></button>
                  {/* <button onClick={() => openModal(x?.id)}><img src={del} alt="delete" /></button> */}
                </li>
              </ul>
            ))}
          </div>

          <Modal
            isOpen={showModal}
            onRequestClose={() => closeModal('admin')}
            contentLabel="Login Modal"
            shouldCloseOnOverlayClick={false}
          >
            <button className='close-button' onClick={() => closeModal('admin')}><img src={cross} alt='' /></button>
            <AdminMultiStep userModalIsOpen={showModal} setUserModalIsOpen={setShowModal} onClose={handleModalClose} />
          </Modal>

          <Modal
            isOpen={deleteModal}
            onRequestClose={closeModal}
            contentLabel="Delete Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            overlayClassName={`modal-overlay add-category delete`}
          >
            <h2>Confirm Delete</h2>
            <p>Deleting this user will permanently remove their account and all associated data. This action can't be undone.</p>
            <div className="modal-buttons">
              <button className="cancel-btn" onClick={closeModal}>Cancel</button>
              <button className="save-btn" onClick={handleDelete} disabled={loader}>
                {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Yes, I'm sure</button>
            </div>
          </Modal>

          <Modal
            isOpen={filtersModal}
            onRequestClose={closeFiltersModal}
            contentLabel="Filters Modal"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            overlayClassName={`modal-overlay member-sidebar booking-order-filter`}
          >
            <button className='close-button' onClick={closeFiltersModal}>
              <img src={cross} alt='close' />
            </button>
            <OrderFilter type={'Booking'}></OrderFilter>
            
          </Modal>
        </div>
      </div>
        <Modal
                    isOpen={notificationModalOpen}
                    onRequestClose={closeNotificationModal}
                    contentLabel="Media-Modal"
                    portalClassName="notify-section"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeNotificationModal()}></button>
                    <PushNotification></PushNotification>
                </Modal>
    </div>
    
  );
}

export default AdminBooking;
