import { collection, addDoc, doc, setDoc, getDocs, updateDoc, deleteDoc, getDoc, where, query, orderBy, limit, serverTimestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import db from './firebase';

const dbPath = '/Chats';

const FirebaseChatService = {
  getAll: async () => {
    try {
      const chatCollectionRef = collection(db, dbPath);
      const snapshot = await getDocs(chatCollectionRef);
      const chatData = [];

      snapshot.forEach((doc) => {
        chatData.push({ id: doc.id, ...doc.data() });
      });

      return { success: true, data: chatData };
    } catch (error) {
      console.error('Error getting all chat data:', error);
      return { success: false, error: error.message };
    }
  },


  createChatRoom: async (userUid, selectedUserId) => {
    try {
      const chatId = `${userUid}_${selectedUserId}`;
      const chatData = {
        participants: [userUid, selectedUserId],
        // Add other chat room data as needed
      };
      
      const roomRef = doc(db, dbPath, chatId);
      await setDoc(roomRef, chatData);
      return { success: true, roomId: chatId };
    } catch (error) {
      console.error('Error creating chat room:', error);
      return { success: false, error: error.message };
    }
  },

  create: async (roomData, roomId) => {
    try {
      const roomRef = doc(db, dbPath, roomId);
      await setDoc(roomRef, roomData);
      return { success: true, roomId };
    } catch (error) {
      console.error('Error creating chat room:', error);
      return { success: false, error: error.message };
    }
  },

  update: async (roomData, roomId) => {
    try {
      const roomRef = doc(db, dbPath, roomId);
      await updateDoc(roomRef, roomData);
      return { success: true, roomId };
    } catch (error) {
      console.error('Error updating chat data:', error);
      return { success: false, error: error.message };
    }
  },

  delete: async (roomId) => {
    try {

      if (!db) {
        throw new Error('Firestore instance is missing.');
      }
      
      if (!dbPath) {
        throw new Error('dbPath is missing.');
      }
  
      if (!roomId) {
        throw new Error('Room ID is required for deletion.');
      }
  
      const roomRef = doc(db, dbPath, roomId);
      const roomSnap = await getDoc(roomRef);
      if (!roomSnap.exists()) {
        throw new Error('Chat room does not exist.');
      }
      // const messageRef = doc(db, 'chatRooms', roomId, 'messages', messageId);\
      // await deleteDoc(messageRef);
      const messagesQuerySnapshot = await getDocs(collection(db, dbPath, roomId, 'messages'));

messagesQuerySnapshot.forEach(async (messageDoc) => {
  await deleteDoc(messageDoc.ref);
});
      // await deleteDoc(roomRef);
      await deleteDoc(doc(db, dbPath, roomId));
      
      return { success: true };
    } catch (error) {
      console.error('Error deleting chat room:', error);
      return { success: false, error: error.message };
    }
  },

  checkRoomExist: async (roomId) => {
    try {
      const roomRef = doc(db, dbPath, roomId);
      const docSnapshot = await getDoc(roomRef);
      return { success: true, exists: docSnapshot.exists() };
    } catch (error) {
      console.error('Error checking chat room existence:', error);
      return { success: false, error: error.message };
    }
  },

  getMessage: async (roomId) => {
    try {
      const messagesRef = collection(db, dbPath, roomId, 'messages');
      const q = query(messagesRef, orderBy('createdAt'), limit(25));
      const snapshot = await getDocs(q);
      const messageData = [];

      snapshot.forEach((doc) => {
        messageData.push({ id: doc.id, ...doc.data() });
      });

      return { success: true, data: messageData };
    } catch (error) {
      console.error('Error getting messages for chat room:', error);
      return { success: false, error: error.message };
    }
  },

  sendMessage: async (roomId, messageObj) => {
    try {

     
      const messagesRef = collection(db, dbPath, roomId, 'messages');
      await addDoc(messagesRef, { ...messageObj, createdAt: serverTimestamp() });
      return { success: true };
    } catch (error) {
      console.error('Error sending message:', error);
      return { success: false, error: error.message };
    }
  },

  addField: async (roomId, messageObj, id) => {
    try {
      const messagesRef = doc(db, dbPath, roomId, 'messages', id);
      await setDoc(messagesRef, messageObj);
      return { success: true };
    } catch (error) {
      console.error('Error adding a field:', error);
      return { success: false, error: error.message };
    }
  },

   blockUser: async(currentUserId, userToBlockId) =>{
    const userRef = doc(db, 'users', currentUserId);
    
    try {
      await updateDoc(userRef, {
        blockedUsers: arrayUnion(userToBlockId)  // Add user to blocked list
      });
      console.log(`User ${userToBlockId} has been blocked.`);
    } catch (error) {
      console.error('Error blocking user:', error);
    }
  },

  unblockUser: async(currentUserId, userToUnblockId)=> {
    const userRef = doc(db, 'users', currentUserId);
    
    try {
      await updateDoc(userRef, {
        blockedUsers: arrayRemove(userToUnblockId)  // Remove user from blocked list
      });
      console.log(`User ${userToUnblockId} has been unblocked.`);
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  },

  getFirebaseTimestamp: () => {
    return serverTimestamp();
  },
};

export default FirebaseChatService;
