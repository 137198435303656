import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import googleLogo from '../../assets/images/google-logo.svg'
import { postApi } from '../../Services/apiService';
import { useSelector } from 'react-redux';
import BookInterview from '../CreatorComponents/CreatorJoin/bookInterview';
import Modal from 'react-modal';
import SubmitForm from '../CreatorComponents/CreatorJoin/submitForm';
import { requestNotificationPermission } from '../FirebaseConfig/NotificationComponent'; // Import the function

const GoogleSignIn = ({role,setUserModalIsOpen,userType}) => {
    const navigate = useNavigate();
    const [bookingInterview,setBookingInterviw] = useState(false)
    const [dashboardModal,setDashboardModal] = useState(false)
    const creatorState = useSelector((state) => state.counter);
    const userState = useSelector((state) => state.userCounter);
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const authorizationCode = tokenResponse.code;
            try {
                const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        code: authorizationCode,
                        client_id: '319571023204-dr9kca64rv9addd686gpghfip8754o3a.apps.googleusercontent.com',
                        client_secret: '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',
                        // redirect_uri: 'http://localhost:3000',
                        redirect_uri: 'https://microcosmstage.enactstage.com',
                        grant_type: 'authorization_code'
                    })
                });

                if (!tokenResponse.ok) {
                    const errorText = await tokenResponse.text();
                    console.error('Error response from token endpoint:', errorText);
                    throw new Error(`Failed to exchange code for token: ${errorText}`);
                }

                const tokenData = await tokenResponse.json();

                const { access_token, id_token } = tokenData;
                // const backendResponse = await fetch('http://localhost:3001/api/v1/google-signin', {
                const backendResponse = await fetch('https://microcosmstage.enactstage.com/microcosmapi/api/v1/google-signin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ access_token: access_token, id_token: id_token })
                });
                if (!backendResponse.ok) {
                    throw new Error('Failed to send token to backend');
                }

                const data = await backendResponse.json();
                
                if(role ==='creatoruser'){
                    const fcmToken = await requestNotificationPermission();
                   
                    
                    const insertData = {
                        // email: data?.user_details?.email,
                        // account_type : 'google',
                        // password:'',
                        name : data?.user_details?.name,
                        email: data?.user_details?.email,
                        user_role: userType,
                        account_type : 'google',
                        access_token: tokenData?.access_token,
                        id_token: tokenData?.id_token,
                        fcmtoken:fcmToken === 'not_granted' ? '' : fcmToken,

                    }
                    const userData =  await postApi('/register', insertData);
                if (userData?.statusCode === 200 || userData?.message === "Email Already Exists") {
                    localStorage.setItem("userDetail",JSON.stringify(userData?.user_details))
                    localStorage.setItem("token",JSON.stringify(userData?.user_details?.token))
                    const isCreator = userData?.user_details?.roles.some(role => role.user_role === "creator");
                    if (isCreator) {
                        if(userData?.user_details?.interview === 0){
                        // if(userData?.alreadyExist === 0){

                            setBookingInterviw(true)
                        }
                        else{

                              navigate('/creator/home');
                        }
                    }  else{
                        navigate('/user/home');
                    }
                    // if (role === "user") {
                    //   navigate('user/home');
                    // }
                    setUserModalIsOpen(false);
                  } 
                }
                else{
                    const fcmToken = await requestNotificationPermission();
                   
                    const insertData = {
                        name : data?.user_details?.name,
                        email: data?.user_details?.email,
                        user_role: role,
                        account_type : userState?.accountType || creatorState?.account_type,
                        access_token: tokenData?.access_token,
                        id_token: tokenData?.id_token,
                        fcmtoken:fcmToken === 'not_granted' ? '' : fcmToken,
    
                    }
                const userData =  await postApi('/register', insertData);
                if (userData?.statusCode === 200 || userData?.message === "Email Already Exists") {
                    localStorage.setItem("userDetail",JSON.stringify(userData?.user_details))
                    localStorage.setItem("token",JSON.stringify(userData?.user_details?.token))
                    const isCreator = userData?.user_details?.roles.some(role => role.user_role === "creator");
                    // if (userData?.user_details?.roles[0]?.user_role === "creator" ) {
                    if (isCreator) {
                        if(userData?.user_details?.interview === 0){
                        // if(userData?.alreadyExist === 0){

                            setBookingInterviw(true)
                        }
                        else{

                              navigate('/creator/home');
                        }
                    } 
                    // if (userData?.user_details?.roles[0]?.user_role === "user") {
                    else {
                      navigate('/user/home');
                    }
                    setUserModalIsOpen(false);
                  } else {
                    // alert('Something went wrong');
                  }
                }
            } catch (error) {
                console.error('Error during login process:', error);
            }
        },
        onError: (error) => {
            console.log('Login Failed:', error);
        },
        flow: 'auth-code',
        scope: 'profile email',
        responseType: 'code',
    });

    return (
        <div>
            {/* <button onClick={() => login()}>
                Sign Up with Google
            </button> */}
            {/* <Link to="/creator/detail"> */}
                <li onClick={() => login()}><img src={googleLogo} alt='google'/>Sign in with Google </li>
            {/* </Link> */}
            <Modal
        isOpen={bookingInterview}
        onRequestClose={() => setBookingInterviw(false)}
        contentLabel="Login Modal"
        portalClassName="Login-modal-tag  lgoin-details-css google-login-cs"
        // shouldCloseOnOverlayClick={false}
      >
  <BookInterview nextStep={''} prevStep={''} data={''} submitForm={''} loader={''} loginStatus={'googleLogin'} setDashboardModal={setDashboardModal} setBookingInterviw={setBookingInterviw}/> {/* Pass selectedDays as data */}
      </Modal>
            <Modal
        isOpen={dashboardModal}
        onRequestClose={() => setDashboardModal(false)}
        contentLabel="Login Modal"
        portalClassName="google-login-modal lgoin-details-css"
        shouldCloseOnOverlayClick={false}
      >
  <SubmitForm prevStep={''} submitForm={''} loader={''} loginStatus={'googleLogin'}/> {/* Pass selectedDays as data */}
      </Modal>

        </div>
    );
};

export default GoogleSignIn;
