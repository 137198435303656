import React from 'react'
import checkCircle from '../../../assets/images/check-circle-2.svg'
import { Link, useNavigate } from 'react-router-dom'

function ResetModal({prevStep,submitForm,loader,loginStatus}) {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const onSubmit=async(values)=>{
  //   dispatch(insertData('end'));
  //   nextStep('end')
  // }
  return (
    <div className="detail-page congratulations">
        <div className="detail-main">
            <div className="congratulations">
                <span> <img src={checkCircle}/> </span>
                <h1>Successful password reset!</h1>
                <p>You can use your new password to login into your account!!</p>
                  <button className="button" onClick={()=>navigate('/')}>Log In</button>
                 
            </div>

        </div>
    </div>
  )
}

export default ResetModal