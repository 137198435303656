import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getApi, postApi } from '../../../Services/apiService';
import check from '../../../assets/images/success.svg';
import remove from '../../../assets/images/close-circle.svg';
import uncheckCircle from '../../../assets/images/uncheck.svg';

function MonthlyBookingModal({ data, closeModal, setFiltersModal, setDataToPaymentModal }) {

    console.log('custom modal data --------->>>>>>', data);
    const initialValues = {
        selectedDate: data?.selectedDate || '',
        selectedTimes: data?.selectedTimes || '',
        price: ''
    };
    const [value, onChange] = useState(new Date());
    const [slotDisplay, setSlotDisplay] = useState([]);
    const [markedDates, setMarkedDates] = useState([]);
    const [activeStartDate, setActiveStartDate] = useState(new Date()); // State for active start date
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [showTimeSlotError, setShowTimeSlotError] = useState(false); // State to manage time slot error
    const [selectedDates, setSelectedDates] = useState([]);
    const [slotIdMap, setSlotIdMap] = useState({});
    const [subtotal, setSubtotal] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedSlotIds, setSelectedSlotIds] = useState([]);
    const onSubmit = async (values) => {
        const uniqueSelectedDates = [...new Set(selectedDates)];
        // Check if the number of unique selected sessions matches the required number
        if (uniqueSelectedDates.length < data?.no_of_sessions) {
            // setErrorMessage(`Please select ${data?.no_of_sessions - uniqueSelectedDates.length} more sessions`);
        } else {
            // Clear error and proceed with submission
            setErrorMessage('');
            console.log('Submitting form with values:', values);
            const price = subtotal || data?.minimum_price || values;
            // Extract the IDs of selected time slots
            const timeSlotIds = selectedSlotIds

            try {
                const dataToSend = {
                    slotIds: timeSlotIds,
                    price,
                    offerServiceId: data?.id
                };
                setDataToPaymentModal(dataToSend)
                console.log('Data to send:', dataToSend);

                setFiltersModal(true); // Show error if no time slots are selected
                closeModal()

            } catch (error) {
                console.error('Error submitting booking:', error);
            }
            // Add your submission logic here
        }
    };
    const handleActiveStartDateChange = useCallback(({ activeStartDate }) => {
        setActiveStartDate(activeStartDate);
    }, []);

    const fetchData = useCallback(async (formattedDate) => {
        try {
            const response = await getApi(`/user/service/date?offerId=${data?.id}&monthYear=${formattedDate}`);
            const dates = response.data;
            // Get the current date and time
            const currentDate = new Date();
            // Filter the dates to include only those greater than the current date
            const filteredDates = dates.filter(dateStr => {
                const date = new Date(dateStr);
                return date > currentDate;
            });
            // Convert dates from the API to the desired format
            const formattedDates = filteredDates.map(dateStr => {
                const date = new Date(dateStr);
                return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
            });

            setMarkedDates(formattedDates);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [data?.id]);

    const fetchInitialData = useCallback(async () => {
        const formattedDate = formatDateForAPI(activeStartDate);
        await fetchData(formattedDate);
    }, [activeStartDate, fetchData]);



    useEffect(() => {
        fetchInitialData();
    }, [activeStartDate, fetchInitialData]);

    const formatDateForAPI = (date) => {
        const month = date.getMonth() + 1; // Months are zero-indexed
        const year = date.getFullYear();
        return `${month}-${year}`;
    };


    const fetchSlotsData = useCallback(async (formattedDate) => {
        try {
            const response = await getApi(`/user/service-slots?offerId=${data?.id}&date=${formattedDate}`);
            console.log('Service slots:', response.data);
            const slots = response.data;
            const newIdMap = slots.reduce((acc, slot) => {
                const formattedTime = slot.start_time.trim(); // Trim any extra spaces
                acc[`${formattedDate}_${formattedTime}`] = slot.id;
                return acc;
            }, {});

            // Merge newIdMap with existing slotIdMap
            setSlotIdMap(prevMap => ({
                ...prevMap,    // Keep previous entries
                ...newIdMap    // Add/overwrite with new slots
            }));

            setAvailableTimes(slots.map(slot => slot.start_time.trim())); // Set available times

            console.log('Merged slotIdMap:', newIdMap); // Log the updated map
        } catch (error) {
            console.error('Error fetching slots data:', error);
        }
    }, [data?.id]);


    const handleDateClick = useCallback(async (date) => {
        const formattedDate = formatDateForSlotsAPI(date);
        const weekNumber = getWeekNumber(date); // Get the week number of the selected date

        setSelectedDates((prevDates) => {
            const updatedDates = [...prevDates, { date: formattedDate, week: weekNumber }];
            const weekDates = updatedDates.filter(d => d.week === weekNumber); // Get all selected dates for the current week

            // Convert markedDates to Date objects for accurate comparison
            const availableWeekDates = markedDates.filter((markedDate) => {
                const markedDateObj = new Date(markedDate); // Convert string to Date
                return getWeekNumber(markedDateObj) === weekNumber; // Check if it belongs to the same week
            });
            console.log('Selected dates in week:', weekDates);
            console.log('Available dates in week:', availableWeekDates);

            return updatedDates;
        });
        // Fetch available time slots for the selected date
        await fetchSlotsData(formattedDate);
    }, [data?.no_of_sessions, markedDates, fetchSlotsData]);


    const formatDateForSlotsAPI = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };
    // Function to calculate the week number for a given date
    const getWeekNumber = (date) => {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };
    //this is only select functionality
    const handleTimeSelect = useCallback((time, date) => {
        setSelectedTimes(prevTimes => {
            // Remove any previous time slot for this date
            const newTimes = prevTimes.filter(slot => !slot.startsWith(`${date}, `));
            // Add the new selected time slot
            const timeSlot = `${date}, ${time}`;
            return [...newTimes, timeSlot];
        });

        // Remove the error message when at least one time slot is selected
        if (selectedTimes.length > 0) {
            setShowTimeSlotError(false);
        }
    }, [selectedTimes]);
    const handleChooseButtonClick = () => {
        console.log('thisi is availabela', selectedTimes);

        if (selectedTimes.length >= 1) {
            setShowTimeSlotError(false); // Remove error when a time slot is selected
        }

        // Function to convert date from "dd/MM/yyyy" to "yyyy-MM-dd"
        const formatDateToSlotMapFormat = (dateString) => {
            const [day, month, year] = dateString.split('/'); // Split the date string
            return `${year}-${month}-${day}`; // Return the date in "yyyy-MM-dd" format
        };

        // Map selected times to their corresponding slot IDs from slotIdMap based on both date and time
        const newSlotIds = selectedTimes.map((entry) => {
            const time = entry.split(', ')[1].trim(); // Extract time from entry
            const date = entry.split(', ')[0].trim(); // Extract date from entry

            // Convert the date to match the format in slotIdMap
            const formattedDate = formatDateToSlotMapFormat(date);

            // Create a combined key of date and time
            const slotIdKey = `${formattedDate}_${time}`;
            const slotId = slotIdMap[slotIdKey]; // Look up slot ID by formatted date and time

            if (!slotId) {
                console.warn(`No slot ID found for ${formattedDate} at ${time}`); // Warn if no ID is found
            }

            return slotId || 'ID not found'; // Return slot ID or log a warning if it's missing
        });

        // Merge newSlotIds with existing slot IDs without replacing previous ones
        setSelectedSlotIds((prevSlotIds) => {
            const mergedSlotIds = [...prevSlotIds, ...newSlotIds];

            // Remove duplicates if any
            const uniqueSlotIds = Array.from(new Set(mergedSlotIds));

            return uniqueSlotIds; // Update the state with the combined unique IDs
        });

        console.log('New selected slot IDs:', newSlotIds);


        setSlotDisplay(selectedTimes);
    };
    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(':');
        let hours12 = parseInt(hours, 10);
        const ampm = hours12 >= 12 ? 'PM' : 'AM';
        hours12 = hours12 % 12 || 12; // Convert 0 to 12 for midnight
        return `${hours12}:${minutes} ${ampm}`;
    };
    const convertSlotTo12HourFormat = (slot) => {
        const [date, time] = slot.split(', '); // Split the date and time
        const [hours, minutes] = time.split(':');
        let hours12 = parseInt(hours, 10);
        const ampm = hours12 >= 12 ? 'PM' : 'AM';
        hours12 = hours12 % 12 || 12; // Convert 0 to 12 for midnight
        return `${date}, ${hours12}:${minutes} ${ampm}`;
    };

    const removeTimeSlot = (slot) => {
        setSelectedTimes(prevTimes => {
            const newTimes = prevTimes.filter(time => time !== slot);
            setSlotDisplay(newTimes);
            return newTimes;
        });
    };

    const validatePrice = (value) => {
        let error;
        if (!value) {
          error = 'Price is required.';
        } else if (value < data?.minimum_price || value > data?.maximum_price) {
          error = `Enter a price between $${data?.minimum_price} and $${data?.maximum_price}.`;
        }
        return error;
      };

    return (
        <div className="detail-page booking">
            <div className="container">
                <h2>{data?.title || 'Yoga'}</h2>
                {(data?.session_type === '1' && data?.session_offer_type === '2' && (data?.package_type === '2')) && (
                    <h3>
                        Select {data?.no_of_sessions || 2} dates per {data?.package_period || 'month'} for {data?.package_timeframe || 3} {data?.package_period || 'months'} ({(data?.no_of_sessions * data?.package_timeframe) || 6} Times)
                    </h3>
                )}

                <div className="detail-main">
                    <Formik
                        initialValues={initialValues}
                        // validationSchema={bookingInterviews}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {({ setFieldValue }) => (
                            <Form>
                                <div className="row">
                                    <div className="col-sm-8 col-lg-6 col-xl-6 select-date-data">
                                        <div className="select-date">
                                            <h4>Select date</h4>
                                            <div className="calender">
                                                <Calendar
                                                    onChange={onChange}
                                                    value={value}
                                                    onActiveStartDateChange={handleActiveStartDateChange}
                                                    // tileClassName={({ date }) => {
                                                    //     const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                                                    //     return markedDates.includes(formattedDate) ? 'highlight' : 'sanket';
                                                    // }}
                                                    tileClassName={({ date, view, activeStartDate }) => {
                                                        let className = '';

                                                        // Format the date
                                                        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

                                                        // Check if the date is from the previous month in month view
                                                        if (view === 'month' && date.getMonth() < activeStartDate.getMonth()) {
                                                            className += 'previous-date '; // Add class for previous month dates
                                                        }

                                                        // Check if the date is from the next month in month view (we have added class previous month because it has css class)
                                                        if (view === 'month' && date.getMonth() > activeStartDate.getMonth()) {
                                                            className += 'previous-date '; // Add class for next month dates
                                                        }

                                                        // Check if the date is in markedDates
                                                        if (markedDates.includes(formattedDate)) {
                                                            className += 'highlight '; // Add class for marked dates
                                                        } else {
                                                            className += 'sanket '; // Add class for non-marked dates
                                                        }

                                                        return className.trim(); // Return trimmed className to avoid trailing spaces
                                                    }}
                                                    tileDisabled={({ date }) => {
                                                        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                                                        return !markedDates.includes(formattedDate); // Disable dates not in markedDates
                                                    }}
                                                    onClickDay={handleDateClick}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6 col-xl-6  select-date-&-time">
                                        <div className="select-date">
                                            <div className=''>
                                                <h4>Select time</h4>
                                                <div className="row scroll-time-set" >
                                                    {availableTimes.length === 0 ? (
                                                        <p>No time slots available</p>
                                                    ) : (
                                                        availableTimes.map((time) => (
                                                            <div className="col-sm-3" key={time}>
                                                                <label className="time">
                                                                    <input
                                                                        type="radio"
                                                                        name={`timeSlot-${value.toLocaleDateString()}`} // Use the date to group time slots
                                                                        value={time}
                                                                        checked={selectedTimes.includes(`${value.toLocaleDateString()}, ${time}`)}
                                                                        onChange={() => handleTimeSelect(time, value.toLocaleDateString())}
                                                                    />
                                                                    <span className="checkmark">
                                                                        <h5>{convertTo12HourFormat(time)}</h5>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                                {availableTimes.length > 0 && ( // Conditionally render button
                                                    <button type="button" className={`btn btn-primary`} onClick={handleChooseButtonClick}>
                                                        Choose
                                                    </button>
                                                )}
                                                <div className='select-date'>
                                                    {availableTimes.length > 0 && (
                                                        <h4>Selected dates </h4>
                                                    )}
                                                    <div className='select-date-scroll'>
                                                        {slotDisplay.length > 0 && slotDisplay.map((slot, index) => (
                                                            <div key={index} className="selected-slot">
                                                                <span><img src={check} alt="close" />{convertSlotTo12HourFormat(slot)}</span>
                                                                <span className='cross' onClick={() => removeTimeSlot(slot)}><img src={remove} alt="close" /></span>
                                                            </div>
                                                        ))}

                                                        {data?.no_of_sessions > slotDisplay.length && slotDisplay.length > 0 && (
                                                            <>
                                                                {Array.from({ length: data?.no_of_sessions - slotDisplay.length }).map((_, index) => (
                                                                    <div className="selected-slot">
                                                                        <span key={index} className="check-mark">
                                                                            <img src={uncheckCircle} alt="check" />
                                                                        </span>
                                                                    </div>
                                                                ))}

                                                                {/* Message showing how many more slots to select */}
                                                                <p className="error-message">
                                                                    Please select {data?.no_of_sessions - slotDisplay.length} more {data?.no_of_sessions - slotDisplay.length === 1 ? 'session' : 'sessions'}.
                                                                </p>

                                                            </>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                            {data?.pricing_type === '2' && (
                                                <div className='enter-price'>
                                                    <p className='enter-label'>Enter a price between ${data?.minimum_price} and ${data?.maximum_price}</p>
                                                    <label htmlFor="price">Your price</label>
                                                    <Field
                                                        type="number"
                                                        id="price"
                                                        name="price"
                                                        min={data?.minimum_price}
                                                        max={data?.maximum_price}
                                                        step="0.01"
                                                        placeholder="Enter your price"
                                                        validate={validatePrice}
                                                    />
                                                    <div className='message-anna'>
                                                        {data?.message_to_user !== null && (
                                                            <>
                                                                <h4>Message from {data?.userData?.name}:</h4>
                                                                <p> {data?.message_to_user} <a>Yoga for All Foundation</a></p>
                                                            </>
                                                        )}
                                                    </div>
                                                    <ErrorMessage name="price" component="div" className="text-danger" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    <div className='subtotal text-end'>
                                        <p className='mt-3 pe-3'>
                                            <strong>Subtotal Price</strong> <b>${data?.price || data?.minimum_price || data?.maximum_price || '-'}</b>
                                        </p>
                                        <button type="submit" className="choose">Complete Booking</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default MonthlyBookingModal;
