import React, { useState, useEffect } from 'react';
import eye from '../../../assets/images/Eye.svg';
import eyeClosed from '../../../assets/images/Eye-closed.svg';
import { useNavigate } from 'react-router-dom';
import { putApi } from '../../../Services/apiService';
import { toast } from 'react-toastify';

function AdminAccounts({type}) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (oldPassword && newPassword === oldPassword) setError('New password cannot be the same as old password');
        else if (newPassword && confirmPassword && newPassword !== confirmPassword) setError('New password and confirm password must match');
        else setError('');
    }, [newPassword, confirmPassword, oldPassword]);

    const handleUpdate = async () => {
        let hasError = false;
        if (!oldPassword) { setOldPasswordError('Old password is required'); hasError = true; }
        if (!newPassword) { setNewPasswordError('New password is required'); hasError = true; }
        if (!confirmPassword) { setConfirmPasswordError('Confirm password is required'); hasError = true; }
        if (hasError || error) return;
        console.log('Old Password:', oldPassword);
        console.log('New Password:', newPassword);
        try {
            setLoader(true);
            const data = { old_password: oldPassword, new_password: newPassword };
            let response;
            {type === 'creator'?
                response= await putApi('/update-password', data)
                :

            response= await putApi('/admin/update-password', data)
            }
            if (response.statusCode === 200) {
                setLoader(false);
                localStorage.clear();
                navigate('/');
                setOldPassword(''); setNewPassword(''); setConfirmPassword('');
            } else {
                toast.error(response.message)
                setLoader(false)
                /* Handle error */ }
        } catch (error) { console.error('Error submitting:', error); }
    };

    const handleOldPasswordChange = (e) => { setOldPassword(e.target.value); if (e.target.value) setOldPasswordError(''); };
    const handleNewPasswordChange = (e) => { setNewPassword(e.target.value); if (e.target.value) setNewPasswordError(''); };
    const handleConfirmPasswordChange = (e) => { setConfirmPassword(e.target.value); if (e.target.value) setConfirmPasswordError(''); };

    return (
        <div className="setting-main account error-fixs">
            <h2>{type==='creator' ? 'Update Password' :'Change Password'}</h2>
            <div className="form-group position-relative">
                <label htmlFor="old-password">Old password</label>
                <div className="password-wrapper">
                    <input name="old-password" type={showOldPassword ? "text" : "password"} id="old-password" placeholder="Enter old password" className="form-control" value={oldPassword} onChange={handleOldPasswordChange}/>
                    <span className='eye-icons' onClick={() => setShowOldPassword(!showOldPassword)}>{showOldPassword ? <img src={eye} alt='Show' /> : <img src={eyeClosed} alt='Hide' />}</span>
                </div>
                {oldPasswordError && <div className="error">{oldPasswordError}</div>}
            </div>
            <div className="form-group position-relative">
                <label htmlFor="new-password">New password</label>
                <div className="password-wrapper">
                    <input name="new-password" type={showNewPassword ? "text" : "password"} id="new-password" placeholder="Enter new password" className="form-control" value={newPassword} onChange={handleNewPasswordChange}/>
                    <span className='eye-icons' onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? <img src={eye} alt='Show' /> : <img src={eyeClosed} alt='Hide' />}</span>
                </div>
                {newPasswordError && <div className="error">{newPasswordError}</div>}
            </div>
            <div className="form-group position-relative">
                <label htmlFor="confirm-password">Confirm password</label>
                <div className="password-wrapper">
                    <input name="confirm-password" type={showConfirmPassword ? "text" : "password"} id="confirm-password" placeholder="Confirm new password" className="form-control" value={confirmPassword} onChange={handleConfirmPasswordChange}/>
                    <span className='eye-icons' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>{showConfirmPassword ? <img src={eye} alt='Show' /> : <img src={eyeClosed} alt='Hide' />}</span>
                </div>
                {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
            </div>
            {error && <div className="error">{error}</div>}
            <div className="setting-btns">
                <button className="setting-btn save update-password-setting" onClick={handleUpdate} disabled={loader}>
                    {loader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                    Update password
                </button>
            </div>
        </div>
    );
}

export default AdminAccounts;
