import React, { useEffect, useState } from 'react'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link } from 'react-router-dom'
import calendar from '../../../assets/images/calender.png'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { insertUserData } from '../../../Counter/userSignUpSlice';
import { bookingInterviews, bookingInterviewsCreator } from '../../../Validations/formValidation';
import Header from '../../CreatorComponents/CreatorJoin/header';
import { getApi, postApi } from '../../../Services/apiService';

function BookConsultation({ id,setIsModal,setIsModalLook}) {
  const initialValues = {
    selectedDate:  '',
    selectedTimes: '',
  }
  const [selectedTime, setSelectedTime] = useState('');
  const [value, onChange] = useState('');
  const [monthDate,setMonthDate] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [activeMonth,setActiveMonth] = useState(new Date());
  const [loader,setLoader] = useState(false)
  const dispatch = useDispatch();

  useEffect(()=>{
    if(value !==''){
      handleDateSlotData();
      }
  },[value])
  useEffect(()=>{
    handleActiveStartDateChange();
  },[activeMonth])

  const handleDateSlotData = async() =>{
    const month = new Date(value).getMonth();
    const year = new Date(value).getFullYear();
    const currentDate = new Date(value).getDate();
    const response = await getApi(`/user/discovery-availbility-slots?id=${id}&date=${year}-${month+1}-${currentDate}`)
    if(response?.statusCode === 200){
      setTimeSlots(response?.data)
    }
    else{

    }
  }

  const handleActiveStartDateChange = async( ) => {
    const month = new Date(activeMonth).getMonth();
    const year = new Date(activeMonth).getFullYear();

    const response = await getApi(`/user/discovery-availbility?id=${id}&monthYear=${month+1}-${year}`)
    if(response?.statusCode === 200){
      const formattedDates = response?.data.map(dateTime => dateTime.slice(0, 10));
      setMonthDate(formattedDates)
    }
    // setActiveStartDate(activeStartDate);
    // Fetch availability for the new month
    // getAvailability();
  }

  const convertTo24HourFormat = (time) => {
    const [hours, minutesPart] = time.split(':');
    const [minutes, period] = minutesPart.match(/[a-zA-Z]+|[0-9]+/g);
    let hours24 = parseInt(hours, 10);
  
    if (period.toLowerCase() === 'pm' && hours24 !== 12) {
      hours24 += 12;
    }
    if (period.toLowerCase() === 'am' && hours24 === 12) {
      hours24 = 0;
    }
  
    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  };
  const onSubmit = async (datas) => {
    setLoader(true)
    const selectedTime = timeSlots.find(time => time.id === datas?.selectedTimes);
    const values ={
      selectedDate:datas?.selectedDate || value,
      // selectedTimes:datas?.selectedTimes
      selectedTimes: `${selectedTime?.start_time} - ${selectedTime?.end_time}` ,
      slotId: datas?.selectedTimes,
      creatorId:id
    }
    const response = await postApi('/user/discovery-book',values)
    if(response.statusCode === 200){
      setIsModal(false)
      setIsModalLook(true)
    }
    setLoader(false)

  }

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(':');
    let period = 'AM';
    let hour = parseInt(hours, 10);
  
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  
    return `${hour}:${minutes} ${period}`;
  }

  return (
    // <div className="detail-page tell-us booking">
    <div className="detail-page  booking signup-cs">
      <div className="container">

        <h2 className='text-start'>Free  discovery  call  to  connect with  Marley</h2>
        <div className="detail-main">
          <Formik
            initialValues={initialValues}
            validationSchema={bookingInterviewsCreator}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="select-date">
                      <h4>Select date</h4>
                      <div className="calender">
                        <Calendar
                          onChange={onChange}
                          value={value}
                          minDate={new Date()}
                          onActiveStartDateChange={(e)=>setActiveMonth(e?.activeStartDate)}
                          tileClassName={({ date }) => {
                            // const formattedDate = date.toISOString().split('T')[0];
                            const formattedDate = date.toLocaleDateString('en-CA');
                            if (monthDate.includes(formattedDate)) {
                              return 'highlight';
                            }
                            else{
                              return 'sanket'
                            }
                            return null;
                          }}
                        />
                        <ErrorMessage name="selectedDate" component="div" className="text-danger" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="select-date">
                      <h4>Select time</h4>
                      <div className="row select-log-tag">
                        {timeSlots.map((time) => (
                          <div className="col-xl-4 col-lg-5" key={time}>
                            <label className="time">
                              <Field type="radio" name="selectedTimes" value={time?.id} />
                              <span className="checkmark">
                                {/* <h5>{time}</h5> */}
                                <h5>{convertTo12HourFormat(time?.start_time)} - {convertTo12HourFormat(time?.end_time)}</h5>
                              </span>
                            </label>
                          </div>
                        ))}
                        {timeSlots?.length === 0 && <p className='text-start'>Please select a date on the left calendar to view available time slots</p>}
                        <ErrorMessage name="selectedTimes" component="div" className="text-danger" />
                      </div>
                    
                     
                    </div>
                  </div>
                  <div className='col-md-12'>
                  <button type="submit" className="choose float-end" disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Choose</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <div className="back-button">
          <button className="button" onClick={() => prevStep()}><img src={leftArrow} alt=''/> </button>
        </div> */}
      </div>
    </div>
  )
}

export default BookConsultation