import React, { useEffect, useState } from 'react';
import search from '../../../assets/images/search.svg';
import tick from '../../../assets/images/green-tick.svg';

 

function OrderFilter(type) {

    const [searchQueryUsers, setSearchQueryUsers] = useState('');
    const [searchQueryCreator, setSearchQueryCreator] = useState('');
    console.log('this is type',type);
    const [selectedProductType, setSelectedProductType] = useState({
        physical: false,
        digital: false,
      });
      const [selectedStatus, setSelectedStatus] = useState({
        completed: false,
        declined: false,
        processing: false,
      });
    const handleProductTypeCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSelectedProductType(prev => ({
          ...prev,
          [name]: checked
        }));
      };
      const handleStatusCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSelectedStatus(prev => ({
          ...prev,
          [name]: checked
        }));
      };
      const handleCreatorsSearchChange = (e) => {
        setSearchQueryCreator(e.target.value);
    };
      const handleUsersearchChange = (e) => {
        setSearchQueryUsers(e.target.value);
    };
    return (

    <>
        <div>
            <h3>Filters</h3>
                <h4>Creators</h4>
                <div className="popular-search position-relative">
                 
                    <input
                        type="text"
                        placeholder="Type Creator Name"
                        value={searchQueryCreator}
                        onChange={handleCreatorsSearchChange}
                    />
                       <button>
                        <img src={search} alt="search" />
                    </button>
                     <div className='creator-list'>
                    <ul>
                        <li>
                          <div className='w-75'>
                          <span className='user me-3'><img src=""  alt='img'></img></span> 
                           <p>Mari Alls</p>
                            </div> 
                            <div className='w-25 text-end'>
                            <span className='tick'><img src={tick}  alt='img'></img></span>
                            </div>
                            </li>
                    </ul>
                </div>
                </div>
               


                <h4>Users</h4>
                <div className="popular-search position-relative">
                 
                    <input
                        type="text"
                        placeholder="Type User Name"
                        value={searchQueryUsers}
                        onChange={handleUsersearchChange}
                    />
                       <button>
                        <img src={search} alt="search" />
                    </button>
                    <div className='creator-list'>
                    <ul>
                        <li>
                          <div className='w-75'>
                          <span className='user me-3'><img src=""  alt='img'></img></span> 
                           <p>Mari Alls</p>
                            </div> 
                            <div className='w-25 text-end'>
                            <span className='tick'><img src={tick}  alt='img'></img></span>
                            </div>
                            </li>
                    </ul>
                </div>
                </div>
                



            <h4>Product Type</h4>

            <div className=''>
                <div class=" cannvas-check mt-2">
                  <label className="container2">
                    Physical
                    <input
                      type="checkbox"
                      name="physical"
                      checked={setSelectedProductType.physical}
                      onChange={handleProductTypeCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div class="cannvas-check ">
                  <label className="container2">
                    Digital
                    <input
                      type="checkbox"
                      name="digital"
                      checked={setSelectedProductType.digital}
                      onChange={handleProductTypeCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
            </div>
            <h4>Status</h4>
            <div className=''>
                <div class=" cannvas-check mt-2">
                  <label className="container2">
                    Completed
                    <input
                      type="checkbox"
                      name="completed"
                      checked={setSelectedStatus.completed}
                      onChange={handleStatusCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div class="cannvas-check ">
                  <label className="container2">
                    Declined
                    <input
                      type="checkbox"
                      name="declined"
                      checked={setSelectedStatus.declined}
                      onChange={handleStatusCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div class="cannvas-check ">
                  <label className="container2">
                    Processing
                    <input
                      type="checkbox"
                      name="processing"
                      checked={setSelectedStatus.processing}
                      onChange={handleStatusCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
            </div>

        </div>
        <div class=" show-btns">
        <button>Show Changes</button>
      </div>
      </>
    )
}
export default OrderFilter;
