import React, { useState, useCallback, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import add from '../../../assets/images/add-slot.svg';
import cross from '../../../assets/images/cross-button.svg';
import { type } from '@testing-library/user-event/dist/cjs/utility/type.js';
import { datetime, RRule, RRuleSet } from 'rrule';
import { getApi, postApi } from '../../../Services/apiService';

function DiscoveryCallModal({data,closeModal}) {
  const [value, onChange] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState({});
  const [sameTimeSlots, setSameTimeSlots] = useState([{from:'',to:''}]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [sameForAllDays, setSameForAllDays] = useState(true);  // Set initial state to true
  const [recurrences, setRecurrences] = useState([])
  const [selectedDayLabel, setSelectedDayLabel] = useState([]);
  const [dayLabel, setDayLabel] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);
  const [sessionDuration,setSessionDuration] = useState('')
  const [discoveryApi,setDiscoveryApi] = useState(false)
  const [loader,setLoader] = useState(false)
  const [errors,setErrors] = useState({})
  console.log("loader",loader);

  const daysOfWeek = [
    { label: 'Sunday', value: RRule.SU, name: 'SU' },
    { label: 'Monday', value: RRule.MO, name: 'MO' },
    { label: 'Tuesday', value: RRule.TU, name: 'TU' },
    { label: 'Wednesday', value: RRule.WE, name: 'WE' },
    { label: 'Thursday', value: RRule.TH, name: 'TH' },
    { label: 'Friday', value: RRule.FR, name: 'FR' },
    { label: 'Saturday', value: RRule.SA, name: 'SA' },
  ];
  
  const validateTimeSlot = (slot) => {
    if (!slot.from || !slot.to) {
      return 'Both "From" and "To" time must be filled';
    }
    if (slot.from >= slot.to) {
      return '"From" time must be before "To" time';
    }
    return null; // No errors
  };

  const validateForm = () => {
    const validationErrors = {};
    
    // Check for selected days
    if (!selectedDayLabel.length && !sameForAllDays) {
      validationErrors.days = 'Please select at least one day.';
    }

    // Validate time slots for "sameForAllDays"
    if (sameForAllDays) {
      sameTimeSlots.forEach((slot, index) => {
        const error = validateTimeSlot(slot);
        if (error) {
          validationErrors[`sameTimeSlots_${index}`] = error;
        }
      });
    } else {
      // Validate time slots for individual days
      selectedDayLabel.forEach((day, ind) => {
        day.dates.forEach((slot, index) => {
          const error = validateTimeSlot(slot);
          if (error) {
            validationErrors[`day_${day.label}_${index}`] = error;
          }
        });
      });
    }
    if(selectedTimeSlot === ''){
      validationErrors.duration = 'Please select a duration.';
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  useEffect(()=>{
    generateRecurrenceStrings()
  },[selectedDayLabel])

  const handleDayClick = (day, label, name) => {
    // if (selectedDays.includes(day)) {
    //   setSelectedDays((prev) => prev.filter((d) => d !== day));
    //   setTimeSlots((prev) => {
    //     const updatedSlots = { ...prev };
    //     delete updatedSlots[day];
    //     return updatedSlots;
    //   });
    // } else {
    //   setSelectedDays((prev) => [...prev, day]);
    //   if (sameForAllDays) {
    //     const commonSlot = timeSlots[selectedDays[0]] ? timeSlots[selectedDays[0]][0] : { from: '', to: '' };
    //     setTimeSlots((prev) => ({ ...prev, [day]: [commonSlot] }));
    //   } else {
    //     setTimeSlots((prev) => ({ ...prev, [day]: [{ from: '', to: '' }] }));
    //   }
    // }

    setDayLabel(prevDays =>
      prevDays.includes(label)
        ? prevDays.filter(d => d !== label)
        : [...prevDays, label]
    );
    setSelectedDayLabel(prevDays => {
      const labelExists = prevDays.some(d => d.label === label);
      if (labelExists) {
        return prevDays.filter(d => d?.label !== label);
        //   updatedDayts = prevDays.filter(d => d.label !== label);
        // return updatedDayts;
      } else {
        return [...prevDays, { label: label, name: name, dates: [{ from: '', to: '' }] }];
        // updatedDayts = [...prevDays, { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }];
        // return updatedDayts
      }
    });
  };

  const handleAddTimeSlot = (label) => {
    if (sameForAllDays) {
      // setTimeSlots((prev) => {
      //   const updatedSlots = { ...prev };
      //   selectedDays.forEach(d => {
      //     if (updatedSlots[d]) {
      //       updatedSlots[d].push({ from: '', to: '' });
      //     }
      //   });
      //   return updatedSlots;
      // });
      setSameTimeSlots([...sameTimeSlots, { from: '', to: '' }]);
    } else {
      // setTimeSlots((prev) => ({
      //   ...prev,
      //   [day]: [...prev[day], { from: '', to: '' }],
      // }));
      setSelectedDayLabel((prev) => {
        return prev.map((item) => {
          if (item?.label === label) {
            // Add a new empty time slot to the timeSlots array
            const updatedTimeSlots = [...item.dates, { from: '', to: '' }];
  
            // Return the updated date object with the new time slot added
            return { ...item, dates: updatedTimeSlots };
          }
          return item;
        });
      });
    }
  };

  const handleRemoveTimeSlot = (day, index) => { //label, indexToRemove
    if(sameForAllDays){
      setSameTimeSlots(sameTimeSlots.filter((_, i) => i !== index));
    }
    else{
    // setTimeSlots((prev) => {
    //   const updatedSlots = prev[day].filter((_, i) => i !== index);
    //   return { ...prev, [day]: updatedSlots };
    // });
    setSelectedDayLabel((prev) => {
      return prev.map((item) => {
        if (item?.label === day) {
          // Remove the time slot at the specified index
          const updatedTimeSlots = item.dates.filter((_, i) => i !== index);

          // Return the updated date object with the modified timeSlots array
          return { ...item, dates: updatedTimeSlots };
        }
        return item;
      });
    });
  }
  };

  const handleTimeChange = (index, field, value, typenumber, typeCalendar, date, ind) => {
  // const handleTimeChange = (day, index, field, value) => {
    // setTimeSlots((prev) => {
    //   const updatedSlots = prev[day].map((slot, i) =>
    //     i === index ? { ...slot, [field]: value } : slot
    //   );
    //   return { ...prev, [day]: updatedSlots };
    // });
    setSelectedDayLabel(prevState => {
      return prevState.map(item => {
        if (item?.label === date) {
          // Update the specific time slot at the given index
          const updatedTimeSlots = item.dates.map((slot, i) => {
            if (i === ind) {
              return { ...slot, [field]: value };
            }
            return slot;
          });

          // Return the updated date object with the modified timeSlots array
          return { ...item, dates: updatedTimeSlots };
        }
        return item;
      });
    });
  };

  const handleToggleChange = () => {
    setSameForAllDays((prev) => !prev);
    if (!sameForAllDays) {
      const commonSlot = timeSlots[selectedDays[0]] ? timeSlots[selectedDays[0]][0] : { from: '', to: '' };
      const updatedTimeSlots = {};
      selectedDays.forEach(day => {
        updatedTimeSlots[day] = [commonSlot];
      });
      setTimeSlots(updatedTimeSlots);
    } else {
      setTimeSlots((prev) => {
        const updatedSlots = { ...prev };
        selectedDays.forEach(day => {
          if (!updatedSlots[day]) {
            updatedSlots[day] = [{ from: '', to: '' }];
          }
        });
        return updatedSlots;
      });
    }
  };

  const getDayOfWeek = (date) => {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return days[date.getDay()];
  };

  const handleActiveStartDateChange = useCallback(({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  }, []);

  const isDaySelected = (date) => {
    const dayOfWeek = getDayOfWeek(date);
    const today = new Date();
    return selectedDays.includes(dayOfWeek) && date > today && date.getMonth() === today.getMonth();
  };

  const isCurrentDate = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleTimeSlotClick = (slot) => {
    setSelectedTimeSlot(slot);
  };

  const fullDayNames = {
    Mo: 'Monday',
    Tu: 'Tuesday',
    We: 'Wednesday',
    Th: 'Thursday',
    Fr: 'Friday',
    Sa: 'Saturday',
    Su: 'Sunday',
  };
  const handleSameTimeChange = (index, field, value) => {
    const updatedTimeSlots = sameTimeSlots.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    setSameTimeSlots(updatedTimeSlots);
  };

  const generateRecurrenceStrings = (types) => {
   
    if(types === 'discovery'){
      setLoader(true)
      setDiscoveryApi(true);
    }
    let startDate =  new Date().getDate();
    let fullyear = new Date().getFullYear();
    let month = new Date().getMonth();
    const dayName = new Date().getDay();


    setRecurrences([]);

    const dayMap = {
      Sunday: RRule.SU,
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
    };

    // const newRecurrenceRules = [...recurrences];
    const newRecurrenceRules = [];
const dtstartnext = new Date();
dtstartnext.setMonth(dtstartnext.getMonth() + 1);
dtstartnext.setDate(0);
const formattedDate = dtstartnext.toISOString().split('T')[0];

console.log(formattedDate); 
    // if(sessionType !== 'multiple_session'){
    selectedDayLabel.forEach((x) => {
      let ruleOptions;
      const dtstart = new Date();
      dtstart.setUTCHours(0, 0, 0, 0);
      
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart: dtstart,
          until:new Date(formattedDate),
          byweekday: [dayMap[x?.label]],
        };
      
     
      const rru = new RRuleSet();
      rru.rrule(new RRule(ruleOptions));
     
      // let obj = sameTime
      let obj = sameForAllDays && selectedDays?.length !==1
        ? {
          dayOfWeek: x?.name,
          sessionDuration: Number(selectedTimeSlot),
          timeSlots: sameTimeSlots,
          rruleString: rru.toString(),
        }
        : {
          dayOfWeek: x?.name,
          sessionDuration: Number(selectedTimeSlot),
          timeSlots: x?.dates,
          rruleString: rru.toString(),
        };

      newRecurrenceRules.push(obj);
    });


    console.log("newRecurrenceRulesnewRecurrenceRules", newRecurrenceRules)
    console.log('recurrences>>>>>>>>>>>>>>>>>', recurrences)
    
    setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
    let recurresRecord = [];
    console.log("recurresRecordrecurresRecord", recurresRecord);

    updateMarkedDates(newRecurrenceRules, types);
    
    // updateMarkedDates(newRecurrenceRules, isMarked);
    console.log("recurrenceString", newRecurrenceRules);
  };

  const updateMarkedDates = async(updatedRecurrences, types) => {
    console.log("updatedRecurrences", updatedRecurrences)
    if (updatedRecurrences && updatedRecurrences.length > 0) {
      const newMarkedDates = updatedRecurrences.reduce((acc, occurrence) => {
        console.log('ifffffff');
        // const abc= 'DTSTART:20240831T183000Z\nRRULE:FREQ=WEEKLY;COUNT=1';
        const dates = parseRRule(occurrence?.rruleString);
        console.log("datesrecurrence", dates)
        return [...acc, ...dates];
      }, []);
      console.log("newMarkedDatesnewMarkedDates", newMarkedDates);
      setMarkedDates(newMarkedDates);
      const values = {
        session_occurrences:updatedRecurrences
        // session_occurrences:recurrences
      }
      
      if(validateForm()){
      if(types === 'discovery'){
      const response = await postApi('/creator/discovery',values)
      if(response.statusCode === 200){
        const response = await getApi('/creator/dashboard');
        if (response?.statusCode === 200) {
          localStorage.setItem('full_user_detail', JSON.stringify(response?.data))
          localStorage.setItem('user_role', response?.data?.roles[0]?.user_role)
          localStorage.setItem('user_social_links', JSON.stringify(response?.data?.social_links))
        }
        setDiscoveryApi(false)
        setLoader(false)
        closeModal()
      }
      else{
        setDiscoveryApi(false)
        setLoader(false)
      }
    }
    }else{
      setLoader(false)
    }
    }
  };
  const parseRRule = (rruleString) => {
    console.log('rruleString', rruleString)
    if (typeof rruleString !== 'string') {
      console.error('Expected a string but got:', typeof rruleString, rruleString);
      return []; 
    }
    const lines = rruleString.split('\n');
    let rruleLine = '';
    let rdates = [];
    let dtstart = [];
    let exdates = [];
    for (let i = lines.length - 1; i >= 0; i--) {
      const line = lines[i];
      console.log('line', line)
      if (line.startsWith('RRULE')) {
        rruleLine += line.split(':')[1]; 
      } else if (line.startsWith('DTSTART')) {
        const dtstart = line.split(':')[1]; 
        const dtstartDate = dtstart.split('T')[0];
        rruleLine += `;DTSTART=${dtstart}`;
      } else if (line.startsWith('RDATE')) {
        const rdateLine = line.split(':')[1];
        const dateStrings = rdateLine.split(',');
        dateStrings.forEach(dateStr => {
          rdates.push(dateStr);
        });
      }
      else if (line.startsWith('EXDATE')) {
        console.log()
        const rdateLine = line.split(':')[1];
        const dateStrings = rdateLine.split(',');
        dateStrings.forEach(dateStr => {
          exdates.push(dateStr);
        });
      }
    }

    let rruleStrings = rruleLine;

    let finalRRuleString = `rrulestr('${rruleStrings}')`
    console.log('rruleLine', finalRRuleString)

    const ruleSet = new RRuleSet();
    const rrule = RRule.fromString(rruleStrings);

    ruleSet.rrule(rrule);
    exdates.forEach(dateStr => {
      const isoDateStr = dateStr.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
      const exdate = new Date(isoDateStr);
      if (!isNaN(exdate.getTime())) {
        ruleSet.exdate(exdate);
      } else {
        console.error('Invalid EXDATE:', dateStr);
      }
    });

    rdates.forEach(dateStr => {
      const isoDateStr = dateStr.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
      const exdate = new Date(isoDateStr);
      if (!isNaN(exdate.getTime())) {
        ruleSet.rdate(exdate);
      } else {
        console.error('Invalid RDATE:', dateStr);
      }
    });
    const rruleDates = ruleSet.all();
    console.log("rruleDatesrruleDates", rruleDates)


    const allDates = [...rruleDates];
    console.log("allDates", allDates)
    const formattedDate = allDates.map(date => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });

    console.log("formattedDate", formattedDate);
    return formattedDate;
  };

  return (
    <div className="users meal-suggestion">
      <div className="HomeMainDiv">
        <div className="row">
          <div className="col-sm-6">
            <div className='discovery-call-left'>
              <h2>Availability for Discovery call</h2>
              <h3>Please, choose the dates on the calendar that work for your discovery call.</h3>
              <div className="select-date">
                <div className="calender">
                  <Calendar
                    onChange={onChange}
                    value={value}
                    onActiveStartDateChange={handleActiveStartDateChange}
                    // tileClassName={({ date }) => {
                    //   let className = '';
                    //   if (isDaySelected(date)) {
                    //     className = 'highlight';
                    //   }
                    //   if (isCurrentDate(date)) {
                    //     className += ' black-current';
                    //   }
                    //   return className.trim();
                    // }}
                    tileClassName={({ date }) => {
                      // const formattedDate = date.toISOString().split('T')[0];
                      const formattedDate = date.toLocaleDateString('en-CA');
                      if (markedDates.includes(formattedDate)) {
                        return 'highlight';
                      }
                      return null;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='discovery-call-right'>
              <h4>Select days and set time frames</h4>
              <div className='select-days'>
                <h3>Select Days</h3>
                <ul>
                  {daysOfWeek.map((day) => (
                    <li
                      key={day}
                      className={dayLabel.includes(day?.label) ? 'active' : ''}
                      onClick={() => handleDayClick(day?.value, day?.label, day?.name)}
                      // onClick={() => handleDayClick(day)}
                    >
                      {day?.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className='select-days'>
                <h3>Call Duration</h3>
                <div className='row'>
                  {['15', '30', '45', '60'].map((duration) => (
                    <div className='col-sm-3' key={duration}>
                      <div
                        className={`duration ${selectedTimeSlot === duration ? 'active' : ''}`}
                        onClick={() => handleTimeSlotClick(duration)}
                      >
                        {duration}min
                      </div>
                      
                    </div>
                  ))}
                  <p className="error">{errors?.duration}</p>
                </div>
              </div>
              <div className='select-days'>
                <h3>Available time window</h3>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={sameForAllDays}
                    onChange={handleToggleChange}
                  />
                  <span className="slider round"></span>
                </label>
                <h5>Same for all days</h5>
              </div>

              {!sameForAllDays && selectedDayLabel && selectedDayLabel?.length>0 && selectedDayLabel?.map((day,ind) => (
                <div className='select-days' key={ind}>
                  {!sameForAllDays && <h4>{day?.label}</h4>}
                  {day?.dates.map((slot, index) => (
                    <div className='row' key={index}>
                      <div className='col-sm-5'>
                        <div className='form-group'>
                          <label>From </label>
                          <input
                            type='time'
                            className='form-control'
                            value={slot.from}
                            onChange={(e) => handleTimeChange(ind,'from', e.target.value,1, '', day?.label, index)}
                            // onChange={(e) => handleTimeChange(day, index, 'from', e.target.value)}
                            required
                          />
                          {errors[`day_${day.label}_${index}`] && (
                            <p className="error">{errors[`day_${day.label}_${index}`]}</p>
                          )}
                         
                        </div>
                      </div>
                      <div className='col-sm-5'>
                        <div className='form-group'>
                          <label>To</label>
                          <input
                            type='time'
                            className='form-control'
                            value={slot.to}
                            onChange={(e) => handleTimeChange(ind,'to', e.target.value,1, '', day?.label, index)}
                            required
                          />
                        </div>
                      </div>
                      <div className='col-sm-2'>
                        <div className='form-group'>
                          {index === 0 ? (
                            <button className='add-btn' onClick={() => handleAddTimeSlot(day?.label)}>
                            {/* <button className='add-btn' onClick={() => handleUpdateSelectedTimeSlots(day?.label)}> */}
                              <img src={add} alt='Add' />
                            </button>
                          ) : (
                            <button className='add-btn' onClick={() => handleRemoveTimeSlot(day?.label, index)}>
                              <img src={cross} alt='Remove' />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              {sameForAllDays &&
                <div className='select-days'>
               {sameTimeSlots.map((slot, index) => (
                            <>
                            <div className='row' key={index}>
                              <div className='col-sm-5'>
                                <div className='form-group'>
                                  <label>From</label>
                                  <input
                                    type='time'
                                    className='form-control'
                                    value={slot.from}
                                    onChange={(e) => handleSameTimeChange(index, 'from', e.target.value)}
                                    required
                                  />
                                    {errors[`sameTimeSlots_${index}`] && (
                            <p className="error">{errors[`sameTimeSlots_${index}`]}</p>
                          )} 
                                </div>
                              </div>
                              <div className='col-sm-5'>
                                <div className='form-group'>
                                  <label>To</label>
                                  <input
                                    type='time'
                                    className='form-control'
                                    value={slot.to}
                                    onChange={(e) => handleSameTimeChange(index, 'to', e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              {/* {index === sameTimeDuratioon.length - 1 && ( */}
                              <div className='col-sm-2 text-center'>
                                <div className='form-group'>
                                  {index === 0 ? <button className='add-btn' onClick={handleAddTimeSlot}>
                                    <img src={add} alt='' />
                                  </button> :
                                    <button className='add-btn' onClick={() => handleRemoveTimeSlot('',index)}>
                                      <img src={cross} alt='' />
                                    </button>}
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                            {/* <div className='border-btm mb-3'></div> */}
                            </>
                          ))}
                        
              </div>
              }

              <button className='save-sch' type='button' onClick={()=>{generateRecurrenceStrings('discovery')}} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save Discovery Schedule</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default DiscoveryCallModal;
