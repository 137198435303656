import React, { useEffect, useState } from 'react'
import discoveryCall from '../../../assets/images/discovery-call.svg'
import instagram from '../../../assets/images/Instagram-Logo.wine 1.svg'
import facebook from '../../../assets/images/facebook-logo.svg'
import twitter from '../../../assets/images/twitter-x-seeklogo-3 1.svg'
import ServiceOffering from '../../HomeComponent/serviceOffering'
// import Gallery from './gallery'
import Ellipse from '../../../assets/images/Ellipse 13.svg'
import yoga_hero from '../../../assets/images/yoga-hero.svg'
import heal from '../../../assets/images/heal-platform.svg'
import annete from '../../../assets/images/annete.png'
import { Link, useParams } from 'react-router-dom'
import { getApi } from '../../../Services/apiService'
import Modal from 'react-modal'
import AvailabilityModal from '../../AdminComponents/availabilityModal'
import BookInterviews from '../UserJoin/bookInterviews'
import BookConsultation from './bookConsultation'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import dummy from '../../../assets/images/profile3.svg'

function SingleDetailList() {

  const params = useParams();
  const id = params?.id
  const [singleData,setSingleData] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const [isModalLook,setIsModalLook] = useState(false);
  const [classState, setClassState] = useState(false)
  const [userData, setUserData] = useState([])
console.log("namenamenamename",singleData);
  

  useEffect(()=>{
    handleGetData()
  },[])

  const handleGetData = async() =>{
    const response = await getApi(`creators/info?userId=${id}`);
    if(response?.statusCode === 200){
      setSingleData(response?.data)
    } else{

    }
  }

  useEffect(() => {
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };
  
  return (
    <>
 <div class="home">
        <UserSidebar classState={classState} name={userData} />
        <div class="right-main">
      <section class="creators-top detail explore-details">
          <div class="row">
          <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> </h1>
            <div class="col-xl-2 col-lg-4 col-sm-4">
              <div class="creator-detail-left">
                <div class="creator-detail-img text-center">
                  <span><img src={singleData?.user_profile_image || dummy} alt=''/></span>
                  {/* <img src={annete} alt=''/> */}
                </div>
                <div class="mobile-view">
								<h1>{singleData?.name}</h1>
								<h2>{singleData?.specializations && singleData?.specializations.length>0 && singleData?.specializations[0]?.area_of_work}</h2>
								<h3>{singleData?.specializations && singleData?.specializations.length>0 && 'Experience: ' + singleData?.specializations[0]?.experience}</h3>
								{/* <h3>2 years of experience</h3> */}
							</div>
							<div class="labels">
								{singleData?.tags && singleData?.tags.length>0 && singleData?.tags.map((x,index)=>{
                  return(
                  <label key={index}>{x}</label>
                  )
                })}
							</div>
                <button class="discovery" onClick={()=>setIsModal(true)}>Discovery call <img src={discoveryCall} alt='' /></button>
                
                <div class="creator-soial">
                  <ul>
                    <li><Link to=''><img src={facebook} alt=''/></Link></li>
                    <li><Link to=''><img src={twitter} alt='' /></Link></li>
                    <li><Link to=''><img src={instagram} alt=''/></Link></li>
                  </ul>
                </div>
                <div class="associated">
                  <h4>Associated with</h4>
                  <ul>
                    <li><span><img src={yoga_hero} alt=''/></span>
                      <h4>“Yoga here studio”</h4>
                    </li>
                    <li><span><img src={heal} alt='' /></span>
                      <h4>“Heal platform”</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-10 col-lg-8 col-sm-8">
              <div class="creator-detail-right">
                <h3>{singleData?.name}</h3>
                <h4>{singleData?.specializations && singleData?.specializations.length>0 && singleData?.specializations[0]?.area_of_work}</h4>
                {singleData?.tags && singleData?.tags.length>0 && singleData?.tags.map((x,index)=>{
                  return(
                  <label key={index}>{x}</label>
                  )
                })}
                {/* <label>Art</label>
                <label>Yoga</label>
                <label>Meditation</label> */}

                <h5>About Me</h5>
                <h6>{singleData?.specializations && singleData?.specializations.length>0 && 'Experience: ' + singleData?.specializations[0]?.experience}</h6>
                {/* <h6>Experience: 2 years</h6> */}
                <p>
                  {/* Hello, aspiring yogis! I'm here to guide you on a transformative journey through the art
                  of yoga. With two years of experience, I bring a blend of expertise and passion to each
                  session, tailored to help you discover the profound benefits of yoga.<br/>
                    In my classes, you'll experience a fusion of movement, breathwork, and mindfulness,
                    designed to cultivate inner peace and vitality. Whether you're seeking stress relief,
                    increased flexibility, or overall wellness, my teachings aim to address your needs
                    holistically. From Hatha to Vinyasa, I offer a range of styles to suit every level
                    and... */}
                    {singleData?.about}
                    {/* <strong>Read less</strong> */}
                </p>
              </div>
            </div>
          </div>
      </section>

      <section class="offerings">
				<h3 class="offering">Offerings by Annete Black</h3>
				<div class="links mention-tags">
					<h4>Mention tags</h4>
					<ul>
						<li><a href="">Workshop</a> </li>
						<li><a href="">Meditation</a> </li>
						<li><a href="">Yoga</a> </li>
						<li><a href="">Fitness</a> </li>
						<li><a href="">Pilates</a> </li>


					</ul>
				</div>
        <ServiceOffering id={id}/>
        </section>

      {/* <Gallery/> */}
      <Modal
        isOpen={isModal}
        onRequestClose={() => setIsModal(false)}
        contentLabel="Login Modal"
        portalClassName="availability-modal  bookingconsultation"
        shouldCloseOnOverlayClick={true}
      >
  <BookConsultation id={id} setIsModal={setIsModal} setIsModalLook={setIsModalLook}/>
      </Modal>
      <Modal
        isOpen={isModalLook}
        onRequestClose={() => setIsModalLook(false)}
        contentLabel="Login Modal"
        portalClassName="availability-modal"
        shouldCloseOnOverlayClick={true}
      >
  <h1>Look Calendar</h1>
      </Modal>
      </div>
    </div>
    </>
  )
}

export default SingleDetailList