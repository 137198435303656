import React, { useState, useRef } from 'react';

function CaroselImage({ userDetails }) {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const totalSlides = userDetails.cover_image ? 1 + (userDetails.offerMaterials?.length || 0) : (userDetails.offerMaterials?.length || 0);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="carousel slide" ref={carouselRef}>
      <div className="carousel-inner">
        {userDetails.cover_image && (
          <div className={`carousel-item ${currentIndex === 0 ? 'active' : ''}`} key="cover-image">
            <img className="d-block w-100" src={userDetails.cover_image} alt="Cover Slide" />
          </div>
        )}
        {userDetails.offerMaterials && userDetails.offerMaterials.map((x, index) => (
          <div
            className={`carousel-item ${currentIndex === (userDetails.cover_image ? index + 1 : index) ? 'active' : ''}`}
            key={index + 1}
          >
            <img className="d-block w-100" src={x?.file_url} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Conditionally render Previous button */}
      {currentIndex > 0 && (
        <a className="carousel-control-prev" role="button" onClick={handlePrev}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        </a>
      )}

      {/* Conditionally render Next button */}
      {currentIndex < totalSlides - 1 && (
        <a className="carousel-control-next" role="button" onClick={handleNext}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
        </a>
      )}
    </div>
  );
}

export default CaroselImage