import React, { useState } from 'react'
import logo from '../../../assets/images/logo.svg'
import { insertUserData } from '../../../Counter/userSignUpSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { userRoles } from '../../../Validations/formValidation'
import Header from '../../CreatorComponents/CreatorJoin/header'

function WelcomeUserCreator({ nextStep, prevStep, data,admin}) {
  const [userRole, setUserRole] = useState('')
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    // const values = {
    //   user_role: userRole
    // }
    dispatch(insertUserData(values));
    nextStep()
  }

  const formState = useSelector((state) => state.userCounter);
  return (
    <div className="detail-page user-creator signup-cs">
      <Header/>
      {/* <header>
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="#">
            <img src={logo} />
          </a>
        </nav>
      </header> */}
      <div className="main">
        <div className="inner-content">
          <h1>Welcome {formState?.name}</h1>
          <p> {admin === 'admin'?'You can add the user as either a creator or a user Please select from the options below' : 'Join us as a Creator to share your creative ideas and skills, or join as a User to enjoy content and learn from others' }</p>
          <Formik
            initialValues={{ role: formState?.role || '' }}
            validationSchema={userRoles}
            onSubmit={(values) => {
              setUserRole(values.role);
              onSubmit(values);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className="top-button">
                  <label className="radio-check3">
                    <Field
                      type="radio"
                      name="role"
                      value="creator"
                      checked={values.role === 'creator'}
                      onChange={handleChange}
                    />
                    <span className="checkmark">{admin === 'admin'? 'Add': 'Join'} as Creator</span>
                  </label>
                  <label className="radio-check3">
                    <Field
                      type="radio"
                      name="role"
                      value="user"
                      checked={values.role === 'user'}
                      onChange={handleChange}
                    />
                    <span className="checkmark">{admin === 'admin'? 'Add': 'Join'} as User</span>
                  </label>
                </div>
                <ErrorMessage name="role" component="div" className="text-danger" />
                <button type="submit" className="continue">Continue</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="back-button">
        <button className="button" onClick={() => prevStep()}><img src={leftArrow} alt=''/> </button>
      </div>
    </div>
  )
}

export default WelcomeUserCreator