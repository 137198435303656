import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import LoginForm from '../userJoin/loginForm';
import LoginForm from './loginForm';
import { useNavigate } from 'react-router-dom';
import { postApi } from '../../../Services/apiService';
import Login from './login';
import WelcomeUserCreator from './welcomeUserCreator';
import UserIdentify from './userIdentify';
import OnBoarding from './onBoarding';
import Review from './review';
import DetailForm from './detailForm';
import AboutDetails from './aboutDetails';
import BookInterviews from './bookInterviews';
import CreatorSubmit from './creatorSubmit';
import { clearUserData } from '../../../Counter/userSignUpSlice';
import EmailVerify from '../../CreatorComponents/CreatorJoin/emailVerify';
import { requestNotificationPermission } from '../../FirebaseConfig/NotificationComponent'; // Import the function

const MultiStep = ({setUserModalIsOpen,userModalIsOpen}) => {
  const navigate =useNavigate()
  const dispatch = useDispatch()
  const [loader,setLoader] = useState(false)
  const [step, setStep] = useState(1);
  const formState = useSelector((state) => state.userCounter);
  const fromder = useSelector((state) => state)
  useEffect(() => {
    console.log('useSelector',fromder)
    console.log('Current Form State:', formState);
    console.log('step:', step);
  }, [formState,step]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const submitForm = async(val,values) => {
    setLoader(true)
    // setUserModalIsOpen(false)
    //   navigate('/')
    const fcmToken = await requestNotificationPermission();
   
    const insertData ={
      name:formState?.name,
      email:formState?.email,
      password:formState?.password,
      gender:formState?.gender,
      account_type:formState?.accountType,
      fcmtoken: fcmToken === 'not_granted' ? '' : fcmToken,
      user_role:formState?.role,
      link:formState?.link || '',
      interview_time:formState?.selectedTimes || values?.selectedTimes || '',
      interview_date:formState?.selectedDate || values?.selectedDate || '',
      area_of_work :formState?.workArea || '',
      experience :formState?.experience || '',
      categoryIds :formState?.selectedImage,
      slotId: formState?.slotId || values?.slotId,
      location : formState?.location
      
      
    }
    const response = await postApi('/register', insertData);
    console.log("response>>>>>>>",response)
    if(response?.statusCode === 200){
      // dispatch(clearUserData())
      localStorage.setItem("userDetail",JSON.stringify(response?.user_details))
      localStorage.setItem("token",JSON.stringify(response?.user_details?.token))
      localStorage.setItem('user_role',response?.data?.roles[0]?.user_role)
      setLoader(false)
      if(val === 'booking'){
        nextStep()
      }
      if(formState?.role === 'user'){
        if(val !== 'booking'){
          navigate('/user/home')
        }
      }
      if(formState?.role === 'creator'){
        if(val !=='booking'){
          navigate('/creator/home')
        }
      }
      if(val !=='booking'){

        setUserModalIsOpen(false)
      }
      // navigate('/')
    }
    else{
      setLoader(false)
    }
    // else{
    //   toast.error('something went wrogn');
    // }
    // fetch('http://13.232.134.145:8002/api/v1/register', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       // Add any additional headers if needed
    //     },
    //     body: JSON.stringify(insertData),
    //   })
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error('Network response was not ok');
    //       }
    //       return response.json();
    //     })
    //     .then(data => {
    //       // Handle the response data here
    //       console.log("Data>>>>>>>>>>>>>>>>>>>>>",data)
    //       // navigate('/')
    //       setUserModalIsOpen(false)
    //     })
    //     .catch(error => {
    //       // Handle errors here
    //       console.error('There was a problem with the fetch operation:', error);
    //     });
    // setUserModalIsOpen(false)
  };

  switch (step) {
    case 1:
      return <LoginForm nextStep={nextStep} setUserModalIsOpen={setUserModalIsOpen} />;
    case 2:
      return <Login nextStep={nextStep} prevStep={prevStep} setUserModalIsOpen={setUserModalIsOpen}/>;
    case 3:
      return <DetailForm nextStep={nextStep} prevStep={prevStep} data={formState} />;
    case 4:
      return <WelcomeUserCreator nextStep={nextStep} prevStep={prevStep} data={formState}/>;
    case 5:
      switch(formState?.role){
        case 'creator':
          return <AboutDetails nextStep={nextStep} prevStep={prevStep} data={formState} />
        case 'user':
          return <UserIdentify nextStep={nextStep} prevStep={prevStep} data={formState} />
        default:
          return <div>Unkown user_role</div>
      }
      
    case 6:
      // return <OnBoarding nextStep={nextStep} prevStep={prevStep} data={formState} />;
      switch(formState?.role){
        case 'creator':
          return <BookInterviews nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={submitForm} loader={loader}/>
        case 'user':
          return <OnBoarding nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={submitForm}/>;
        default:
          return <div>Unkown user_role</div>
      }
      case 7:
        switch(formState?.role){
          case 'creator':
            return <EmailVerify nextStep={nextStep} prevStep={prevStep} data={formState} loader={loader} />;
          case 'user':
            return <Review nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={submitForm} loader={loader}/>;
          default:
            return <div>Unkown user_role</div>
        }
        // return <EmailVerify nextStep={nextStep} prevStep={prevStep} data={formState} loader={loader} />;
    case 8:
      // return <Review nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={submitForm} />;
      switch(formState?.role){
        case 'creator':
          return <CreatorSubmit nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={submitForm} loader={loader}/>
        case 'user':
          return <EmailVerify nextStep={nextStep} prevStep={prevStep} data={formState} loader={loader} />;
        default:
          return <div>Unkown user_role</div>
      }
    default:
      return <div>Unknown step</div>;
  }
  // switch (step) {
  //   case 1:
  //     return <LoginForm nextStep={nextStep}  />;
  //   case 2:
  //     return <Login nextStep={nextStep} prevStep={prevStep} />;
  //   case 3:
  //     return <DetailForm nextStep={nextStep} prevStep={prevStep} data={formState} />;
  //   case 4:
  //     return <WelcomeUserCreator nextStep={nextStep} prevStep={prevStep} data={formState}/>;
  //   case 5:
  //     return <UserIdentify nextStep={nextStep} prevStep={prevStep} data={formState} />;
  //   case 6:
  //     return <OnBoarding nextStep={nextStep} prevStep={prevStep} data={formState} />;
  //   case 7:
  //     return <Review nextStep={nextStep} prevStep={prevStep} data={formState} submitForm={submitForm} />;
  //   default:
  //     return <div>Unknown step</div>;
  // }
};

export default MultiStep;
