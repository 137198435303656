import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './Counter/signupSlice'
import usercounterReducer from './Counter/userSignUpSlice'
import offercounterReducer from './Counter/offeringSLice'
import productCounter from './Counter/productSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    userCounter:usercounterReducer,
    offerCounter:offercounterReducer,
    productCounter:productCounter,
  },
})