import React from 'react'
import Modal from 'react-modal';
import cross from '../../../assets/images/gray-close5.svg'
import timeslot from '../../../assets/images/stress-reduction1.svg'
import { useNavigate } from 'react-router-dom';

function DraftModal({setIsModal,isModal,steps}) {
  const navigate = useNavigate()
  return (
    <Modal
                  isOpen={isModal}
                  onRequestClose={() => {steps === 'final' ?navigate('/creator/offering/home'):setIsModal(false)}}
                  contentLabel="Delete Modal"
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  overlayClassName={`modal-overlay  verification-modal offiering-modals-change time-avaliable timeslot save-draft`} >
                  <button className='close-button ' onClick={() => {steps === 'final' ?navigate('/creator/offering/home'):setIsModal(false)}}><img src={cross} alt='' /></button>
                  <h1>Saved to Drafts</h1>
                  <span>You can edit and publish your post any time.</span>
                  <span class="d-block text-center"> <img src={timeslot} /></span>
                  <p></p>
                </Modal>
  )
}

export default DraftModal