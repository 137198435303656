import React from 'react';
import LogoBlack from '../assets/images/Logo_black.svg'
import instagram from '../assets/images/instagram.svg'
import facebook from '../assets/images/facebook.svg'
import twitter from '../assets/images/twitter.svg'
import youtube from '../assets/images/youtube.svg'
import Linkdin from '../assets/images/linkedin.svg'
import logoWhite from '../assets/images/microcosm-white.svg'

const Footer = () => {
    return (
        // <footer style={{ backgroundColor: '#BDCDC7', color: 'white', padding: '20px', textAlign: 'center' }}>
        //     <p style={{ margin: 0 }}>© 2024 My Website. All rights reserved.</p>
        // </footer>
        <>
            <div class="footer">
			<div class="container">
				<div class="row">
					<div class="col-sm-7">
						<span><img alt='' src={logoWhite}/> </span>
					</div>
					<div class="col-sm-5">
						<div class="row">
							<div class="col-sm-6">
								<div class="footer-social">
								<ul>
									<h3>Overview</h3>
									<li><a href="">About Us</a> </li>
									<li><a href="">Offerings</a> </li>
									<li><a href="">Our Creators</a> </li>
									<li><a href="">Become a Creator</a></li>
									<li><a href="">Contact Us</a> </li>
								</ul>
							</div>
						  </div>
							<div class="col-sm-6">
								<div class="footer-social">
								<ul>
									<h3>Follow Us</h3>
									<li><img alt='' src={instagram}/><a href="">Instagram</a> </li>
									<li><img alt='' src={facebook}/><a href="">Facebook</a> </li>
									<li><img alt='' src={Linkdin}/><a href=""> LinkedIn</a> </li>
									<li><img alt='' src={twitter}/><a href="">Twitter</a> </li>
									<li><img alt='' src={youtube}/><a href="">YouTube </a></li>
								</ul>
							</div>
							</div>
						</div>
					</div>
				</div>
				<div class="footer-bottom">
					<p>Copyright © 2024 microcosm | All Rights Reserved </p>
				</div>

			</div>
		</div>
        </>
    );
}

export default Footer;