import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../../Layout/userSidebar';
import burger from '../../../../assets/images/burger-menu.svg'
import ActiveLinks from '../activeLinks';
import leftArrow from '../../../../assets/images/backarrow.svg'
import { useNavigate } from 'react-router-dom';
import cardJewellery from '../../../../assets/images/card jwellery.png'
import share from '../../../../assets/images/share.svg'
import heart from '../../../../assets/images/heart.svg'
import location from '../../../../assets/images/location1.svg'
import doubleBox from '../../../../assets/images/double-box.svg'
import marley from '../../../../assets/images/annete.png'
import whiteArrow from '../../../../assets/images/white-arrow.svg'
import LiveModal from '../liveModal';
import PublishModal from '../publishModal';
import { insertProductData } from '../../../../Counter/productSlice';
import Modal from 'react-modal';
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import cross from '../../../../assets/images/cross-button.svg'
import dummyImage from '../../../../assets/images/profile3.svg'


function ProductDetailOffering({ nextStep, prevStep, step, data, submitForm, setDraftData,type }) {
  console.log("data in product details", data);
  const user_role = localStorage.getItem("user_role")
  const [classState, setClassState] = useState(false);
  const [readyPublish, setReadyPublish] = useState(false)
  const [offeringLive, setOfferingLive] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const creatorDetail = JSON.parse(localStorage.getItem('full_user_detail'));
  const [loader, setLoader] = useState(false)

  const navigate = useNavigate()
  console.log("step", step);

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };
  const dispatch = useDispatch()

  const openModal = (src) => {
    setModalContent(src);
    setModalIsOpen(true);
  };

  useEffect(() => {
    // fetchData(searchQuery, selectedFilters); // Initial f../etch
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  // Ensure description is defined and is a string
  const description = data?.description || '';
  const isLongDescription = description?.length > 300;
  const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;


  const handleSave = (type) => {

    const values = {



    }
    dispatch(insertProductData(values))
    if (type === 'draft') {
      setLoader(true)
      submitForm('draft', values)
      setTimeout(() => {
        setLoader(false)
      }, 500);    }
    else {
      setReadyPublish(true);
    }
  }

  const closeModals = (type) => {

    if (type === 'ready') {
      setReadyPublish(false)
    }
    if (type === 'live') {
      setOfferingLive(false)
    }
  }
  const handlePublish = (type) => {
    if (type === 'ready') {
      setReadyPublish(true)
    }
    if (type === 'live') {
      setReadyPublish(false)
      setOfferingLive(true)
    }
  }
  const closeImgModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };

  return (
    <>
      <UserSidebar classState={classState} />
      <div className='right-main product product--diff-structure'>
        <div class="session">
          {type !== 'edit' && 
          <>
          <div class="header">
            <h1> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Product </h1>
            <div class="top-buttons">
              <button class="save-draft" onClick={() => handleSave('draft')} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save to Draft</button>
              {/* <Link to='/creator/offering/availability'> */}
              <button class="publish-btn-cs" onClick={handleSave}>Publish </button>
              {/* </Link> */}
            </div>
          </div>
          <div class="active-links">
            <button className="back" onClick={() => { setDraftData(true); prevStep() }}><img src={leftArrow} alt='' /> </button>
            <ActiveLinks step={step + 1} type="product" productType={data?.productType} />
          </div>
          </>}
          <div class="main">
            <div class="row">
              <div class="col-sm-12  col-lg-12 col-xl-7">
                <div class="left">
                  <h1>{data?.title}</h1>
                </div>
                <div class="choose-tags mb-1 mt-2">
                  <ul>
                    {data && data?.tagName?.length > 0 && data.tagName.map((tag, index) => (
                      <li key={tag.id || index} className="custom-checkbox">
                        <input disabled
                          type="checkbox"
                          id={`option${index}`}
                        // You might want to manage checked state if needed
                        />
                        <label htmlFor={`option${index}`}>
                          {/* Handle different possible tag name formats */}
                          {tag.tag_name || tag.name || tag}
                        </label>
                      </li>
                    ))}
                  </ul>

                </div>
                <div class="row">
                  <div class="col-lg-10  col-sm-10">
                    <div class="main-image">
                      <img src={data?.showImagePreview ? data.showImagePreview : cardJewellery} alt='' class="img-fluid " />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-2">
                    <div className={
                      ((data?.additionalImages?.length || 0) + (data?.offerMaterials?.length || 0)) <= 3
                        ? 'jewellery-img-height'
                        : 'jewellery-img-height image'
                    }>                      
                    <div className="row">
                        {/* Check for additionalImages first, if not found, check offerMaterials */}
                        {data?.additionalImages?.length > 0 ? (
                          data.additionalImages.map((image, index) => {
                            const imageUrl = image.file_url || image; // Check if image is an object or a URL string
                            return (
                              <div className="col-6 col-lg-12 col-sm-12" key={index}>
                                <div className="jewellery-img">
                                  <img src={imageUrl} alt={`additional-image-${index}`} className="img-fluid" />
                                </div>
                              </div>
                            );
                          })
                        ) : data?.offerMaterials?.length > 0 ? (
                          data.offerMaterials.map((material, index) => (
                            material.media_type === 'image' && (
                              <div className="col-6 col-lg-12 col-sm-12 mb-3" key={index}>
                                <div className="jewellery-img">
                                  <img src={material.file_url} alt={`offer-material-image-${index}`} className="img-fluid" />
                                </div>
                              </div>
                            )
                          ))
                        ) : (
                          null
                        )}
                      </div>
                    </div>
                  </div>

                </div>
                {/* <div class="col-sm-12 col-lg-12 col-xl-9">
                  <div className='creator-detail-right creator-product'>
                    <h2 className='fw-bold'> About product</h2>
                    <p>
                      {isExpanded || !isLongDescription ? description : truncatedDescription}
                      <br />
                      {isLongDescription && (
                        <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                          {isExpanded ? 'Read less' : 'Read more'}
                        </strong>
                      )}
                    </p>
                  </div>
                
                <div className='row align-item-center'>
                  <div className='col-sm-5 col-lg-5 col-xl-3'>
                    <span className='profile-pic'>
                    <img  src={creatorDetail?.user_profile_image || dummyImage} alt='' />
                    </span>
                  </div>
                  <div className='col-sm-7 col-lg-7 col-xl-9 '>
                    <div className='creator-detail-right creator-profiles'>
                      <h2 className='fw-bold'>{creatorDetail?.name || ''}</h2>
                      {creatorDetail?.specializations.length >0 && (
                        <h4>{creatorDetail?.specializations[0]?.area_of_work || ''}</h4>
                      )}
                      {creatorDetail?.specializations.length >0 && (
                      <p className='d-block'>{creatorDetail?.specializations[0]?.experience || ''} of experience</p>
                      )}
                      <button class="discovery">Discovery call<img src={whiteArrow} alt='' class="px-1" /></button>
                    </div>
                  </div>
                </div>
                </div> */}
              </div>
              <div class="col-sm-12  col-lg-12 col-xl-5">
                <div class="share-product">
                  <div class="row profuct-head d-flex justify-content-between  ">
                    <div className='col-md-8'>
                    <h2>{data?.title}</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                    <div class="">
                      <img src={share} alt='' class="pe-3" />
                      <img src={heart} alt='' />
                      </div>
                      </div>
                  
                  </div>
                  {data?.productType === 'physical' && (
                  <div class="product-jwelley">
                    <p class="py-2" ><img src={doubleBox} alt='' class="pe-2" />{data?.availability==='ready' ? data?.quantityOrders :data?.Maxquantity} available items</p>
                    <p className="py-2">
                      <img src={location} alt="" className="pe-2" />
                      {data?.selectedOption === 'Pick up' ?  'Pick up' && `Pick up in ${data?.city}` : ''}
                      {data?.selectedOption === 'Delivery' ? 'Delivery' && `Delivery in ${data?.city}` : ''}
                      {data?.selectedOption ==='Both' ? 'both' && `Pick up and Deliver in ${data?.city}` : ''}
                    </p>
                  </div>
                  )}
                  <div class="border-grays py-2"></div>
                  <div class="row align-items-center">
                    <div class="col-md-6 col-xl-6 col-sm-12">
                      <p class="d-flex">Price <span className='fw-bold px-2'>${data?.discoveryPrice === 'variable_price' ? (data?.discoverMinPrice +  '-' + data?.discoverManPrice) : data?.discoverMinPrice}</span></p>
                    </div>
                    <div class="col-md-6 col-xl-6 text-end col-sm-12 text-end">
                      <button class="cutom-invoice">Custom invoice</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-6">
                  <div className='creator-detail-right creator-product'>
                    <h2 className='fw-bold'> About product</h2>
                    <p>
                      {isExpanded || !isLongDescription ? description : truncatedDescription}
                      <br />
                      {isLongDescription && (
                        <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                          {isExpanded ? 'Read less' : 'Read more'}
                        </strong>
                      )}
                    </p>
                  </div>
                
                <div className='row align-item-center'>
                  <div className='col-sm-5 col-lg-5 col-xl-3'>
                    <span className='profile-pic'>
                    <img  src={creatorDetail?.user_profile_image || dummyImage} alt='' />
                    </span>
                  </div>
                  <div className='col-sm-7 col-lg-7 col-xl-6'>
                    <div className='creator-detail-right creator-profiles'>
                      <h2 className='fw-bold'>{creatorDetail?.name || ''}</h2>
                      {creatorDetail?.specializations.length >0 && (
                        <h4>{creatorDetail?.specializations[0]?.area_of_work || ''}</h4>
                      )}
                      {creatorDetail?.specializations.length >0 && (
                      <p className='d-block'>{creatorDetail?.specializations[0]?.experience || ''} of experience</p>
                      )}
                      <button class="discovery">Discovery call<img src={whiteArrow} alt='' class="px-1" /></button>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={readyPublish} onRequestClose={() => closeModals('ready')} contentLabel="Publish Modal" shouldCloseOnOverlayClick={false}
        portalClassName="creatorLiveModal">
        <PublishModal submitForm={submitForm} handlePublish={handlePublish} closeModal={closeModals} type='product' />
      </Modal>
      <Modal isOpen={offeringLive} onRequestClose={() => closeModals('live')} contentLabel="Publish Modal" shouldCloseOnOverlayClick={false}
        portalClassName="creatorPublishModal">
        <LiveModal submitForm={submitForm} data={data} closeModal={closeModals} type='product' adminType={user_role} />
      </Modal>
      {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}><img src={cross} alt='' /></button>

                    {modalContent && (modalContent.endsWith('.png') || modalContent.endsWith('.jpg') || modalContent.endsWith('.jpeg') || modalContent.endsWith('.svg')) ? (
                        <img src={modalContent} alt="Modal Content"/>
                    ) : modalContent.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={modalContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </Modal> */}
    </>
  );
}
export default ProductDetailOffering
