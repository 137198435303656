import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NewOffering from '../../CreatorComponents/CreatorOffering/newOffering';
import DefineOffering from '../../CreatorComponents/CreatorOffering/defineOffering';
import AvailabilityOffering from '../../CreatorComponents/CreatorOffering/availabilityOffering';
import SessionSessionHybrid from '../../CreatorComponents/CreatorOffering/sessionSessionHybrid';
import SessionDiscovery from '../../CreatorComponents/CreatorOffering/sessionDiscovery';
import { postApi } from '../../../Services/apiService';
import OfferingViewData from '../../CreatorComponents/CreatorOffering/offeringViewData';
import { clearOfferingData, insertOfferingData } from '../../../Counter/offeringSLice';
import AvailabilityOfferingGroup from '../../CreatorComponents/CreatorOffering/availabilityOfferingGroup';


function AdminOfferingStep() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [switchType, setSwitchType] = useState('')
  const [step, setStep] = useState(1);
  const [draftData,setDraftData] = useState(false)
  const formState = useSelector((state) => state.offerCounter);

  const craetorId = formState?.creator_id;
  console.log("craetorIdcraetorId",craetorId)

  const Stype = formState?.classMode;
  console.log("firststepstepstepstepstepstep",step);
  const sessionTypesData = formState?.sessionTypes;

  useEffect(() => {
    // console.log('useSelector',fromder)
    console.log('Current Form State:', formState);
    console.log('step:', step);
  }, [formState, step, switchType]);
  const nextStep = (val, type,stepValue) => {
    console.log("valvalvalval",val)
    console.log("typetypetypetype",type)
    
    if (val === 'types') {
      setSwitchType(type)
      setStep(step)
    }
    else {
      if(stepValue !==null && stepValue !== undefined){
        setDraftData(true);
        setStep(stepValue)
      }
      else{
        setStep(step + 1);
      }
    }
  };
  const prevStep = (val) => {
      setStep(step - 1);
  };
  
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const submitForm = async (offerStatus,valu) => {
    // setTimeout(async () => {
      await delay(1000); 
      let locationType;
      if (formState?.classMode === 'online' || valu?.classMode === 'online') {
        locationType = 1;
      } else if (formState?.classMode === 'in_person' || valu?.classMode === 'in_person') {
        locationType = 2;
      } else if (formState?.classMode === 'hybrid' || valu?.classMode === 'hybrid') {
        locationType = 3;
      }
      let session_offer_type;
      if(formState?.sessionType === 'single_session' || valu?.sessionType === 'single_session'){
        session_offer_type = 1;
      } else if(formState?.sessionType === 'multiple_session' || valu?.sessionType === 'multiple_session'){
        session_offer_type =2;
      }
      let session_type;
      if(formState?.sessionTypes === 'private_session' || valu?.sessionTypes === 'private_session'){
        session_type = 1;
      } else if(formState?.sessionTypes === 'group_session' || valu?.sessionTypes === 'group_session'){
        session_type =2;
      }
      let time_type;
      if(formState?.specifyTime === 'exact' || valu?.specifyTime === 'exact'){
        time_type = 1;
      } else if(formState?.specifyTime === 'availability' || valu?.specifyTime === 'availability'){
        time_type =2;
      }
      let package_type;
      if(formState?.sessionMultipleType === 'weekly' || valu?.sessionMultipleType === 'weekly'){
        package_type = 1;
      } else if(formState?.sessionMultipleType === 'monthly' || valu?.sessionMultipleType === 'monthly'){
        package_type =2;
      }
      else if(formState?.sessionMultipleType === 'custom' || valu?.sessionMultipleType === 'custom'){
        package_type =3;
      }
      else if(formState?.sessionMultipleType === 'daily' || valu?.sessionMultipleType === 'daily'){
        package_type =4;
      }
      let session_repeat;
      if(formState?.sessionRepeat === 'one' || valu?.sessionRepeat === 'one'){
        session_repeat = 1;
      } else if(formState?.sessionRepeat === 'daily' || valu?.sessionRepeat === 'daily'){
        session_repeat =2;
      }
      else if(formState?.sessionRepeat === 'weekly' || valu?.sessionRepeat === 'weekly'){
        session_repeat =3;
      }
      else if(formState?.sessionRepeat === 'monthly' || valu?.sessionRepeat === 'monthly'){
        session_repeat =4;
      }
      else if(formState?.sessionRepeat === 'weekday' || valu?.sessionRepeat === 'weekday'){
        session_repeat =5;
      }
      else if(formState?.sessionRepeat === 'weekend' || valu?.sessionRepeat === 'weekend'){
        session_repeat =6;
      }
    
      let pricing_type;
      if(formState?.discoveryPrice === 'fixed_price' || valu?.discoveryPrice === 'fixed_price'){
        pricing_type = 1;
      } else if(formState?.discoveryPrice === 'variable_price' || valu?.discoveryPrice === 'variable_price'){
        pricing_type =2;
      }

    let formData = new FormData()
    console.log('formData>>>>>>>>>>>>>>>.',formState)
    formData.append('name', formState?.name || valu?.name);
    formData.append('description', formState?.description || valu?.description);
    formData.append('category_id', formState?.categoryId || valu?.categoryId);
    formData.append('offer_tag', formState?.tags || valu?.tags);
    formData.append('offer_type', 'b80291e6-708b-4d62-81f5-3529da84a241');
    formData.append('address_id', formState?.address || valu?.address);
    formData.append('location_type', locationType);
    formData.append('invitation_link', formState?.invitationLink || valu?.invitationLink);
    formData.append('session_occurrences', JSON.stringify(formState?.recurrences) || JSON.stringify(valu?.recurrences) || JSON.stringify(formState?.recurrencess));
    formData.append('session_type', session_type);
    formData.append('min_group_size', formState?.minGroupSize || valu?.minGroupSize || 0);
    formData.append('max_group_size', formState?.maxGroupSize || valu?.maxGroupSize);
    formData.append('pricing_type', pricing_type);
    formData.append('price', formState?.discoveryMinPrice || valu?.discoveryMinPrice);
    formData.append('minimum_price', formState?.discoveryMinPrice || valu?.discoveryMinPrice);
    formData.append('maximum_price', formState?.discoveryMaxPrice || valu?.discoveryMaxPrice);
    formData.append('session_offer_type', session_offer_type);
    formData.append('time_type', 2);
    formData.append('creator_id', formState?.creator_id || valu?.creator_id || '');
    formData.append('package_type', package_type);
    formData.append('session_repeat', session_repeat);
    formData.append('cover_image', formState?.coverImage || valu?.coverImage);
    formData.append('stepvalue', (step-1));
    formData.append('travel_area', formState?.travelArea || valu?.travelArea);
    formData.append('travel_charge_fee', formState?.chargeTravel || valu?.chargeTravel);
    formData.append('in_person_option', 3);
    formData.append('location', formState?.locationInput || valu?.locationInput);
    formData.append('message_to_user', formState?.messageUser || valu?.messageUser);
    // formData.append('materials', formState?.additionalImagesPreview || valu?.additionalImagesPreview)
    formData.append('offer_status', offerStatus)
    formData.append('no_of_sessions', Number(formState?.sessionPackage) || Number(valu?.sessionPackage) || Number(formState?.no_of_sessions) || 0)
    formData.append('package_timeframe', formState?.timeFrame?.number || valu?.timeFrame?.number || formState?.package_timeframe)
    formData.append('package_period', formState?.timeFrame?.months || valu?.timeFrame?.months || formState?.package_period)
    formData.append('title', formState?.title || valu?.title)
    // formData.append('offer_type', formState?.type || valu?.type)
    const additionalImages = formState?.additionalImagesPreview || valu?.additionalImagesPreview;
if (Array.isArray(additionalImages)) {
    additionalImages.forEach((image, index) => {
        if (image instanceof File || image instanceof Blob) {
            formData.append(`materials`, image);
            // formData.append(`materials${index}`, image);
        }
    });
}
    console.log("ApiData>>>>>>>>>>>>>>>>>>>>>", formData)

    let response ;
    if(offerStatus === 'draft'){
      formData.append('id',formState?.id)
      response = await postApi('/creator/draft', formData);
      if(response?.statusCode === 200){
        const values = {
          id : response?.data?.id
        }
        dispatch(insertOfferingData(values))
        console.log("draft saved successfully")
        return true;
      } else{
        return false
      }
    }
    else{
      response = await postApi('/creator/Service', formData);
      console.log("response>>>>>>>", response)
      if(response?.statusCode === 200){
        dispatch(clearOfferingData())
        return true;
      } else{
        return false;
      }
    }
  };

  switch (step) {
    case 1:
      return <NewOffering nextStep={nextStep} formState={formState} draftData={draftData} craetorId={craetorId}/>;
    case 2:
      return <DefineOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} setDraftData={setDraftData}/>;
    case 3:
      if (sessionTypesData) {
        switch (sessionTypesData) {
          case 'private_session':
            return <AvailabilityOffering nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm}/>;
          case 'group_session':
            return <AvailabilityOfferingGroup nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm}/>;
          default:
            return <div>Unknown service type</div>;
        }
      }
    case 4:  
      return <SessionSessionHybrid nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm}/>;
    case 5:
        return <SessionDiscovery nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} adminType={'admin'}/>;
    case 6:
        return <OfferingViewData nextStep={nextStep} prevStep={prevStep} step={step} data={formState} submitForm={submitForm} adminType={'admin'}/>
    default:
      return <div>Unknown step</div>;
  }
};

export default AdminOfferingStep