import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postApi } from '../../Services/apiService';
import WelcomeUserCreator from '../UserComponents/UserJoin/welcomeUserCreator';
import UserIdentify from '../UserComponents/UserJoin/userIdentify';
import OnBoarding from '../UserComponents/UserJoin/onBoarding';
import Review from '../UserComponents/UserJoin/review';
import DetailForm from '../UserComponents/UserJoin/detailForm';
import AboutDetails from '../UserComponents/UserJoin/aboutDetails';
import BookInterviews from '../UserComponents/UserJoin/bookInterviews';
import CreatorSubmit from '../UserComponents/UserJoin/creatorSubmit';
import { clearUserData } from '../../Counter/userSignUpSlice';
import { requestNotificationPermission } from '../FirebaseConfig/NotificationComponent'; // Import the function

const AdminMultiStep = ({ setUserModalIsOpen, userModalIsOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(1);
  const formState = useSelector((state) => state.userCounter);

  useEffect(() => {
    console.log('Current Form State:', formState);
    console.log('step:', step);
  }, [formState, step]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const submitForm = async () => {
    setLoader(true);
    // Call the notification function and await the token
 const fcmToken = await requestNotificationPermission();

    const insertData = {
      name: formState?.name,
      email: formState?.email,
      password: formState?.password,
      gender: formState?.gender,
      account_type: formState?.accountType,
      user_role: formState?.role,
      link: formState?.link || '',
      interview_time: formState?.selectedTimes || '',
      interview_date: formState?.selectedDate || '',
      area_of_work: formState?.workArea || '',
      experience: formState?.experience || '',
      categoryIds: formState?.selectedImage,
      createdBy: 'admin',
      fcmtoken:fcmToken === 'not_granted' ? '' : fcmToken,
    };
    const response = await postApi('/register', insertData);
    console.log("response>>>>>>>", response);
    if (response?.statusCode === 200) {
      dispatch(clearUserData());
      setLoader(false);
      setUserModalIsOpen(false);
      onClose(); // Call the onClose callback
    } else {
      setLoader(false);
    }
  };

  switch (step) {
    case 1:
      return <DetailForm nextStep={nextStep} prevStep={prevStep} data={formState} admin='admin' />;
    case 2:
      return <WelcomeUserCreator nextStep={nextStep} prevStep={prevStep} data={formState} admin='admin' />;
    case 3:
      switch (formState?.role) {
        case 'creator':
          return <AboutDetails nextStep={nextStep} prevStep={prevStep} data={formState} admin='admin' />;
        case 'user':
          return <UserIdentify nextStep={nextStep} prevStep={prevStep} data={formState} admin='admin' />;
        default:
          return <div>Unknown user_role</div>;
      }
    case 4:
      switch (formState?.role) {
        case 'creator':
          return <CreatorSubmit nextStep={nextStep} prevStep={prevStep} data={formState} admin='admin' submitForm={submitForm} loader={loader} />;
        case 'user':
          return <CreatorSubmit nextStep={nextStep} prevStep={prevStep} data={formState} admin='admin' role='user' submitForm={submitForm} loader={loader} />;
        default:
          return <div>Unknown user_role</div>;
      }
    default:
      return <div>Unknown step</div>;
  }
};

export default AdminMultiStep;
