import axiosInstance from "../Middleware/interceptor";
import { toast } from "react-toastify";

export const postApi = async (url, data) => {
  try {
    const response = await axiosInstance.post(`${url}`, data);
    if(response?.data?.statusCode === 200){
    toast.success(response?.data?.message)
    } else{
      toast.error(response?.data?.message)
    }

    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getApi = async (url) => {
  try {
    const response = await axiosInstance.get(`${url}`);
    if(response?.data?.statusCode === 200){
      // toast.success(response?.data?.message)
      } else{
        toast.error(response?.data?.message)
      }
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const putApi = async (url, data) => {
  try {
    const response = await axiosInstance.put(`${url}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteApi = async (url, data) => {
  try {
    const response = await axiosInstance.delete(`${url}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};