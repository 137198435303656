import React, { useEffect, useRef, useState } from 'react';
import bgtrans from '../../assets/images/bg-trans.svg'


function TermsAndPolicy({ setTerms, closeTermsIsOpen }) {
    const contactUsRef = useRef(null);
    const [isContactInView, setIsContactInView] = useState(false);


    useEffect(() => {
        const observerCallback = (entries) => {
            const [entry] = entries;
            setIsContactInView(entry.isIntersecting);
        };

        const observer = new IntersectionObserver(observerCallback, {
            root: null,
            threshold: 0.1
        });

        if (contactUsRef.current) {
            observer.observe(contactUsRef.current);
        }

        return () => {
            if (contactUsRef.current) {
                observer.unobserve(contactUsRef.current);
            }
        };
    }, []);


    return (
        <>
            <div className='position-relative'>
                <h1>Terms of Use and Privacy Policy for Microcosm</h1>
                <div className='intro-section'>
                    <h6>Introduction:</h6>
                    <ul>
                        <li>This Privacy Policy outlines how we, Microcosm, collect, use, and protect the personal information of our users who engage in creating offers and selling digital and physical products on our platform</li>
                        <li>By using our services, you agree to the collection and use of information in accordance with this policy</li>
                        <li>You can update your personal information or withdraw consent at any time by contacting us.</li>
                    </ul>
                    {/* --------------------- */}
                    <h6>Information We Collect:</h6>
                    <p>When you create an offer (services, workshops, classes, training, etc.) or list a product for sale, we collect the following information:</p>
                    <ul>
                        <li>Personal details (name, email, contact information)</li>
                        <li>Offer details (description, pricing, availability)</li>
                        <li>Product details (description, pricing, inventory)</li>
                        <li>Payment information</li>
                        <li>Usage data (IP address, browser type, device information).</li>
                    </ul>
                    {/* --------------------- */}
                    <h6>How We Use Your Information:</h6>
                    <p>We use the collected information for the following purposes:</p>
                    <ul>
                        <li>To facilitate the creation and management of your offers and products</li>
                        <li>To process transactions and payments</li>
                        <li>To communicate with you regarding your offers or sales</li>
                        <li>To improve and personalize the user experience on the platform</li>
                        <li>To comply with legal obligations.</li>
                    </ul>
                    {/* --------------------- */}
                    <h6>Sharing of Information:</h6>
                    <p className='mb-3'>We do not share or sell your personal information with third parties, except when required for processing payments or as mandated by law.</p>
                    {/* --------------------- */}
                    <h6>User Consent:</h6>
                    <ul>
                        <li>By creating an offer or listing a product for sale, you consent to the collection and use of your information as described in this Privacy Policy. </li>
                        <li>You also acknowledge that you are responsible for ensuring that the information you provide is accurate and up-to-date.</li>
                    </ul>
                    {/* --------------------- */}
                    <h6>Data Security:</h6>
                    <p className='mb-3'>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, or disclosure.</p>

                    {/* --------------------- */}
                    <h6>Your Rights:</h6>
                    <p className='mb-3'>You have the right to access, correct, or delete your personal information at any time. To exercise your rights, please contact us at microcosm@gmail.com</p>

                    {/* --------------------- */}
                    <h6>Changes to this Policy:</h6>
                    <p className='mb-3'>We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page, and we will notify you via email or through the platform.</p>
                    {/* --------------------- */}
                    <h6 ref={contactUsRef}>Contact Us:</h6>
                    <p className='mb-3'>If you have any questions or concerns about this Privacy Policy, please contact us at <b>microcosm@gmail.com.</b></p>
                    <p>By using our web application, you agree to the terms outlined in this Privacy Policy</p>
                    <div className={`bg-trans ${isContactInView ? 'hide-bg' : ''}`}>
                        <img src={bgtrans} alt='img-trans' />
                        </div>
                </div>
        
                <div className='acept-terms'>
                    <button className='btn accept-btns w-100' onClick={() => { setTerms(true); closeTermsIsOpen() }}>Accept</button>
                </div>

            </div>
        </>
    )
}
export default TermsAndPolicy;
