import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignUpDetail from './signUpDetail';
import AboutDetail from './aboutDetail';
import BookInterview from './bookInterview';
import SubmitForm from './submitForm';
import { postApi } from '../../../Services/apiService';
import Login from './login';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoginForm from '../../UserComponents/UserJoin/loginForm';
import { clearData } from '../../../Counter/signupSlice'
import EmailVerify from './emailVerify';
import { requestNotificationPermission } from '../../FirebaseConfig/NotificationComponent'; // Import the function

const MultiStep = ({ setCreatorModalIsOpen, creatorModalIsOpen }) => {
  const navigate = useNavigate()
  const [step, setStep] = useState(1);
  const [loader,setLoader] = useState(false)
  const formState = useSelector((state) => state.counter);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Current Form State:', formState);
    console.log('step:', step);
  }, [formState]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const submitForm = async (val,formValue) => {
    setLoader(true)
    // setCreatorModalIsOpen(false)
    // navigate('/')
    const fcmToken = await requestNotificationPermission();
   
    
    const insertData ={
      name:formState?.name,
      fcmtoken:fcmToken === 'not_granted' ? '' : fcmToken,
      email:formState?.email,
      password:formState?.password,
      gender:formState?.gender,
      account_type:formState?.accountType,
      user_role:'creator',
      // about:formState?.name,
      link:formState?.link,
      interview_time:formState?.selectedTimes || formValue?.selectedTimes,
      interview_date:formState?.selectedDate || formValue?.selectedDate,
      account_type:formState?.account_type,
      area_of_work :formState?.workArea || '',
      experience :formState?.experience || '',
      slotId: formState?.slotId || formValue?.slotId,
      location : formState?.location
      
    }
    const response = await postApi('/register', insertData);
    console.log("response>>>>>>>",response)
    if(response?.statusCode === 200){
      localStorage.setItem("userDetail",JSON.stringify(response?.user_details))
      localStorage.setItem("token",JSON.stringify(response?.user_details?.token))
      localStorage.setItem('user_role',response?.data?.roles[0]?.user_role)
      setLoader(false)
      if(val === 'booking'){
        nextStep()
      }
      // navigate('/creator/home')
      // setCreatorModalIsOpen(false)
    }
    else{
      setLoader(false)
    }
    // else{
    //   toast.error('something went wrogn');
    // }
    // fetch('http://localhost:3001/api/v1/register', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     // Add any additional headers if needed
    //   },
    //   body: JSON.stringify(formState),
    // })
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     return response.json();
    //   })
    //   .then(data => {
    //     // Handle the response data here
    //     console.log("Data",data)
    //     navigate('/')
    //   })
    //   .catch(error => {
    //     // Handle errors here
    //     console.error('There was a problem with the fetch operation:', error);
    //   });
  };

  switch (step) {
    case 0:
      return <LoginForm nextStep={nextStep} />;
    case 1:
      return <Login nextStep={nextStep} prevStep={prevStep}/>;
    case 2:
      return <SignUpDetail nextStep={nextStep} prevStep={prevStep} data={formState} />;
    case 3:
      return <AboutDetail nextStep={nextStep} prevStep={prevStep} data={formState} />;
    case 4:
      return <BookInterview nextStep={nextStep} prevStep={prevStep} submitForm={submitForm} data={formState} loader={loader} />;
    case 5:
      return <EmailVerify nextStep={nextStep} prevStep={prevStep} data={formState} loader={loader} />;
    case 6:
      return <SubmitForm prevStep={prevStep}  data={formState} loader={loader}/>;
    default:
      return <div>Unknown step</div>;
  }
};

export default MultiStep;
