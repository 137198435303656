import React, { useState,useEffect } from 'react';
import checkCircle from '../../../../assets/images/check-mark.svg';
import greenTick from '../../../../assets/images/green-tick.svg'

function ProductDetailsModal({ setProductDetailsModal, setProductDeliveryAddressModal, setFiltersModal, type, setSelectedOption, setQuantity, originalQuantity }) {
    const [formData, setFormData] = useState({
        quantity: '',
        selectedOption: '', // Add selectedOption to formData
    });
    const [errors, setErrors] = useState({});
    const [quantityDropdown, setQuantityDropdown] = useState(false);

    console.log('thiis is quantity', originalQuantity);
    const validateForm = () => {
        let validationErrors = {};
        if (!formData.quantity) {
            validationErrors.quantity = 'Quantity is required';
        } else if (formData.quantity.trim().length !== formData.quantity.length) {
            validationErrors.quantity = 'Quantity cannot contain spaces';
        } 
        else if(originalQuantity?.maximum_quantity_order != null){
            if(parseInt(formData.quantity) > Number(originalQuantity?.maximum_quantity_order)){
            validationErrors.quantity = `Quantity cannot exceed the available stock of ${originalQuantity?.maximum_quantity_order}`;
            }
        }
        else if(originalQuantity?.quantity_items != null){
            if(parseInt(formData.quantity) > Number(originalQuantity?.quantity_items)){
            validationErrors.quantity = `Quantity cannot exceed the available stock of ${originalQuantity?.quantity_items}`;
            }
        }
        // else if (parseInt(formData.quantity) > originalQuantity?.quantity_items) {
        //     validationErrors.quantity = `Quantity cannot exceed the available stock of ${originalQuantity?.quantity_items}`;
        // }

        if (!formData.selectedOption) {
            validationErrors.selectedOption = 'Please select a delivery option';
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    const getDeliveryDate = (offsetDays) => {
        // Get the current date
        const currentDate = new Date();

        // Create a copy of the current date and add offset days
        const deliveryDate = new Date(currentDate);
        deliveryDate.setDate(deliveryDate.getDate() + offsetDays);

        // Format the date to "day month year"
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return deliveryDate.toLocaleDateString('en-GB', options);
    };
    useEffect(() => {
        // Set default selection based on originalQuantity?.delivery_option
        if (originalQuantity?.delivery_option === '1') {
          setFormData(prevData => ({ ...prevData, selectedOption: 'Pickup' }));
        } else if (originalQuantity?.delivery_option === '2') {
          setFormData(prevData => ({ ...prevData, selectedOption: 'Delivery' }));
        } else if (originalQuantity?.delivery_option === '3') {
          setFormData(prevData => ({ ...prevData, selectedOption: '' }));
        }
      }, [originalQuantity]);

    const continueNext = () => {
        if (validateForm()) {
            setSelectedOption(formData?.selectedOption);
            if (formData?.selectedOption === 'Pickup') {
                setQuantity(formData?.quantity)
                setProductDetailsModal(false); // Close ProductDetails Modal
                setFiltersModal(true)
            }
            else {
                setQuantity(formData?.quantity)
                setProductDetailsModal(false); // Close ProductDetails Modal
                setProductDeliveryAddressModal(true); // Open next Modal
            }
        }
    };

    // const handleChange = (e) => {
        // const { id, value } = e.target;
    const handleChange = (id, value) => {
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({ ...prevData, selectedOption: value }));
    // Clear any error if user selects an option
    if (errors.selectedOption) {
      setErrors(prevErrors => ({ ...prevErrors, selectedOption: '' }));
    }
  };
  var dropdownNumber = Number(originalQuantity?.maximum_quantity_order) || Number(originalQuantity?.quantity_items)

    return (
        <>
            <div className='modal-header'>
                {/* Add header content if necessary */}
            </div>
            <div>
                <div className="payment-modal">
                    {/* Add content for payment modal if necessary */}
                </div>
            </div>
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <div className="thanks">
                            <div className="top">
                                <h1 className='text-center'>Product Details</h1>
                                <p className='mb-3'>Please select quantity and delivery method.
                                    If choosing pick up, details will follow after ordering.</p> 

                            </div>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <div className='form-group'>
                                        <label htmlFor="quantity">Quantity</label>
                                        {/* <input
                                            type='number'
                                            className='form-control'
                                            id='quantity'
                                            value={formData.quantity}
                                            onChange={handleChange}
                                            placeholder="Enter quantity"
                                            onKeyDown={(e) => {
                                                // Prevent typing 0 or space at the beginning
                                                if ((e.key === '0' && (e.target.value === '' || e.target.value === '0')) || e.key === ' ') {
                                                  e.preventDefault();
                                                }
                                              }}
                                        /> */}
                                            <input placeholder="Select quantity" class="daily-weeks-selct-ontime " value={formData.quantity} onClick={()=>setQuantityDropdown(!quantityDropdown)}/>
                                        {/* <select
  className="form-control daily-weeks-selct-ontime "
  id="quantity"
  value={formData.quantity}
  onChange={(e) => handleChange(e)}
>
  <option value="" disabled>
    Enter quantity
  </option>
  {Array.from({ length: dropdownNumber }, (_, i) => i + 1).map((num) => (
    <option key={num} value={num}>
      {num}
    </option>
  ))}
</select> */}
{quantityDropdown &&
<div id="myDropdown" className="selct-weklys-dropdown p-0">
{Array.from({ length: dropdownNumber }, (_, i) => i + 1).map((num) => (
    <div className={formData.quantity == num ? 'active' : ''}  key={num} value={num} onClick={(e)=>{handleChange('quantity',num);setQuantityDropdown(false)}}>{num}{formData.quantity == num &&<img src={greenTick} className='float-end' /> }</div>
  ))}
                         {/* <div className='active'>1  <img src={greenTick} className='float-end'/></div>
                        <div >2  <img className='float-end' src={greenTick} alt='' /></div>
                        <div className='mb-0'>3 <img className='float-end' src={greenTick} alt='' /></div> */}
                        </div>
}
                                        {errors.quantity && <div className='error'>{errors.quantity}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-9'>
                                    <div className='form-group  daily-weeks-selct-ontime'>
                                        <label htmlFor="selectedOption">Delivery Option</label>
                                        {/* <input placeholder="Select Delivery Option" class="daily-weeks-selct-ontime " value=""/> */}
                                        <select
                                            className='form-control'
                                            id='selectedOption'
                                            value={formData.selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            <option value="">Select an option</option>
                                            {originalQuantity?.delivery_option !== '1' && (
            <option value="Delivery">Delivery</option>
          )}
          {originalQuantity?.delivery_option !== '2' && (
            <option value="Pickup">Pick up</option>
          )}
                                        </select>
                                        {/* <div id="myDropdown" className="selct-weklys-dropdown p-0">
                         <div className='active'>1  <img src={greenTick} className='float-end'/></div>
                        <div >2  <img className='float-end' src={greenTick} alt='' /></div>
                        <div className='mb-0'>3 <img className='float-end' src={greenTick} alt='' /></div>
                        </div> */}
                                        {errors.selectedOption && <div className='error'>{errors.selectedOption}</div>}
                                    </div>
                                </div>

                                <div className='product-detail-list'>
                                    <ul className='p-0'>
                                        {(formData?.selectedOption === 'Delivery' && originalQuantity?.delivery_time !== null) && (
                                            <>
                                                <li className='aproxi'>  Approximate Delivery date: <span>{getDeliveryDate(originalQuantity?.delivery_time)}</span></li>
                                            </>
                                        )}
                                        {(formData?.selectedOption === 'both' && originalQuantity?.delivery_time !== null) && (
                                            <>
                                                <li className='aproxi'>  Approximate Delivery date: <span>{getDeliveryDate(originalQuantity?.delivery_time)}</span></li>
                                            </>
                                        )}
                                        {(formData?.selectedOption === 'Pickup') && (
                                            <>
                                                <li className='aproxi'> Approximate date when the product will be ready: <span>{getDeliveryDate(3)}</span></li>
                                            </>
                                        )}

                                        {formData?.selectedOption === 'Pickup' && (
                                            <>
                                                <li className='pt-2 pick-up'>Pickup area: <span>{originalQuantity?.address?.address === ''? originalQuantity?.address?.city + ',' +originalQuantity?.address?.state : originalQuantity?.address?.address}</span></li>
                                                <li className='receive'>You will receive the exact address after payment.</li>
                                            </>
                                        )}

                                    </ul>

                                </div>
                                <div className='sub-total-details'>
                                    <div className='row align-item-cener'>
                                        <div className='col-md-8'>
                                            <ul className='p-0'>
                                                <li className='sub-total'>Subtotal Price<span>{originalQuantity?.pricing_type==='1'? '$'+originalQuantity?.minimum_price: '$'+originalQuantity?.minimum_price +'-'+'$'+originalQuantity?.maximum_price}</span></li>
                                                {/* <li className='sub-total'>Subtotal Price<span>${originalQuantity?.minimum_price}</span></li> */}
                                                {formData?.selectedOption === 'Pickup' && (
                                                    <>
                                                        {/* <li>TAX of $13 is included in the price</li> */}
                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                        <div className='col-md-4'>
                                            <button className="pay float-end" onClick={continueNext}> Continue </button>
                                        </div>
                                    </div>
                                    {/* <div class=" mt-2">
                                    <div className='sub-total d-flex align-items-center'>
                                    <p class="w-100">Subtotal Price<span>$26,25</span></p>
                                    </div>
                                    <p>TAX of $13 is included in the price</p>
                                  
                                <button className="pay float-end" onClick={continueNext}> Continue </button>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetailsModal;
