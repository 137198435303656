
import React, { useEffect, useState, useCallback } from 'react';


function AdminBillingPayout() {
    useEffect(() => {}, []);


    return(
        <div>Billing and payouts</div>
        )
};


export default AdminBillingPayout;
