import React, { useEffect, useState } from 'react';
import checkboxs from '../../../assets/images/_Checkbox.svg';
import { useDispatch } from 'react-redux';
import { insertUserData } from '../../../Counter/userSignUpSlice';
import leftArrow from '../../../assets/images/left-arrow_black.svg';
import { toast } from 'react-toastify';
import { getApi } from '../../../Services/apiService';
import Header from '../../CreatorComponents/CreatorJoin/header';

function OnBoarding({ nextStep, prevStep, submitForm }) {
  const [imageData, setImageData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();

  const fetchUserDetails = async () => {
    const response = await getApi('/interest');
    if (response?.statusCode === 200) {
      setImageData(response?.data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserDetails();
    };

    fetchData();
  }, []);

  const handleImageClick = (imageId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(imageId)) {
        return prevSelectedItems.filter(item => item !== imageId);
      } else {
        return [...prevSelectedItems, imageId];
      }
    });
  };

  const handleSubmit = async () => {
    const values = {
      selectedImage: selectedItems,
    };
    dispatch(insertUserData(values));
    nextStep();
  };

  const remainingSelections = 5 - selectedItems.length;
  const buttonText = selectedItems.length >= 5
    ? 'Continue'
    : `Pick ${remainingSelections} more`;

  return (
    <div className="detail-page onbording signup-cs">
      <Header/>
      <div className="main">
        <h1>Tell Us What You Are Interested In</h1>
        <div className="select-section pe-2">
          <div className="row">
            {imageData.map((image, index) => (
              <div className="col-sm-3" key={index}>
                <div
                  className={`image-section ${selectedItems.includes(image?.id) ? 'bordered' : ''}`}
                  onClick={() => handleImageClick(image?.id)}
                >
                  <span className='sign-img'><img src='https://caseus.s3.ap-south-1.amazonaws.com/fdb5ea7d9f41445fc3e2b40f9ea68bc2.png' alt={image?.tag_name} /></span>
                  <div className="image-content">{image?.tag_name}</div>
                  {selectedItems.includes(image.id) && (
                    <button className="checkbox">
                      <img src={checkboxs} alt="Checkbox" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button 
          onClick={handleSubmit} 
          className="pick-more"
          disabled={selectedItems.length < 5}
        >
          {buttonText}
        </button>
      </div>
      <div className="back-button">
        <button className="button" onClick={() => prevStep()}>
          <img src={leftArrow} alt="" />
        </button>
      </div>
    </div>
  );
}

export default OnBoarding;
