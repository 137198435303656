import React, { useState, useEffect } from 'react';
import { getApi, postApi } from '../../Services/apiService';

const AvailabilityModal = ({ data, handleModalClose, modalStartDate }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [sessionDuration, setSessionDuration] = useState('');
    const [availability, setAvailability] = useState('available'); 
    const [loader, setLoader] = useState(false);

    // Error states for validation
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [startTimeError, setStartTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');
    const [sessionDurationError, setSessionDurationError] = useState('');
console.log('this is date',modalStartDate);
useEffect(() => {
    if (modalStartDate) {
        const date = new Date(modalStartDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0'); // Pad with '0' if necessary
        
        const formattedDate = `${year}-${month}-${day}`;
        setStartDate(formattedDate);
    } else {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        
        const todayFormatted = `${year}-${month}-${day}`;
        setStartDate(todayFormatted);
    }
}, [modalStartDate]);



    // Utility function to calculate the time difference in minutes
    const getTimeDifferenceInMinutes = (start, end) => {
        const [startHour, startMin] = start.split(':').map(Number);
        const [endHour, endMin] = end.split(':').map(Number);
        const startDateTime = new Date(0, 0, 0, startHour, startMin, 0);
        const endDateTime = new Date(0, 0, 0, endHour, endMin, 0);
        const diffInMilliseconds = endDateTime - startDateTime;

        return diffInMilliseconds / 1000 / 60; // Convert milliseconds to minutes
    };

    const validateFields = () => {
        let isValid = true;
    
        // Validate Start Date
        if (!startDate) {
            setStartDateError('Start date is required.');
            isValid = false;
        } else {
            setStartDateError('');
        }
    
        // Validate End Date
        if (!endDate) {
            setEndDateError('End date is required.');
            isValid = false;
        } else if (new Date(endDate) < new Date(startDate)) {
            setEndDateError('End date cannot be before start date.');
            isValid = false;
        } else {
            setEndDateError('');
        }
    
        // Validate Start Time
        if (!startTime) {
            setStartTimeError('Start time is required.');
            isValid = false;
        } else {
            setStartTimeError('');
        }
    
        // Validate End Time
        if (!endTime) {
            setEndTimeError('End time is required.');
            isValid = false;
        } else if (endTime <= startTime) {
            setEndTimeError('End time cannot be earlier than or the same as start time.');
            isValid = false;
        } else if (sessionDuration && getTimeDifferenceInMinutes(startTime, endTime) < sessionDuration) {
            setEndTimeError(`End time must be at least ${sessionDuration} minutes after the start time.`);
            isValid = false;
        } else {
            setEndTimeError('');
        }
    
        // Validate Session Duration
        if (!sessionDuration) {
            setSessionDurationError('Session duration is required.');
            isValid = false;
        } else if (isNaN(sessionDuration) || sessionDuration <= 0) {
            setSessionDurationError('Session duration must be a positive number.');
            isValid = false;
        } else {
            setSessionDurationError('');
        }
    
        return isValid;
    };
    

    const handleSave = async () => {
        if (!validateFields()) {
            return;
        }

        setLoader(true);

        const convertTo24HourFormat = (time12h) => {
            const [time, modifier] = time12h.split(' ');
            let [hours, minutes] = time.split(':');

            if (hours === '12') hours = '00';
            if (modifier === 'PM' && hours !== '12') hours = String(parseInt(hours, 10) + 12);

            return `${hours}:${minutes}`;
        };

        const formatDate = (date) => {
            const d = new Date(date);
            const year = d.getUTCFullYear();
            const month = String(d.getUTCMonth() + 1).padStart(2, '0');
            const day = String(d.getUTCDate()).padStart(2, '0');
            const hours = String(d.getUTCHours()).padStart(2, '0');
            const minutes = String(d.getUTCMinutes()).padStart(2, '0');
            const seconds = String(d.getUTCSeconds()).padStart(2, '0');
            return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
        };

        const dayMapping = {
            'Monday': 'MO',
            'Tuesday': 'TU',
            'Wednesday': 'WE',
            'Thursday': 'TH',
            'Friday': 'FR',
            'Saturday': 'SA',
            'Sunday': 'SU'
        };
        const byDay = data.map(day => dayMapping[day]).join(',');

        const rruleString = `FREQ=WEEKLY;DTSTART=${formatDate(startDate)};BYDAY=${byDay};UNTIL=${formatDate(endDate)}`;

        const convertedStartTime = convertTo24HourFormat(startTime);
        const convertedEndTime = convertTo24HourFormat(endTime);

        try {
            const availabilityData = {
                startTime: convertedStartTime,
                endTime: convertedEndTime,
                sessionDuration: parseInt(sessionDuration, 10),
                rruleString
            };

            const response = await postApi('/admin/availbility', availabilityData);

            if (response.statusCode === 200) {
                setLoader(false);
                handleModalClose(true);
            } else {
                // Handle error
            }
        } catch (error) {
            console.error('Error submitting availability:', error);
        }
    };

    return (
        <div className="availability-inner">
            <h3>Update Availability</h3>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Start date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        {startDateError && <div className="error-message">{startDateError}</div>}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>End Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                        {endDateError && <div className="error-message">{endDateError}</div>}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Earliest start time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                        />
                        {startTimeError && <div className="error-message">{startTimeError}</div>}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Latest start time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                        />
                        {endTimeError && <div className="error-message">{endTimeError}</div>}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Duration session</label>
                        <input
                            type="text"
                            className="form-control"
                            value={sessionDuration}
                            onChange={(e) => setSessionDuration(e.target.value)}
                        />
                        {sessionDurationError && <div className="error-message">{sessionDurationError}</div>}
                    </div>
                </div>
                <div className="col-sm-12">
                    <button className="cancel ms-0" onClick={() => handleModalClose(false)}>
                        Cancel
                    </button>
                    <button className="save" onClick={handleSave} disabled={loader}>
                        {loader ? 'Saving...' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AvailabilityModal;
