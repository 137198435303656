import React, { useState } from 'react'
import Header from '../../CreatorComponents/CreatorJoin/header'
import facebookLogo from '../../../assets/images/facebook-logo.svg'
import appleLogo from '../../../assets/images/apple-logo.svg'
import mailLogo from '../../../assets/images/mail-logo.svg'
import GoogleSignIn from '../../AuthComponent/googleSignIn'
import { useDispatch } from 'react-redux'
import {insertUserData} from '../../../Counter/userSignUpSlice'
import { Link } from 'react-router-dom'

function Login({nextStep,prevStep,setUserModalIsOpen}) {
  
  const dispatch = useDispatch();

  const onSubmit=async(values)=>{
    const value={
      accountType:values
    }
    dispatch(insertUserData(value));
    if(values === 'email'){
      nextStep()
    }
  }


  return (
    <div className='login-page signup-cs'>
       <Header/>
       <div className="join-microcosm">
    <div className="top">
            <h1>Join microcosm</h1>
            <p>Explore your creativity with thousands of inspiring classes</p>
    </div>
    <div className="middle">
        <ul>
         {/* <li onClick={()=>onSubmit('google')}><img src={googleLogo}/>Sign in with Google </li> */}
        <div onClick={()=>onSubmit('google')}><GoogleSignIn role='user' setUserModalIsOpen={setUserModalIsOpen}/></div>
         <li onClick={()=>onSubmit('facebook')}><img src={facebookLogo} alt=''/>Sign in with Facebook </li>
         {/* <li onClick={()=>onSubmit('apple')}><img src={appleLogo} alt=''/>Sign in with Apple </li> */}
         <li onClick={()=>onSubmit('email')}><img src={mailLogo} alt=''/>Sign in with Email </li>
        </ul>
    </div>
    <div className="bottom">
        <h5>Already have an account? <button onClick={()=>prevStep()}>Log in</button></h5>
        <p>By clicking you agree to the processing of your personal data in accordance with <u><Link to='#'>Privacy Policy</Link></u></p>
    </div>
   </div> 
    </div>
  )
}

export default Login