import { useState } from 'react'
import { createSlice } from '@reduxjs/toolkit'

// const initialState = {
//   value: {
//     name:'',
//     email:'',
//     password:'',
//     gender:'',
//     user_role:'',
//     accountType:'',
//     selectedDate:'',
//     selectedTimes:[],
//     workArea:'',
//     experience:'',
//     link:''
//   }
// }
const initialState = {
  // Add your initial state properties here
  value: 0,
  isAuthenticated: false,
  // Add other properties as needed
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    insertData: (state,action) => {
        Object.entries(action.payload).forEach(([key, value]) => {
          state[key] = value;
        })
      },
      clearData: () => initialState,
    // decrement: (state) => {
    //   state.value -= 1
    //   state.isAuthenticated = false
    // },
  },
})

export const { insertData, clearData } = counterSlice.actions

export default counterSlice.reducer